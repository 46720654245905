import moment from 'moment-timezone';
import React, { useState } from 'react';
import pluralize from 'pluralize';
import Avatar from 'react-avatar';
import { useToasts } from 'react-toast-notifications';
import { Form, Formik } from 'formik';
import Skeleton from 'react-loading-skeleton';
import { usePrompt } from 'react-router-dom';
import { Panel, Header, DescriptionList, useIsLoading, RadioGroup, IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { Page } from '../../../components/Page';
// import { VerticalNavigation } from '@shuffl/tailwind-ui-react';
import { GetUserQueryHookResult, UserRole, useUpdateUserMutation } from '../../../generated/graphql';
import { useUser } from '../../../context/user-context';
import { SubmitButton } from '../../../components/Form';

import { FormData } from './form-schema';

export interface MemberProps {
  userQuery: GetUserQueryHookResult;
}

export const Member = ({ userQuery }: MemberProps) => {
  const user = userQuery.data?.user;
  const { isLoading } = useIsLoading();
  // const name = `${user?.firstName} ${user?.lastName}`;
  const createdAt = moment(user?.createdAt ?? new Date());
  const { addToast } = useToasts();
  const [dirty, setDirty] = useState(false);
  usePrompt('You have unsaved changes!\nAre you sure you want to leave?', dirty);
  const { isSuperAdmin, isAdmin, userQuery: loggedInUser } = useUser();
  const isLoggedInUserProfile = loggedInUser.data?.profile.id === user?.id;
  const [updateUserMutation] = useUpdateUserMutation({
    onCompleted: async (data) => {
      addToast('Updated user role', { appearance: 'success' });

      if (userQuery.refetch) {
        await userQuery.refetch();
      }
    },
    onError: (error) => {
      addToast('Failed to update user role', { appearance: 'error' });
    },
  });
  const numberOfEventDefinitionsCreated = user?.eventDefinitionsCreated?.length ?? 0;

  return (
    <Page>
      <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6">
        <div className="lg:col-span-6">
          <Panel padding={true}>
            <div className="flex items-center">
              <div className="mr-auto">
                <div className="flex">
                  {isLoading && (
                    <div className="ml-0 sm:ml-3 w-full mb-4">
                      <Skeleton />
                    </div>
                  )}
                  {!isLoading && <Header>{user?.name}</Header>}
                </div>
                <div className="-mb-4">
                  <DescriptionList divided={false}>
                    <DescriptionList.Item name="Email">
                      <span className="text-sm text-gray-500 font-medium">{user?.email}</span>
                    </DescriptionList.Item>
                    <DescriptionList.Item name="Joined Shuffl">
                      <span className="text-sm text-gray-500 font-medium">
                        {createdAt.format('ll')} ({createdAt.fromNow()})
                      </span>
                    </DescriptionList.Item>
                    <DescriptionList.Item name="Created">
                      <span className="text-sm text-gray-500 font-medium">
                        {numberOfEventDefinitionsCreated} {pluralize('channel', numberOfEventDefinitionsCreated)}
                      </span>
                    </DescriptionList.Item>
                  </DescriptionList>
                </div>
              </div>
              {isLoading && <Skeleton circle height={96} width={96} />}
              {!isLoading && (
                <Avatar
                  className="h-16 w-16 rounded-full"
                  round={true}
                  email={user?.email ?? ''}
                  src={user?.slackMetadata?.image ?? ''}
                  alt={user?.name}
                  name={user?.name}
                />
              )}
            </div>
          </Panel>
        </div>
        {/* <div className="lg:col-span-3 lg:row-start-2">
          <Panel>
            <VerticalNavigation>
              <VerticalNavigation.Item to={`/members/${user?.id}`} title="General" />
              <VerticalNavigation.Item to={`/members/${user?.id}/history`} title="Events" />
              <VerticalNavigation.Item to={`/members/${user?.id}/Stats`} title="Stats" />
            </VerticalNavigation>
          </Panel>
        </div> */}
        <div className="lg:col-span-9 lg:row-start-2">
          <h1 className="text-2xl font-bold text-gray-900 sm:truncate mb-2">Role</h1>
          <div className="flex items-center text-sm text-gray-500 font-medium mb-4">
            A member&apos;s role determines their permissions
          </div>
          <Formik
            initialValues={{ role: user?.role }}
            enableReinitialize
            onSubmit={async (submitData: FormData) => {
              if (user) {
                await updateUserMutation({
                  variables: {
                    role: submitData.role as UserRole,
                    userId: user.id,
                  },
                });
              }
            }}
          >
            {(formik) => {
              setDirty(formik.dirty);

              return (
                <Form>
                  <div className="mb-8">
                    <IsLoadingProvider isLoading={false}>
                      <RadioGroup legend="Role" name="role">
                        <RadioGroup.Item
                          value={UserRole.User}
                          disabled={(!isSuperAdmin && user?.role === UserRole.SuperAdmin) || isLoggedInUserProfile}
                          label="User"
                          subLabel="The default role granted with no added abilities or administrative permissions"
                        />
                        <RadioGroup.Item
                          value={UserRole.Admin}
                          disabled={
                            (!isSuperAdmin && ((!isSuperAdmin && !isAdmin) || user?.role === UserRole.SuperAdmin)) ||
                            isLoggedInUserProfile
                          }
                          label="Admin"
                          subLabel="Admins have the ability to add other Admins and manage Shuffl channel settings"
                        />
                        <RadioGroup.Item
                          value={UserRole.SuperAdmin}
                          disabled={!isSuperAdmin}
                          label="Super Admin"
                          subLabel="Super Admins can perform all abilities granted to Admins and manage Billing settings"
                        />
                      </RadioGroup>
                    </IsLoadingProvider>
                  </div>
                  <SubmitButton>Update</SubmitButton>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Page>
  );
};
