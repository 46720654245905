import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Unauthorized } from '../../pages/Unauthorized';

export const UserApp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/', { replace: true });
  });

  return (
    <Routes>
      <Route element={<Unauthorized />} path="/" />
    </Routes>
  );
};
