import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { usePrompt } from 'react-router-dom';
import { Header, Panel, MediaObject, LearningHubLink, RadioGroup, Button } from '@shuffl/tailwind-ui-react';
import { SubmitButton } from '../../../../components/Form';

import {
  GetEventDefinitionQueryHookResult,
  ShufflStrategy,
  StripePriceLookupKey,
  useUpdateEventDefinitionStrategyMutation,
} from '../../../../generated/graphql';
import { ReactComponent as UndrawConnectingTeamsImage } from '../../../../images/undraw/connecting-teams.svg';

import { FormData, SmartShufflSchema } from './form-schema';

export interface SmartShufflProps {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;
  isPayingOrTrialing?: boolean;
}

export const SmartShuffl = ({ eventDefinitionQuery, isPayingOrTrialing }: SmartShufflProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const [updateEventDefinitionStrategy] = useUpdateEventDefinitionStrategyMutation();
  const [dirty, setDirty] = useState(false);
  usePrompt('You have unsaved changes!\nAre you sure you want to leave?', dirty);

  return (
    <>
      <div className="lg:col-span-12">
        <Panel padding>
          <MediaObject
            header="Smart Shuffl"
            media={<UndrawConnectingTeamsImage />}
            mediaSize={isPayingOrTrialing ? 'xs' : undefined}
          >
            <div className="flex flex-col space-y-8">
              {!isPayingOrTrialing && <div>Available starting at $50/month.</div>}
              <div className="text-sm text-gray-500 space-y-4">
                <p>Transform culture by networking and collaborating meaningfully with AI-powered shuffls.</p>
                <p>
                  From cross-team collaborations to leadership shuffls, start smart shufflin&apos; in fun and smart
                  ways.
                </p>
              </div>
              <div className="space-y-4">
                <LearningHubLink collectionId="2842577-smart-shuffl" size="sm">
                  Learn more about Smart Shuffl in our Learning Hub
                </LearningHubLink>
                {!isPayingOrTrialing && (
                  <div>
                    <Button to={`/account/billing/change/payment-details?price=${StripePriceLookupKey.ProMonthly}`}>
                      Upgrade team plan starting at $50/month
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </MediaObject>
        </Panel>
      </div>
      <div className="lg:col-span-12">
        <Header type="h2">Algorithm Settings</Header>
        <Panel padding>
          <div className="flex items-center justify-between mb-8">
            <p className="max-w-4xl text-sm text-gray-500 space-y-2">
              <div>Supercharged Team Networking!</div>
              <div>Set up is simple for fun and smart shuffl experiences.</div>
            </p>
          </div>
          <Formik
            validationSchema={SmartShufflSchema}
            initialValues={{
              shufflStrategy: eventDefinition?.shufflStrategy,
            }}
            enableReinitialize
            onSubmit={async (submitData: FormData) => {
              if (!eventDefinition) {
                return;
              }

              await updateEventDefinitionStrategy({
                variables: {
                  eventDefinitionId: eventDefinition.id,
                  shufflStrategy: submitData.shufflStrategy,
                },
              });

              await eventDefinitionQuery.refetch();
            }}
          >
            {(formik) => {
              setDirty(formik.dirty);

              return (
                <Form>
                  <RadioGroup legend="Shuffl Strategy" name="shufflStrategy">
                    <RadioGroup.Item
                      value={ShufflStrategy.Random}
                      label="Random"
                      subLabel={
                        <div className="space-y-2">
                          <div>Randomly match people together!</div>
                          <div>
                            Repeated intros with previous matches can happen when left up to serendipitous connections.
                          </div>
                        </div>
                      }
                    />
                    <RadioGroup.Item
                      value={ShufflStrategy.GroupFrequencyWeightedV2}
                      label="New Match Shuffl"
                      disabled={!isPayingOrTrialing}
                      subLabel={
                        <div className="space-y-2">
                          <div>
                            We remember who you&apos;ve met with and ensure you get a chance to make new matches!
                          </div>
                          <div>
                            Strengthen engagement by using shuffl match history to find new shuffl combinations in
                            future intros automatically.
                          </div>
                        </div>
                      }
                    />
                  </RadioGroup>
                  {isPayingOrTrialing && <SubmitButton className="mt-8">Update</SubmitButton>}
                </Form>
              );
            }}
          </Formik>
        </Panel>
      </div>
    </>
  );
};
