import React from 'react';
import * as Sentry from '@sentry/react';
import {
  useGetTenantQuery,
  useGetIsOnboardingInstanceCompletedQuery,
  useGetProfileQuery,
  UserRole,
  GetProfileQueryHookResult,
  GetTenantQueryHookResult,
  FeatureFlagKey,
  useGetFeatureFlagQuery,
  GetIsOnboardingInstanceCompletedQueryHookResult,
} from '../generated/graphql';
import { useAuth } from './auth-context';

export interface UpdateTenantProps {
  isPayingOrTrialing?: boolean;
}

export interface UserContextInterface {
  userQuery: GetProfileQueryHookResult;
  isPayingOrTrialing: boolean;
  isEligibleForTrial: boolean;
  isPaying: boolean;
  isTrialing: boolean;
  tenantQuery: GetTenantQueryHookResult;
  isInternalAdmin: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  loading: boolean;
  isOnboardingInstanceCompletedQuery: GetIsOnboardingInstanceCompletedQueryHookResult;
  tenantQueryLoading: boolean;
  userQueryLoading: boolean;
  impersonatedBy?: { id: string; email: string };
  updateTenant: (props?: UpdateTenantProps) => Promise<any>;
  isOnboardingCompleted: boolean;
}

const UserContext = React.createContext<UserContextInterface>({
  isEligibleForTrial: false,
  isInternalAdmin: false,
  isOnboardingCompleted: false,
  isPaying: false,
  isPayingOrTrialing: false,
  isSuperAdmin: false,
  isTrialing: false,

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateTenant: () => Promise.resolve(),
} as UserContextInterface);

const UserProvider: React.FC = (props) => {
  const auth = useAuth();
  const tenantQuery = useGetTenantQuery({
    skip: auth.loading || !auth.isAuthenticated,
  });
  const userQuery = useGetProfileQuery({
    skip: auth.loading || !auth.isAuthenticated,
  });

  let isOnboardingCompleted = false;
  let isInternalAdmin = false;
  let isAdmin = false;
  let isSuperAdmin = false;
  let impersonatedBy: any;

  if (userQuery.data && userQuery.data.profile) {
    const { role } = userQuery.data.profile;
    impersonatedBy = userQuery.data.profile.impersonatedBy;
    isInternalAdmin = role === UserRole.InternalSuperAdmin;
    isAdmin = role === UserRole.Admin || role === UserRole.SuperAdmin || role === UserRole.InternalSuperAdmin;
    isSuperAdmin = role === UserRole.SuperAdmin || role === UserRole.InternalSuperAdmin;

    const fullStory = (window as any).FS;
    const { gtag } = window as any;
    const isProduction = process.env.NODE_ENV === 'production';

    if (!impersonatedBy && isProduction) {
      if (fullStory) {
        fullStory.identify(userQuery.data.profile.id, {
          displayName: `${userQuery.data.profile.firstName} ${userQuery.data.profile.lastName}`,
          email: userQuery.data.profile.email,
        });
      }

      if (gtag) {
        gtag('set', { user_id: userQuery.data.profile.id }); // Set the user ID using signed-in user_id.
      }
    }

    Sentry.setUser({ email: userQuery.data.profile.email as string, id: userQuery.data.profile.id });
    Sentry.setTag('userRole', userQuery.data.profile.role);

    if (tenantQuery.data) {
      Sentry.setTag('tenantId', tenantQuery.data.tenant.id);
      Sentry.setTag('tenantIsPaying', tenantQuery.data.tenant.isPaying);
      Sentry.setTag('tenantPrice', tenantQuery.data.tenant.price);
      Sentry.setExtra('tenantSlackTeamName', tenantQuery.data.tenant.slackMetadata?.team.name);
    }
  }

  const isOnboardingInstanceCompletedQuery = useGetIsOnboardingInstanceCompletedQuery({
    skip: auth.loading || !isAdmin,
  });

  const featureFlagQuery = useGetFeatureFlagQuery({
    skip: auth.loading || !isAdmin,
    variables: {
      key: FeatureFlagKey.OnboardingV3,
    },
  });

  const loading =
    auth.loading ||
    isOnboardingInstanceCompletedQuery.loading ||
    tenantQuery.loading ||
    userQuery.loading ||
    featureFlagQuery.loading;

  if (userQuery.data && userQuery.data.profile) {
    isOnboardingCompleted = isOnboardingInstanceCompletedQuery.data?.isOnboardingInstanceCompleted ?? false;
  }

  const data = {
    impersonatedBy,
    isAdmin,
    isEligibleForTrial: tenantQuery.data?.tenant.isEligibleForTrial ?? false,
    isInternalAdmin,
    isOnboardingCompleted,
    isOnboardingInstanceCompletedQuery,
    isPaying: tenantQuery.data?.tenant.isPaying ?? false,
    isPayingOrTrialing: tenantQuery.data?.tenant.isPayingOrTrialing ?? false,
    isSuperAdmin,
    isTrialing: tenantQuery.data?.tenant.isTrialing ?? false,
    loading,
    tenantQuery,
    updateTenant: async () => {
      // if (isShufflAdmin || impersonatedBy) {
      //   if (updateTenantProps) {
      //     setIsOnPaidPrice(updateTenantProps.isTenantOnPaidOrTrialing || false);
      //   }
      // }

      await tenantQuery.refetch();
    },
    userQuery,
  } as UserContextInterface;

  return <UserContext.Provider value={{ ...data }} {...props} />;
};

function useUser() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}

export { UserProvider, useUser };
