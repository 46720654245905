import { object, string, InferType, number } from 'yup';

export const ScheduleSchema = object()
  .shape({
    amPm: string().required(),
    frequency: string().required(),
    hourOfDay: number().required(),
    minuteOfHour: number().required(),
    scheduledTime: string().required(),
    timezone: string().required(),
  })
  .defined();

export type FormData = InferType<typeof ScheduleSchema>;
