import React, { useState } from 'react';
import { capitalize } from 'lodash';

import { Button, Alert } from '@shuffl/tailwind-ui-react';
import DefaultStep, { DefaultStepProps } from '../DefaultStep';
import { useCreateDefaultEventDefinitionMutation } from '../../../../generated/graphql';

export interface ConfigureChannelProps extends DefaultStepProps {
  strategy: string;
  groupSize: number;
  channelName: string;

  scheduleToString: string;
}

export const ConfigureChannel = (props: ConfigureChannelProps) => {
  const [createDefaultEventDefinition] = useCreateDefaultEventDefinitionMutation();
  const [isWaitingUntilNextUpdate, setWaitingUntilNextUpdate] = useState(false);

  return (
    <DefaultStep
      content={
        <div>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="grid gap-3">
              <div className="font-semibold">Channel</div>
              <div>#{props.channelName}</div>
            </div>
            <div className="grid gap-3">
              <div className="font-semibold">Shuffl Strategy</div>
              <div>{capitalize(props.strategy)}</div>
            </div>
            <div className="grid gap-3">
              <div className="font-semibold">Group Size</div>
              <div>{props.groupSize}</div>
            </div>
          </div>
          <div className="grid gap-3">
            <div className="font-semibold">Frequency</div>
            <div>
              <div className="mb-2">Shuffls {props.scheduleToString}</div>
              <p className="text-sm text-gray-700">Two business days from now</p>
            </div>
          </div>
        </div>
      }
      accessory={
        <>
          {props.isCurrentStep && !isWaitingUntilNextUpdate && (
            <Button
              onClick={async () => {
                await createDefaultEventDefinition();
                // getOnboardingInstanceQuery();
                setWaitingUntilNextUpdate(true);
              }}
              buttonStyle="primary"
            >
              Create Channel
            </Button>
          )}
          {props.isCurrentStep && isWaitingUntilNextUpdate && (
            <Button isLoading={true} buttonStyle="primary">
              Create Channel
            </Button>
          )}
        </>
      }
      footer={
        <Alert
          type="info"
          title={'If two business days from now is too soon, you can edit the schedule after completing onboarding.'}
        />
      }
      {...props}
    />
  );
};
