/* eslint-disable no-await-in-loop */
import { Form, Formik } from 'formik';
import { startCase } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { usePrompt } from 'react-router-dom';
import { Header, DatePicker, TimePicker, Panel, LearningHubLink, Button } from '@shuffl/tailwind-ui-react';

import {
  DayOfWeek,
  Frequency,
  useUpdateEventDefinitionScheduleMutation,
  useGetNextScheduledTimeFromScheduleLazyQuery,
  EventDefinitionState,
  useUpdateEventDefinitionStateMutation,
  GetEventDefinitionQueryHookResult,
} from '../../../../generated/graphql';
import { dayOfWeekToNumber } from '../../../../utils/schedule';

import { SubmitButton, SelectMenu } from '../../../../components/Form';
import { FormData, ScheduleSchema } from './form-schema';

export interface ScheduleProps {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;
}

export const Schedule = ({ eventDefinitionQuery }: ScheduleProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const [dirty, setDirty] = useState(false);
  usePrompt('You have unsaved changes!\nAre you sure you want to leave?', dirty);

  const [updateEventDefinitionSchedule] = useUpdateEventDefinitionScheduleMutation();
  const [updateEventDefinitionState] = useUpdateEventDefinitionStateMutation();
  const [getNextScheduledTimeFromScheduleQuery, { data: nextScheduledTimeData }] =
    useGetNextScheduledTimeFromScheduleLazyQuery();

  const scheduledTime = eventDefinition?.nextShufflEvent?.scheduledTime
    ? moment(eventDefinition?.nextShufflEvent?.scheduledTime).startOf('day').format()
    : undefined;

  const zones = new Set<string>();
  const countries = moment.tz.countries();

  for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
  }

  const timezones = Array.from(zones.values()).sort();

  return (
    <>
      <div className="lg:col-span-12 lg:row-start-1">
        <Header type="h2" id="current-schedule" className="mb-2">
          Current Schedule
        </Header>
        <Panel padding>
          <div className="flex items-center justify-between mb-8">
            <div className="max-w-4xl text-sm text-gray-500 space-y-2">
              <div>The schedule determines when the next shuffl will occur.</div>
              <LearningHubLink articleId="5125851-shuffl-schedule" size="sm">
                Learn more about Scheduling in the docs
              </LearningHubLink>
            </div>
          </div>
          <Formik
            validationSchema={ScheduleSchema}
            initialValues={{
              amPm: Number(eventDefinition?.schedule.hourOfDay) > 12 ? 'pm' : 'am',
              frequency: eventDefinition?.schedule.frequency,
              hourOfDay:
                Number(eventDefinition?.schedule.hourOfDay) > 12
                  ? Number(eventDefinition?.schedule.hourOfDay) - 12
                  : eventDefinition?.schedule.hourOfDay,
              minuteOfHour: eventDefinition?.schedule.minuteOfHour,
              scheduledTime,
              timezone: eventDefinition?.schedule.timezone,
            }}
            enableReinitialize
            validate={async (data: FormData) => {
              if (!eventDefinition) {
                return;
              }

              if (
                !moment(data.scheduledTime)
                  .startOf('day')
                  .isSame(moment(eventDefinition.nextShufflEvent?.scheduledTime).startOf('day')) &&
                data.scheduledTime !== scheduledTime
              ) {
                getNextScheduledTimeFromScheduleQuery({
                  variables: {
                    eventDefinitionId: eventDefinition.id,
                    schedule: {
                      scheduledTime: moment(data.scheduledTime).toDate(),
                      timezone: data.timezone,
                    },
                  },
                });
              }
            }}
            onSubmit={async (submitData: FormData) => {
              if (!eventDefinition) {
                return;
              }

              await updateEventDefinitionSchedule({
                variables: {
                  eventDefinitionId: eventDefinition.id,
                  frequency: submitData.frequency,
                  hourOfDay:
                    submitData.amPm === 'pm' ? Number(submitData.hourOfDay) + 12 : Number(submitData.hourOfDay),
                  minuteOfHour: Number(submitData.minuteOfHour),
                  scheduledTime: moment(submitData.scheduledTime).toDate(),
                  timezone: submitData.timezone,
                },
              });

              let doesTimeMatch = false;
              do {
                const eventDefinitionRefetchQuery = await eventDefinitionQuery.refetch();
                doesTimeMatch = moment(eventDefinitionRefetchQuery.data.eventDefinition?.nextShufflEvent?.scheduledTime)
                  .startOf('day')
                  .isSame(moment(submitData.scheduledTime).startOf('day'));

                if (!doesTimeMatch) {
                  await new Promise((resolve) => setTimeout(resolve, 500));
                }
              } while (!doesTimeMatch);
            }}
          >
            {(formik) => {
              setDirty(formik.dirty);

              // https://formik.org/docs/examples/dependent-fields
              // if (
              //   nextScheduledTimeData?.getNextScheduledTimeFromSchedule &&
              //   formik.values.scheduledTime !== nextScheduledTimeData?.getNextScheduledTimeFromSchedule
              // ) {
              //   formik.setFieldValue(
              //     'scheduledTime',
              //     moment(nextScheduledTimeData?.getNextScheduledTimeFromSchedule).startOf('day').format(),
              //     false,
              //   );
              //   formik.setFieldValue(
              //     'dayOfWeek',
              //     numberToDayOfWeek(moment(nextScheduledTimeData?.getNextScheduledTimeFromSchedule).day()),
              //     false,
              //   );
              // }

              return (
                <Form>
                  <div className="mb-8">
                    <div className="sm:pb-6 pb-5">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <SelectMenu label="Frequency" name="frequency">
                            <SelectMenu.Item value={Frequency.EveryWeek}>
                              {startCase(Frequency.EveryWeek.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={Frequency.EveryTwoWeeks}>
                              {startCase(Frequency.EveryTwoWeeks.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={Frequency.EveryThreeWeeks}>
                              {startCase(Frequency.EveryThreeWeeks.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={Frequency.EveryFourWeeks}>
                              {startCase(Frequency.EveryFourWeeks.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={Frequency.StartOfEveryMonth}>
                              {startCase(Frequency.StartOfEveryMonth.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={Frequency.EndOfEveryMonth}>
                              {startCase(Frequency.EndOfEveryMonth.toLowerCase())}
                            </SelectMenu.Item>
                          </SelectMenu>
                        </div>
                        {/* <div className="col-span-6 sm:col-span-3">
                          <SelectMenu label="Day of Week" name="dayOfWeek">
                            <SelectMenu.Item value={DayOfWeek.Monday}>
                              {startCase(DayOfWeek.Monday.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={DayOfWeek.Tuesday}>
                              {startCase(DayOfWeek.Tuesday.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={DayOfWeek.Wednesday}>
                              {startCase(DayOfWeek.Wednesday.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={DayOfWeek.Thursday}>
                              {startCase(DayOfWeek.Thursday.toLowerCase())}
                            </SelectMenu.Item>
                            <SelectMenu.Item value={DayOfWeek.Friday}>
                              {startCase(DayOfWeek.Friday.toLowerCase())}
                            </SelectMenu.Item>
                          </SelectMenu>
                        </div> */}
                      </div>
                    </div>
                    <div className="grid grid-cols-9 gap-6">
                      <div className="col-span-9 sm:col-span-3">
                        <DatePicker
                          label="Your next event will be scheduled for"
                          name="scheduledTime"
                          allowDatesInThePast={false}
                          hideOptional
                          disallowedWeekdays={[
                            dayOfWeekToNumber(DayOfWeek.Saturday),
                            dayOfWeekToNumber(DayOfWeek.Sunday),
                          ]}
                        />
                      </div>
                      <div className="col-span-9 sm:col-span-2">
                        <TimePicker label="Time" hourName="hourOfDay" minuteName="minuteOfHour" />
                      </div>
                      <div className="col-span-9 sm:col-span-4">
                        <SelectMenu label="Timezone" name="timezone">
                          {timezones.map((timezone) => (
                            <SelectMenu.Item key={timezone} value={timezone}>
                              {timezone}
                            </SelectMenu.Item>
                          ))}
                        </SelectMenu>
                      </div>
                    </div>
                  </div>

                  <SubmitButton>Update</SubmitButton>
                </Form>
              );
            }}
          </Formik>
        </Panel>
      </div>
      <div className="lg:col-span-12 lg:row-start-2">
        <Header type="h2" id="overrides" className="mb-2">
          Overrides
        </Header>
        <Panel padding>
          <div className="flex items-center justify-between mb-8">
            <div className="max-w-4xl text-sm text-gray-500 space-y-2">
              <div>You can manually pause and resume the shuffl here</div>
              <LearningHubLink articleId="5049322-how-can-i-pause-the-next-shuffl" size="sm">
                Learn more about pausing a shuffl in the docs
              </LearningHubLink>
            </div>
          </div>

          {eventDefinition?.state === EventDefinitionState.Enabled && (
            <Button
              buttonStyle="danger"
              onClick={async () => {
                if (!eventDefinition) {
                  return;
                }

                await updateEventDefinitionState({
                  variables: {
                    eventDefinitionId: eventDefinition.id,
                    state: EventDefinitionState.PausedThroughPortal,
                  },
                });

                await eventDefinitionQuery.refetch();
              }}
            >
              Pause
            </Button>
          )}
          {eventDefinition?.state !== EventDefinitionState.Enabled && (
            <Button
              onClick={async () => {
                if (!eventDefinition) {
                  return;
                }

                await updateEventDefinitionState({
                  variables: {
                    eventDefinitionId: eventDefinition.id,
                    state: EventDefinitionState.Enabled,
                  },
                });

                await eventDefinitionQuery.refetch();
              }}
            >
              Resume
            </Button>
          )}
        </Panel>
      </div>
    </>
  );
};
