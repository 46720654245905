import { SelectMenu, DatePicker, TimePicker } from '@shuffl/tailwind-ui-react';
import { Formik, Form } from 'formik';
import { startCase } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitButton } from '../../../../components/Form';
import {
  Frequency,
  DayOfWeek,
  useUpdateOnboardingInstanceMutation,
  OnboardingDefinitionStepId,
} from '../../../../generated/graphql';
import { dayOfWeekToNumber, numberToDayOfWeek } from '../../../../utils/schedule';
import { addWeekdays } from '../../../../utils/add-weekdays';
import { useOnboarding } from '../../../OnboardingV3/context';
import { ScheduleSchema, FormData } from './form-schema';

export const AddChannelSelectSchedule = () => {
  const navigate = useNavigate();
  const { onboardingInstanceQuery } = useOnboarding();
  const [updateOnboardingInstance] = useUpdateOnboardingInstanceMutation();
  const zones = new Set<string>();
  const countries = moment.tz.countries();

  for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
  }

  const currentTimezone = moment.tz.guess();

  return (
    <Formik
      validationSchema={ScheduleSchema}
      initialValues={{
        amPm: Number(10) > 12 ? 'pm' : 'am',
        frequency: Frequency.EveryWeek,
        hourOfDay: 10,
        minuteOfHour: 0,
        scheduledTime: addWeekdays(new Date(), 2).toISOString(),
        timezone: currentTimezone,
      }}
      onSubmit={async (submitData: FormData) => {
        let finalHourOfDay = submitData.hourOfDay;
        if (submitData.amPm === 'am' && submitData.hourOfDay === 12) {
          finalHourOfDay = 0;
        } else if (submitData.amPm === 'pm') {
          finalHourOfDay += 12;
        }

        const scheduledTime = moment(submitData.scheduledTime)
          .hours(finalHourOfDay)
          .minutes(submitData.minuteOfHour)
          .seconds(0)
          .milliseconds(0);

        await updateOnboardingInstance({
          variables: {
            isCompleted: true,
            metadata: JSON.stringify({
              dayOfWeek: numberToDayOfWeek(scheduledTime.day()),
              frequency: submitData.frequency,
              hourOfDay: finalHourOfDay,
              minuteOfHour: submitData.minuteOfHour,
              scheduledTime: scheduledTime.toISOString(),
              timezone: submitData.timezone,
            }),
            stepId: OnboardingDefinitionStepId.OnboardingV3SelectSchedule,
          },
        });
        await onboardingInstanceQuery.refetch();
        navigate('../confirm-settings');
      }}
    >
      <Form>
        <div className="mb-8">
          <div className="mb-8">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Select the schedule for your first Shuffl</h3>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              Your first shuffl will go off on the specified date. And then at the frequency after that.
            </p>
          </div>
          <div>
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 md:col-span-3">
                <DatePicker
                  label="First shuffl"
                  name="scheduledTime"
                  allowDatesInThePast={false}
                  required
                  disallowedWeekdays={[dayOfWeekToNumber(DayOfWeek.Saturday), dayOfWeekToNumber(DayOfWeek.Sunday)]}
                />
              </div>
              <div className="col-span-12 md:col-span-3">
                <TimePicker label="Time" hourName="hourOfDay" minuteName="minuteOfHour" />
              </div>
              <div className="col-span-12 md:col-span-4">
                <SelectMenu label="Frequency" name="frequency">
                  <SelectMenu.Item value={Frequency.EveryWeek}>
                    {startCase(Frequency.EveryWeek.toLowerCase())}
                  </SelectMenu.Item>
                  <SelectMenu.Item value={Frequency.EveryTwoWeeks}>
                    {startCase(Frequency.EveryTwoWeeks.toLowerCase())}
                  </SelectMenu.Item>
                  <SelectMenu.Item value={Frequency.EveryThreeWeeks}>
                    {startCase(Frequency.EveryThreeWeeks.toLowerCase())}
                  </SelectMenu.Item>
                  <SelectMenu.Item value={Frequency.EveryFourWeeks}>
                    {startCase(Frequency.EveryFourWeeks.toLowerCase())}
                  </SelectMenu.Item>
                  <SelectMenu.Item value={Frequency.StartOfEveryMonth}>
                    {startCase(Frequency.StartOfEveryMonth.toLowerCase())}
                  </SelectMenu.Item>
                  <SelectMenu.Item value={Frequency.EndOfEveryMonth}>
                    {startCase(Frequency.EndOfEveryMonth.toLowerCase())}
                  </SelectMenu.Item>
                </SelectMenu>
              </div>
            </div>
          </div>
        </div>

        <SubmitButton alwaysEnabled>Select Schedule</SubmitButton>
      </Form>
    </Formik>
  );
};
