import React from 'react';
import {
  useGetHighestUsageRecordForCurrentBillingPeriodQuery,
  useGetStripePaymentMethodsQuery,
  useGetAllEventDefinitionsQuery,
  useGetStripeSubscriptionQuery,
  useGetStripeInvoicesQuery,
  useGetTenantWithCreatedByQuery,
  EventDefinitionState,
} from '../../generated/graphql';

import { useUser } from '../../context/user-context';
import Billing from './Billing';

export const BillingContainer = () => {
  const usageRecordQuery = useGetHighestUsageRecordForCurrentBillingPeriodQuery();
  const stripePaymentMethodsQuery = useGetStripePaymentMethodsQuery();
  const eventDefinitionsQuery = useGetAllEventDefinitionsQuery({
    variables: {
      first: 1,
      state: EventDefinitionState.Enabled,
    },
  });
  const tenantWithCreatedByQuery = useGetTenantWithCreatedByQuery();
  const stripeSubscription = useGetStripeSubscriptionQuery();
  const userContext = useUser();
  const invoicesQuery = useGetStripeInvoicesQuery();

  return (
    <Billing
      isPaying={userContext.isPaying}
      userContext={userContext}
      usageRecordQuery={usageRecordQuery}
      tenantWithCreatedByQuery={tenantWithCreatedByQuery}
      invoicesQuery={invoicesQuery}
      eventDefinitionsQuery={eventDefinitionsQuery}
      stripeSubscriptionQuery={stripeSubscription}
      stripePaymentMethodsQuery={stripePaymentMethodsQuery}
    />
  );
};
