import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { UsersIcon, CalendarIcon, UserGroupIcon } from '@heroicons/react/outline';

import { startCase } from 'lodash';
import moment from 'moment-timezone';
import { Stats, Link, DescriptionList, Header, Panel, Table, Badge } from '@shuffl/tailwind-ui-react';

import {
  FeatureFlagKey,
  GetEventDefinitionQueryHookResult,
  GetShufflEventsQueryHookResult,
  ShufflEvent,
} from '../../../../generated/graphql';

import { useFeatureFlag } from '../../../../hooks/use-feature-flag';

export interface ChannelOverviewProps {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;

  shufflEventsQuery: GetShufflEventsQueryHookResult;
  isPayingOrTrialing?: boolean;
}
export const ChannelOverview = ({
  eventDefinitionQuery,
  shufflEventsQuery,
  isPayingOrTrialing,
}: ChannelOverviewProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const shufflEvents = shufflEventsQuery.data?.shufflEventConnection.edges
    ?.map((edge) => edge?.node)
    .filter((shufflEvent) => !!shufflEvent) as ShufflEvent[];
  const shufflEventsCount = shufflEventsQuery.data?.shufflEventConnection.totalCount;
  const expectedNextRunDate = moment(eventDefinition?.nextRunDate).tz(eventDefinition?.schedule.timezone ?? 'UTC');

  const biosFeatureFlag = useFeatureFlag({
    eventDefinitionId: eventDefinition?.id,
    key: FeatureFlagKey.SlackSendBioMessages,
  });

  const numberFormatter = new Intl.NumberFormat('en-US');

  return (
    <>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<CalendarIcon className="h-5 w-5" />}
          stat={numberFormatter.format(shufflEventsCount ?? 0)}
          title="Shuffls"
          linkTo={`../history`}
        />
      </div>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<UsersIcon className="h-5 w-5" />}
          stat={numberFormatter.format(eventDefinition?.latestSnapshot?.users.optedIn.total ?? 0)}
          title="Members Opted In"
          linkTo={`../members`}
        />
      </div>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<UserGroupIcon className="h-5 w-5" />}
          stat={numberFormatter.format(eventDefinition?.groupsCreatedTotal ?? 0)}
          title="Groups Created"
          linkTo={`../history`}
        />
      </div>
      <div className="lg:col-span-6 lg:row-start-2">
        <Header type="h2" className="mb-2">
          Channel Settings
        </Header>
        <Panel padding>
          <div className="lg:col-span-6 lg:row-start-2 mb-6">
            <div className="mb-2">
              <Link to={`../smart-shuffl`}>
                <div className="flex justify-between items-center">
                  <Header type="h3">Smart Shuffl</Header>
                  <div className="ml-2 text-sm">
                    <ArrowRightIcon className="w-4 h-4" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="border-t border-gray-200">
              <DescriptionList>
                <DescriptionList.Item name="Shuffl Algorithm">
                  {eventDefinition?.shufflStrategyToString}
                </DescriptionList.Item>
              </DescriptionList>
            </div>
          </div>
          <div className="lg:col-span-6 lg:row-start-3 mb-6">
            <div className="mb-2 ">
              <Link to={`../schedule`}>
                <div className="flex justify-between items-center">
                  <Header type="h3">Schedule</Header>
                  <div className="ml-2 text-sm">
                    <ArrowRightIcon className="w-4 h-4" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="border-t border-gray-200">
              <DescriptionList>
                <DescriptionList.Item name="Frequency">
                  {startCase(eventDefinition?.schedule.frequency.toLowerCase())}
                </DescriptionList.Item>
                <DescriptionList.Item name="Day of Week">{expectedNextRunDate.format('dddd')}</DescriptionList.Item>
                <DescriptionList.Item name="Time of Day">{expectedNextRunDate.format('h A')}</DescriptionList.Item>
                <DescriptionList.Item name="Timezone">{expectedNextRunDate.tz()}</DescriptionList.Item>
              </DescriptionList>
            </div>
          </div>
          <div className="lg:col-span-6 lg:row-start-4">
            <div className="mb-2">
              <Link to={`../group-settings`}>
                <div className="flex justify-between items-center">
                  <Header type="h3">Group Settings</Header>
                  <div className="ml-2 text-sm">
                    <ArrowRightIcon className="w-4 h-4" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="border-t border-gray-200">
              <DescriptionList>
                <DescriptionList.Item name="Group Size">{eventDefinition?.groupSize ?? 0}</DescriptionList.Item>
                {biosFeatureFlag.featureFlag?.isEnabled && (
                  <DescriptionList.Item name="Bios and Intros">
                    {eventDefinition?.notifications.sendBiosToGroups ? (
                      <Badge color="green">Enabled</Badge>
                    ) : (
                      <Badge color="red">Disabled</Badge>
                    )}
                  </DescriptionList.Item>
                )}
              </DescriptionList>
            </div>
          </div>
        </Panel>
      </div>
      <div className="lg:col-span-6 lg:row-start-2">
        <Header type="h2">Recent Shuffls</Header>
        <div className="mt-2">
          <Table isEmpty={shufflEvents?.length === 0} emptyText="No Shuffls Yet">
            <Table.Header>
              <Table.Header.Cell>Date</Table.Header.Cell>
              <Table.Header.Cell>Shuffl&apos;d Members</Table.Header.Cell>
              <Table.Header.Cell>Groups</Table.Header.Cell>
            </Table.Header>
            <Table.Body>
              {shufflEvents?.map((shufflEvent, index) => {
                return (
                  <Table.Row key={shufflEvent.id ?? index}>
                    <Table.Cell>
                      <Link to={`../history/${shufflEvent?.id}`} disabled={!isPayingOrTrialing}>
                        {moment(shufflEvent.createdAt).format('ll')}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{shufflEvent.eventSnapshot?.users.shuffled.total ?? 0}</Table.Cell>
                    <Table.Cell>{shufflEvent.eventSnapshot?.groups.created.total ?? 0}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>
  );
};
