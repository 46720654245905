import React from 'react';
import ErrorImage from '../../images/error.svg';
import { Page } from '../../components/Page';
import { useUser } from '../../context/user-context';

export const Error = () => {
  const { isAdmin, isInternalAdmin } = useUser();

  return (
    <Page header="Sorry an error occurred!" hideNavLinks={!isAdmin && !isInternalAdmin}>
      <div className="flex flex-1 justify-center items-center relative h-full">
        <img className="w-1/2 lg:w-1/4 h-full" src={ErrorImage} alt="error" />
      </div>
    </Page>
  );
};
