/* eslint-disable import/no-extraneous-dependencies */
import { RetryLink } from '@apollo/client/link/retry';

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      if (operation.operationName === 'GetRefreshToken' && error.message.includes('Failed to fetch')) {
        return true;
      }

      if (
        operation.extensions?.exception?.retryable !== undefined &&
        operation.extensions.exception.retryable === true
      ) {
        return true;
      }

      return !!error;
    },
  },
  delay: {
    initial: 300,
    jitter: true,
    max: 5000,
  },
});

export default retryLink;
