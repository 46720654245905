/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable consistent-return */
import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';
import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { GetRefreshTokenDocument } from '../../../generated/graphql';
import httpLink from './http';
import retryLink from './retry';
import withToken from './with-token';

const refreshClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: withToken.concat(retryLink.concat(httpLink)),
});

let isRefreshing = false;
// eslint-disable-next-line @typescript-eslint/ban-types
let pendingRequests: Function[] = [];

const setIsRefreshing = (refreshing: boolean) => {
  isRefreshing = refreshing;
};

const resetPendingRequests = () => {
  pendingRequests = [];
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const pushPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(() => pendingRequest());
};

const resetToken = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const { message, locations, path, extensions } of graphQLErrors) {
      if (extensions && extensions.code === 'Unauthorized') {
        // error code is set to UNAUTHENTICATED
        // when AuthenticationError thrown in resolver
        let forward$;

        if (operation.operationName === 'GetRefreshToken') {
          return;
        }
        console.debug(`[Expired Token]: Retrying request. Message: ${message}, Location: ${locations}, Path: ${path}`);

        if (!isRefreshing) {
          setIsRefreshing(true);
          forward$ = fromPromise(
            refreshClient
              .query({
                fetchPolicy: 'network-only',
                query: GetRefreshTokenDocument,
              })
              .then((data) => {
                // Store the new tokens for your auth link
                resolvePendingRequests();
                return data;
              })
              .catch((error) => {
                resetPendingRequests();
                console.error('Failed refresh token', operation.operationName, error);
                window.location.reload();
                return null;
              })
              .finally(() => {
                setIsRefreshing(false);
              }),
          ).filter((value) => Boolean(value));
        }

        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
          new Promise((resolve) => {
            pushPendingRequest(resolve);
          }),
        );

        return forward$.flatMap(() => forward(operation));
      }
    }
  }
});

export default resetToken;
