import React, { PropsWithChildren, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Panel, Steps } from '@shuffl/tailwind-ui-react';

import { Page } from '../../../components/Page';

export const ChangePlan = ({ children }: PropsWithChildren<any>) => {
  const selectPlanStepUrl = '/account/billing/change/select-plan';
  const billingContactStepUrl = '/account/billing/change/billing-contact';
  const paymentDetailsStepUrl = '/account/billing/change/payment-details';
  const matchSelectPlan = useMatch(selectPlanStepUrl);
  const matchBillingContact = useMatch(billingContactStepUrl);
  const matchPaymentDetails = useMatch(paymentDetailsStepUrl);
  const navigate = useNavigate();

  useEffect(() => {
    if (!matchSelectPlan && !matchBillingContact && !matchPaymentDetails) {
      navigate(selectPlanStepUrl, { replace: true });
    }
  });

  return (
    <Page>
      <Panel padding={true}>
        <div className="flex flex-col align-center justify-center max-w-3xl mx-auto">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900 sm:truncate">Change your team plan</h1>
            <div className="mt-2 max-w-4xl text-sm text-gray-500">Discover the right plan for your team.</div>
          </div>
          <div className="mb-14">
            <Steps ariaLabel="Select a Plan">
              <Steps.Step
                to="/account/billing/change/select-plan"
                completed={!matchSelectPlan || !!matchBillingContact || !!matchPaymentDetails}
                title="Select a Plan"
              />
              {/* <Steps.Step
                to="/account/billing/change/billing-contact"
                completed={!!matchBillingContact || !!matchPaymentDetails}
                title="Billing Contact"
                disabled
              /> */}
              <Steps.Step
                to="/account/billing/change/payment-details"
                completed={!!matchPaymentDetails}
                title="Payment Details"
                disabled
              />
            </Steps>
          </div>
          <div>{children}</div>
        </div>
      </Panel>
    </Page>
  );
};
