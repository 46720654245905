import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { Button, ButtonProps } from '@shuffl/tailwind-ui-react';

export interface SubmitButtonProps extends ButtonProps {
  alwaysEnabled?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const SubmitButton = ({
  alwaysEnabled,
  className,
  children,
  disabled,
  isLoading,
  ...rest
}: PropsWithChildren<SubmitButtonProps>) => {
  const formikContext = useFormikContext();
  const classNameCopy = classnames(className);

  let isDisabled = true;

  if (formikContext.dirty) {
    isDisabled = false;
  }

  if (alwaysEnabled) {
    isDisabled = false;
  }

  isDisabled = disabled ?? isDisabled;

  return (
    <Button
      className={classNameCopy}
      buttonStyle="primary"
      type="submit"
      isDisabled={isDisabled && !isLoading && !formikContext.isSubmitting}
      isLoading={isLoading ?? formikContext.isSubmitting}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
