import React from 'react';
import DefaultStep, { DefaultStepProps } from '../DefaultStep';

export interface AddToSlackProps extends DefaultStepProps {
  tenantName: string;
  tenantIcon: string;
}

export const AddToSlack = (props: AddToSlackProps) => {
  return (
    <DefaultStep
      content={
        <div className="flex flex-row items-center">
          <img src={props.tenantIcon} alt={props.tenantName} className="mr-4 h-auto w-12" />
          <div className="font-semibold">{props.tenantName}</div>
        </div>
      }
      {...props}
    />
  );
};
