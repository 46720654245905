/* eslint-disable import/no-extraneous-dependencies */
import { setContext } from '@apollo/client/link/context';

const withToken = setContext(async (_, { headers: contextHeaders }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const idToken = urlParams.get('id_token');
  const refreshToken = urlParams.get('refresh_token');

  const headers = {
    ...contextHeaders,
  };

  if (idToken) {
    headers['Authorization'] = `Bearer ${idToken}`;
  }

  if (refreshToken) {
    headers['RefreshToken'] = refreshToken;
  }

  return {
    headers,
  };
});

export default withToken;
