import React from 'react';
import { useParams } from 'react-router';
import { useGetGroupsForShufflEventQuery, useGetShufflEventQuery } from '../../../../../generated/graphql';
import { Shuffl } from './Shuffl';

export const ShufflContainer = () => {
  const { id } = useParams();

  const shufflEventQuery = useGetShufflEventQuery({
    variables: {
      shufflEventId: id,
    },
  });

  const groupsForShufflEventQuery = useGetGroupsForShufflEventQuery({
    variables: {
      shufflEventId: id,
    },
  });

  return <Shuffl shufflEventQuery={shufflEventQuery} groupsForShufflEventQuery={groupsForShufflEventQuery} />;
};
