import {
  Checkbox,
  DropdownSearch,
  Table,
  Listbox,
  ListboxOption,
  IsLoadingProvider,
  Alert,
  LearningHubLink,
} from '@shuffl/tailwind-ui-react';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Avatar from 'react-avatar';
import { uniq } from 'lodash';
import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { SubmitButton } from '../../../../components/Form';
import {
  useUpdateOnboardingInstanceMutation,
  User,
  GetAllUsersLazyQueryHookResult,
  GetSlackConversationUsersQueryHookResult,
  UserRole,
  GetAllUsersQueryHookResult,
  OnboardingDefinitionStepId,
} from '../../../../generated/graphql';
import { useOnboarding } from '../../../OnboardingV3/context';
import { useUser } from '../../../../context/user-context';
import { SelectMembersSchema } from './form-schema';

export interface AddChannelSelectMembersProps {
  usersLazyQuery: GetAllUsersLazyQueryHookResult;
  previouslySelectedUsersQuery: GetAllUsersQueryHookResult;
  slackConversationUsersQuery: GetSlackConversationUsersQueryHookResult;
}

export const AddChannelSelectMembers = ({
  usersLazyQuery,
  previouslySelectedUsersQuery,
  slackConversationUsersQuery,
}: AddChannelSelectMembersProps) => {
  const navigate = useNavigate();
  const { isAdmin, userQuery } = useUser();
  const loggedInUser = userQuery.data?.profile;
  const previouslySelectedUsers = useMemo(
    () =>
      (previouslySelectedUsersQuery.data?.userConnection.edges?.map((edge) => edge?.node).filter((user) => !!user) ??
        []) as User[],
    [previouslySelectedUsersQuery.data?.userConnection.edges],
  );

  const slackConversationUsers = useMemo(
    () =>
      (slackConversationUsersQuery.data?.slackConversationUsers.edges
        ?.map((edge) => edge?.node)
        .filter((user) => !!user) ?? []) as User[],
    [slackConversationUsersQuery.data?.slackConversationUsers.edges],
  );

  const [selectedUsers, setSelectedUsers] = useState<{ [userId: string]: User }>({});

  useEffect(() => {
    const userMap = [...(previouslySelectedUsers ?? []), ...(slackConversationUsers ?? []), loggedInUser].reduce(
      (result, user) => {
        if (user) {
          // eslint-disable-next-line no-param-reassign
          result[user.id] = user;
        }

        return result;
      },
      {} as any,
    );

    setSelectedUsers(userMap);
  }, [previouslySelectedUsers, slackConversationUsers, loggedInUser]);

  const [queryUsersByName, usersQuery] = usersLazyQuery;

  const { onboardingInstanceQuery } = useOnboarding();
  const [updateOnboardingInstance] = useUpdateOnboardingInstanceMutation();
  const onboardingInstance = onboardingInstanceQuery.data?.onboardingInstance;

  const selectChannelMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectChannel,
  )?.metadata;

  let conversationId;
  let conversationName: string | undefined;

  if (selectChannelMetadata) {
    const parsedMetadata = JSON.parse(selectChannelMetadata);
    conversationId = parsedMetadata.conversationId;
    conversationName = parsedMetadata.conversationName;
  }

  const isExistingConversation = conversationId !== 'shuffl-meetups';

  const users = (usersQuery.data?.userConnection.edges?.map((edge) => edge?.node).filter((user) => !!user) ??
    []) as User[];

  const initialSelectedUserIds = uniq([
    loggedInUser?.id,
    ...(previouslySelectedUsers?.map((user) => user.id) ?? []),
    ...(slackConversationUsers?.map((user) => user.id) ?? []),
  ]);

  return (
    <Formik
      validationSchema={SelectMembersSchema}
      initialValues={{ userIds: initialSelectedUserIds }}
      enableReinitialize
      onSubmit={async () => {
        await updateOnboardingInstance({
          variables: {
            isCompleted: true,
            metadata: JSON.stringify({
              users: Object.values(selectedUsers).map((user) => ({
                id: user.id,
                role: user.role,
              })),
            }),
            stepId: OnboardingDefinitionStepId.OnboardingV3SelectMembers,
          },
        });
        await onboardingInstanceQuery.refetch();
        navigate('../schedule');
      }}
    >
      {(formik) => {
        const selectedUserIds: (string | undefined)[] = formik.values['userIds'] ?? [];
        // Also add selected users here
        let usersToList: User[];

        if (isExistingConversation) {
          usersToList = slackConversationUsers;
        } else {
          usersToList = Object.values(selectedUsers).reverse();
        }

        return (
          <Form>
            <div className="mb-8">
              <div className="flex justify-between mb-8 items-center">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {isExistingConversation ? 'Selected Members' : 'Add Members to Channel'}
                  </h3>
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    {isExistingConversation
                      ? `Select roles for the members in ${
                          conversationName ? `#${conversationName}` : 'the Shuffl Channel'
                        }`
                      : `These members will be added to ${
                          conversationName ? `#${conversationName}` : 'the Shuffl Channel'
                        }`}
                  </p>
                </div>
                {!isExistingConversation && (
                  <IsLoadingProvider isLoading={usersQuery.loading}>
                    <div className="w-1/2">
                      <DropdownSearch
                        label="Search for Members"
                        isEmpty={(users?.length ?? 0) === 0}
                        emptyText="Search by Slack User Name, alias, or email"
                        onAtBottom={() => {
                          if (usersQuery.data?.userConnection.pageInfo.hasNextPage && !usersQuery.loading) {
                            usersQuery.fetchMore({
                              variables: {
                                after: usersQuery.data?.userConnection.pageInfo.endCursor,
                              },
                            });
                          }
                        }}
                        onSearchChange={(value) => {
                          if (value) {
                            queryUsersByName({
                              variables: {
                                name: value ?? '',
                              },
                            });
                          }
                        }}
                        onSelect={(user: User) => {
                          selectedUsers[user.id] = user;
                          setSelectedUsers(selectedUsers);
                          formik.setFieldValue('userIds', [user.id, ...selectedUserIds]);
                        }}
                        options={users?.map((user) => {
                          return {
                            disabled: user.id === loggedInUser?.id,
                            label: (
                              <div className="flex items-center">
                                <Avatar
                                  size={'24'}
                                  color="#7c3aed"
                                  round={true}
                                  email={user.slackMetadata?.image ? undefined : (user.email as string)}
                                  src={user.slackMetadata?.image ?? ''}
                                  alt={user.slackMetadata?.realName ?? ''}
                                  name={user.slackMetadata?.realName ?? ''}
                                />
                                <span className="font-normal ml-3 block truncate mr-auto">
                                  <div className="flex flex-row space-x-2">
                                    <div>{user.slackMetadata?.realName}</div>
                                    {user.slackMetadata?.displayName && (
                                      <div className="text-gray-500">{`@${user.slackMetadata?.displayName}`}</div>
                                    )}
                                  </div>
                                </span>
                                {selectedUsers[user.id] && (
                                  <div>
                                    <CheckIcon className="w-5 h-5 text-gray-500" />
                                  </div>
                                )}
                              </div>
                            ),
                            value: user,
                          };
                        })}
                      />
                    </div>
                  </IsLoadingProvider>
                )}
              </div>
              <IsLoadingProvider
                isLoading={slackConversationUsersQuery.loading || previouslySelectedUsersQuery.loading}
              >
                <Table
                  isEmpty={(usersToList?.length ?? 0) === 0}
                  emptyText="No Members"
                  stickyHeaders
                  height="lg"
                  onAtBottom={() => {
                    if (
                      slackConversationUsersQuery.data?.slackConversationUsers.pageInfo.hasNextPage &&
                      !slackConversationUsersQuery.loading &&
                      isExistingConversation
                    ) {
                      slackConversationUsersQuery.fetchMore({
                        variables: {
                          after: slackConversationUsersQuery.data?.slackConversationUsers.pageInfo.endCursor,
                        },
                      });
                    }
                  }}
                >
                  <Table.Header hidden>
                    <Table.Header.Cell>Name</Table.Header.Cell>
                    <Table.Header.Cell>Role</Table.Header.Cell>
                  </Table.Header>
                  <Table.Body>
                    {usersToList?.map((user) => {
                      const userRoleOptions: ListboxOption<UserRole>[] = [
                        {
                          description: 'Users will be shuffled but they do not have access to the Shuffl Portal',
                          disabled: loggedInUser?.id === user.id,
                          selected: user.role === UserRole.User,
                          title: 'User',
                          value: UserRole.User,
                        },
                        {
                          description: 'Admins have the ability to add other Admins and manage Shuffl channel settings',
                          disabled: loggedInUser?.id === user.id,
                          selected: user.role === UserRole.Admin,
                          title: 'Admin',
                          value: UserRole.Admin,
                        },
                        {
                          description:
                            'Super Admins can perform all abilities granted to Admins and manage Billing settings',
                          disabled: isAdmin && loggedInUser?.id === user.id,
                          selected: user.role === UserRole.SuperAdmin,
                          title: 'Super Admin',
                          value: UserRole.SuperAdmin,
                        },
                      ];

                      return (
                        <Table.Row key={user.id}>
                          <Table.Cell>
                            {!isExistingConversation && loggedInUser?.id !== user.id && (
                              <Checkbox
                                id={user.id}
                                center
                                name="userIds"
                                value={user.id}
                                onChange={(e: any) => {
                                  if (e.target.checked) {
                                    selectedUsers[user.id] = user;
                                    setSelectedUsers(selectedUsers);
                                  } else {
                                    if (selectedUsers && user.id in selectedUsers) {
                                      delete selectedUsers[user.id];
                                    }

                                    setSelectedUsers(selectedUsers);
                                  }
                                }}
                                label={
                                  <div className="flex items-center space-x-2">
                                    <Avatar
                                      size={'40'}
                                      color="#7c3aed"
                                      round={true}
                                      email={user.email ?? ''}
                                      src={user.slackMetadata?.image ?? ''}
                                      alt={user.slackMetadata?.realName ?? ''}
                                      name={user.slackMetadata?.realName ?? ''}
                                    />
                                    <div className="flex flex-col">
                                      <div>{user.slackMetadata?.realName}</div>
                                      {user.slackMetadata?.displayName && (
                                        <div className="text-gray-500">{`@${user.slackMetadata?.displayName}`}</div>
                                      )}
                                    </div>
                                  </div>
                                }
                              />
                            )}
                            {loggedInUser?.id === user.id && (
                              <div
                                className={classNames('flex items-center space-x-2', {
                                  'ml-7': !isExistingConversation,
                                })}
                              >
                                <Avatar
                                  size={'40'}
                                  color="#7c3aed"
                                  round={true}
                                  email={user.email ?? ''}
                                  src={user.slackMetadata?.image ?? ''}
                                  alt={user.slackMetadata?.realName ?? ''}
                                  name={user.slackMetadata?.realName ?? ''}
                                />
                                <div className="flex flex-col">
                                  <div>{user.slackMetadata?.realName}</div>
                                  {user.slackMetadata?.displayName && (
                                    <div className="text-gray-500">{`@${user.slackMetadata?.displayName}`}</div>
                                  )}
                                </div>
                              </div>
                            )}
                            {loggedInUser?.id !== user.id && isExistingConversation && (
                              <div className="flex items-center space-x-2">
                                <Avatar
                                  size={'40'}
                                  color="#7c3aed"
                                  round={true}
                                  email={user.email ?? ''}
                                  src={user.slackMetadata?.image ?? ''}
                                  alt={user.slackMetadata?.realName ?? ''}
                                  name={user.slackMetadata?.realName ?? ''}
                                />
                                <div className="flex flex-col">
                                  <div>{user.slackMetadata?.realName}</div>
                                  {user.slackMetadata?.displayName && (
                                    <div className="text-gray-500">{`@${user.slackMetadata?.displayName}`}</div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {loggedInUser?.id !== user.id && (
                              <span className="flex justify-end">
                                {selectedUserIds?.includes(user.id) && (
                                  <Listbox
                                    label="User Role"
                                    options={userRoleOptions}
                                    onChange={(role) => {
                                      if (selectedUsers && user.id in selectedUsers) {
                                        selectedUsers[user.id] = {
                                          ...selectedUsers[user.id],
                                          role,
                                        };
                                      }

                                      setSelectedUsers(selectedUsers);
                                    }}
                                  />
                                )}
                              </span>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </IsLoadingProvider>
            </div>
            {((slackConversationUsersQuery.data?.slackConversationUsers.totalCount ?? 0) > 50 ||
              (previouslySelectedUsersQuery.data?.userConnection.totalCount ?? 0) > 50 ||
              Object.values(selectedUsers).length > 50) && (
              <div className="mb-8">
                <Alert
                  title={<div>You have selected more than 50 members.</div>}
                  details={
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <div>
                          Shuffl Community supports up to 50 people, so a random selection of 50 lucky members will get
                          shuffl&apos;d!
                        </div>
                        <div>
                          To shuffl everyone please upgrade to Shuffl Pro on the Billing page after you finish
                          onboarding.
                        </div>
                      </div>
                      <LearningHubLink articleId="5049334-what-is-shuffl-s-pricing-model" size="sm">
                        Learn more about Shuffl Pro in our Learning Hub
                      </LearningHubLink>
                    </div>
                  }
                />
              </div>
            )}
            <SubmitButton disabled={Object.values(selectedUsers).length < 2}>Select Members</SubmitButton>
          </Form>
        );
      }}
    </Formik>
  );
};
