import React, { PropsWithChildren } from 'react';
import { GetOnboardingInstanceQueryHookResult, useGetOnboardingInstanceQuery } from '../../generated/graphql';

export interface OnboardingContextInterface {
  onboardingInstanceQuery: GetOnboardingInstanceQueryHookResult;
}

const OnboardingContext = React.createContext<OnboardingContextInterface>({} as any);

const OnboardingProvider = (props: PropsWithChildren<any>) => {
  const onboardingInstanceQuery = useGetOnboardingInstanceQuery();

  return <OnboardingContext.Provider value={{ onboardingInstanceQuery }} {...props} />;
};

function useOnboarding() {
  const context = React.useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error(`useOnboarding must be used within a OnboardingContext`);
  }

  return context;
}

export { OnboardingProvider, useOnboarding };
