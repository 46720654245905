import React from 'react';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router';
import { Button } from '@shuffl/tailwind-ui-react';
import { useUser } from '../../../context/user-context';
import { OnboardingDefinitionStepId, useUpdateOnboardingInstanceMutation } from '../../../generated/graphql';
import { useOnboarding } from '../context';
import { CommunityDetails } from '../../Billing/ChangePlan/components/CommunityDetails';

export const OnboardingWelcome = () => {
  const { tenantQuery } = useUser();
  const navigate = useNavigate();
  const { onboardingInstanceQuery } = useOnboarding();
  const [updateOnboardingInstance] = useUpdateOnboardingInstanceMutation({
    variables: {
      isCompleted: true,
      stepId: OnboardingDefinitionStepId.OnboardingV3StartOnboarding,
    },
  });
  const tenant = tenantQuery.data?.tenant;

  return (
    <>
      <div className="mb-8">
        <h3 className="flex items-center text-lg leading-6 font-medium text-gray-900">
          Let&apos;s start onboarding the{' '}
          <div className="flex flex-row items-center mx-2">
            <Avatar
              src={tenant?.slackMetadata?.team.icon as string}
              alt={tenant?.slackMetadata?.team.name}
              name={tenant?.slackMetadata?.team.name}
              round={true}
              size={'25'}
              color="#7c3aed"
              className="mr-1"
            />
            <div className="font-semibold">{tenant?.slackMetadata?.team.name}</div>
          </div>{' '}
          Slack Workspace to Shuffl
        </h3>
        <p className="my-2">With the Shuffl Community plan you will have access to the following.</p>
        <CommunityDetails />
        <p className="mt-4 max-w-4xl text-sm text-gray-500">
          Once you have gone through the onboarding steps, you will be able to configure more advance options for each
          Channel. You can upgrade your plan later in order to gain access to more Shuffl fun!
        </p>
      </div>
      <div className="mb-8 w-1/2">
        <Button
          onClick={async () => {
            await updateOnboardingInstance();
            await onboardingInstanceQuery.refetch();
            navigate('../channel');
          }}
        >
          Start Onboarding
        </Button>
      </div>
    </>
  );
};
