import React from 'react';
import { Outlet } from 'react-router-dom';
import { AddChannelProvider } from './context';
import { AddChannel } from './Add';

export const AddChannelContainer = () => {
  return (
    <AddChannelProvider>
      <AddChannel>
        <Outlet />
      </AddChannel>
    </AddChannelProvider>
  );
};
