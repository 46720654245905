import { Frequency, DayOfWeek } from '../generated/graphql';

export function numberToFrequency(frequencyAsNumber: number) {
  switch (frequencyAsNumber) {
    case 1:
      return Frequency.EveryWeek;
    case 2:
      return Frequency.EveryTwoWeeks;
    case 3:
      return Frequency.EveryThreeWeeks;
    case 4:
      return Frequency.EveryFourWeeks;
    default:
      return Frequency.EveryWeek;
  }
}

export function numberToDayOfWeek(dayOfWeekAsNumber: number) {
  switch (dayOfWeekAsNumber) {
    case 0:
      return DayOfWeek.Sunday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    default:
      return DayOfWeek.Monday;
  }
}

export function dayOfWeekToNumber(dayOfWeek: DayOfWeek) {
  switch (dayOfWeek) {
    case DayOfWeek.Sunday:
      return 0;
    case DayOfWeek.Monday:
      return 1;
    case DayOfWeek.Tuesday:
      return 2;
    case DayOfWeek.Wednesday:
      return 3;
    case DayOfWeek.Thursday:
      return 4;
    case DayOfWeek.Friday:
      return 5;
    case DayOfWeek.Saturday:
      return 6;
    default:
      return 0;
  }
}
