import React from 'react';
import { Outlet } from 'react-router-dom';
import { AddChannelProvider } from '../Channels/Add/context';
import { OnboardingProvider } from './context';
import { Onboarding } from './Onboarding';

export const OnboardingContainer = () => {
  return (
    <OnboardingProvider>
      <AddChannelProvider>
        <Onboarding>
          <Outlet />
        </Onboarding>
      </AddChannelProvider>
    </OnboardingProvider>
  );
};
