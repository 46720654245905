/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { ApolloProvider as BaseApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import generatedIntrospection from '../../generated/fragment-types';
import errorLink from './links/error';
import authFlowLink from './links/auth-flow';
import retryLink from './links/retry';
import httpLink from './links/http';

export const ApolloProvider: React.FC = ({ children }) => {
  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          eventDefinitionConnection: relayStylePagination(['first', 'where']),
          groupConnection: relayStylePagination(['first', 'where']),
          shufflEventConnection: relayStylePagination(['first', 'where']),
          slackConversationUsers: relayStylePagination(['first', 'where']),
          slackConversations: relayStylePagination(['first', 'where']),
          userConnection: relayStylePagination(['first', 'where']),
          userEventDefinitionConnection: relayStylePagination(['first', 'where']),
        },
      },
    },
  });

  const client = new ApolloClient({
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
    link: errorLink.concat(authFlowLink.concat(retryLink.concat(httpLink))),
  });

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};
