import React from 'react';
import moment from 'moment-timezone';
import { Table, IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { GetStripeSubscriptionQueryHookResult, GetTenantQueryHookResult } from '../../../../../generated/graphql';

export interface ProMonthlyPriceProps {
  tenantQuery?: GetTenantQueryHookResult;
  stripeSubscriptionQuery?: GetStripeSubscriptionQueryHookResult;
}

export const ProMonthlyPrice = ({ tenantQuery, stripeSubscriptionQuery }: ProMonthlyPriceProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  const tenant = tenantQuery?.data?.tenant;
  const stripeSubscription = stripeSubscriptionQuery?.data?.stripeSubscription;

  const formattedPrice = formatter.format(Number(stripeSubscription?.cost ?? 5000) / 100);

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="font-medium">Shuffl Pro Monthly</span>
                <span className="text-sm text-gray-500 font-medium">
                  Effective since {moment(tenant?.payingStartAt).format('ll')}
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="text-sm text-gray-500 font-medium">
                {numberFormatter.format(stripeSubscription?.priceTier?.min ?? 1)} -{' '}
                {numberFormatter.format(stripeSubscription?.priceTier?.max ?? 50)} members
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">Unlimited Channels</span>
                <span className="text-sm text-gray-500 font-medium">Unlimited Shuffl Events</span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{formattedPrice} / month</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
