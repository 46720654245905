import React from 'react';
import { useLocation } from 'react-router-dom';
import { Divider } from '@shuffl/tailwind-ui-react';
import SlackButton from '../../components/SlackButton';
import { ReactComponent as LogoIcon } from '../../images/shuffl-bot-gradient.svg';
import { ReactComponent as LogoText } from '../../images/shuffl-wordmark.svg';
import Footer from '../../components/Footer';
import { ReactComponent as ConnectingImage } from '../../images/undraw/connection.svg';

export const Unauthenticated = () => {
  const location = useLocation();
  const redirectUrl =
    new URLSearchParams(location.search).get('redirectUrl') ?? process.env.REACT_APP_SLACK_REDIRECT_URL;

  return (
    <div className="flex flex-col h-screen">
      <header
        id="site-header"
        className="fixed w-full z-30 md:bg-opacity-95 transition duration-300 ease-in-out bg-white"
      >
        <div className="max-w-7xl mx-auto py-3 md:py-5 px-5 sm:px-6 flex items-center justify-between">
          <div className="flex-shrink-0 mr-4">
            <a className="flex items-center pr-3" href="/" aria-label="Shuffl">
              <LogoIcon className="w-8 h-8" />
              <LogoText className="h-5 ml-3" />
            </a>
          </div>
        </div>
      </header>
      <section className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* <!-- Hero content --> */}
        <div className="pt-40 pb-24 lg:grid lg:grid-cols-12 lg:gap-8">
          {/* <!-- Section header --> */}
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1 className="text-6xl font-semibold leading-tight mb-10 font-playfair">
              Hello,{' '}
              <span className="text-purple-600">
                Future <span className="whitespace-nowrap">of Work</span>
              </span>
            </h1>
            <p className="text-2xl mb-12 max-w-lg">
              Build great teams when groups meet with intention and AI-powered shuffls.
            </p>
            <div className="flex flex-col justify-center items-center w-1/2">
              <SlackButton redirectUrl={redirectUrl} oauthType="add-to-slack" />
              <div className="my-6 w-1/2">
                <Divider>Or</Divider>
              </div>
              <SlackButton redirectUrl={redirectUrl} oauthType="sign-in" />
            </div>
          </div>
          <div className="lg:col-span-6 pl-10">
            <ConnectingImage className="w-full" />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
