import React from 'react';
import { useGetTenantQuery } from '../../../../generated/graphql';
import { useEventDefinition } from '../../context/event-definition-context';
import { Notifications } from './Notifications';

export const NotificationsContainer = () => {
  const { eventDefinitionQuery } = useEventDefinition();
  const tenantQuery = useGetTenantQuery();

  return <Notifications eventDefinitionQuery={eventDefinitionQuery} tenantQuery={tenantQuery} />;
};
