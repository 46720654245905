// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((graphqlError) => {
      const { message, locations, path, extensions, name, stack } = graphqlError;
      if (
        extensions?.code === 'Not Found' ||
        extensions?.code === 'Unauthorized' ||
        operation.operationName === 'GetRefreshToken'
      ) {
        return;
      }

      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      Sentry.captureException(
        new Error(`{
          message,
          name,
          stack,
        },`),
        {
          extra: {
            extensions,
            locations,
            path,
          },
          tags: {
            operationName: operation.operationName,
          },
        },
      );
    });

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError, {
      tags: {
        operationName: operation.operationName,
      },
    });
  }
});

export default errorLink;
