import { DocumentReportIcon } from '@heroicons/react/outline';
import React from 'react';
import { Table, IsLoadingProvider } from '@shuffl/tailwind-ui-react';

import {
  GetAllEventDefinitionsQueryHookResult,
  GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult,
} from '../../../../../generated/graphql';

export interface ProTrialUsageProps {
  usageRecordQuery?: GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult;
  eventDefinitionsQuery?: GetAllEventDefinitionsQueryHookResult;
}

export const ProTrialUsage = ({ usageRecordQuery, eventDefinitionsQuery }: ProTrialUsageProps) => {
  const usageRecord = usageRecordQuery?.data?.highestUsageRecordForCurrentBillingPeriod;
  const eventDefinitionsCount = eventDefinitionsQuery?.data?.eventDefinitionConnection?.totalCount;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  const formattedPrice = currencyFormatter.format(Number(usageRecord?.cost ?? 0) / 100);
  const formattedNumberOfEventDefinitions = numberFormatter.format(
    usageRecord?.numberOfEventDefinitions ?? eventDefinitionsCount ?? 0,
  );
  const formattedNumberOfShufflEvents = numberFormatter.format(usageRecord?.numberOfShufflEvents ?? 0);

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="font-medium">Shuffl Pro Trial</span>
            </Table.Cell>
            <Table.Cell padding={false}>
              {usageRecord && usageRecord.reportUrl && (
                <a href={usageRecord.reportUrl} className="text-sm font-medium text-purple-600 hover:text-purple-500">
                  <div className="flex items-center">
                    <div className="mr-2">{numberFormatter.format(usageRecord.memberQuantity ?? 0)} members</div>
                    <DocumentReportIcon className="w-5 h-5" />
                  </div>
                </a>
              )}
              {(!usageRecord || !usageRecord.reportUrl) && (
                <div className="text-sm text-gray-500 font-medium">0 members</div>
              )}
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">{formattedNumberOfEventDefinitions} Channels</span>
                <span className="text-sm text-gray-500 font-medium">{formattedNumberOfShufflEvents} Shuffl Events</span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{formattedPrice}</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
