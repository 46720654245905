/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { QueryResult } from '@apollo/client/react';
import {
  useGetAllEventDefinitionsQuery,
  GetAllEventDefinitionsQuery,
  GetAllEventDefinitionsQueryVariables,
} from '../../../generated/graphql';

interface EventDefinitionsContextInterface {
  eventDefinitionsQuery?: QueryResult<GetAllEventDefinitionsQuery, GetAllEventDefinitionsQueryVariables>;
}

const EventDefinitionsContext = React.createContext<EventDefinitionsContextInterface>({});

const EventDefinitionsProvider: React.FC = (props) => {
  const eventDefinitionsQuery = useGetAllEventDefinitionsQuery();

  return <EventDefinitionsContext.Provider value={{ eventDefinitionsQuery }} {...props} />;
};

function useEventDefinitions() {
  const context = React.useContext(EventDefinitionsContext);

  if (context === undefined) {
    throw new Error(`useEventDefinitions must be used within a EventDefinitionsProvider`);
  }

  return context;
}

export { EventDefinitionsProvider, useEventDefinitions };
