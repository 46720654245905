import React from 'react';
// import { ReactComponent as ShufflText } from '../../images/shuffl-text.svg';
import { LoadingIcon } from '@shuffl/tailwind-ui-react';
import Footer from '../Footer';

export const FullPageLoader = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="w-full flex flex-col flex-1 bg-white h-full justify-center items-center text-center">
        {/* <ShufflText className="h-auto text-orange-400 fill-current" /> */}
        <h1 className="text-6xl font-semibold leading-tight mb-10 font-playfair">
          Hello, <span className="text-purple-600 whitespace-nowrap">Future of Work</span>
        </h1>
        <p className="text-2xl mb-10 max-w-lg">
          Build great teams when groups meet with intention and AI-powered shuffls.
        </p>
        <LoadingIcon />
      </div>
      <Footer />
    </div>
  );
};
