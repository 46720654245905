import React from 'react';
import { StripeProvider } from '../../../../context/stripe-context';
import { StripePaymentForm, StripePaymentFormProps } from './StripePaymentForm';

export const StripePaymentFormContainer = (props: StripePaymentFormProps) => {
  return (
    <StripeProvider>
      <StripePaymentForm {...props} />
    </StripeProvider>
  );
};
