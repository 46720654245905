import React, { useEffect } from 'react';
import { useLocation, useMatch, Routes, Route } from 'react-router-dom';
import { useUser } from '../context/user-context';
import { FullPageLoader } from '../components/FullPageLoader';
import { useAuth } from '../context/auth-context';
import { Authenticated } from '../pages/Authenticated';
import { Unauthenticated } from '../pages/Unauthenticated';

export function AppContainer() {
  const { isAuthenticated, loading: authLoading } = useAuth();
  const { loading: userLoading } = useUser();
  const matchLogin = useMatch('/login');
  const location = useLocation();

  useEffect(() => {
    if (authLoading || userLoading) return;

    async function handleAuthRedirect() {
      let redirectUrl;
      const loginUrl = new URL('/login', window.location.origin);

      if (isAuthenticated) {
        redirectUrl = new URLSearchParams(location.search).get('redirectUrl') ?? '';
      } else {
        const currentUrl = new URL(window.location.href);
        const existingRedirectUrl = currentUrl.searchParams.get('redirectUrl');
        loginUrl.searchParams.append('redirectUrl', existingRedirectUrl ?? currentUrl.toString());
      }

      if (isAuthenticated) {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else if (matchLogin) {
          window.location.replace('/');
        }
      }

      if (!isAuthenticated && !matchLogin) {
        window.location.replace(loginUrl.toString());
      }

      window.Intercom('update');
    }

    handleAuthRedirect();
  }, [isAuthenticated, location.search, matchLogin, authLoading, userLoading]);

  if (authLoading || userLoading) return <FullPageLoader />;

  return (
    <Routes>
      <Route element={<Unauthenticated />} path="login" />
      {isAuthenticated && <Route element={<Authenticated />} path="*" />}
    </Routes>
  );
}
