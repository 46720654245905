import React from 'react';
import { useParams } from 'react-router-dom';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useUser } from '../../../../context/user-context';
import { useGetShufflEventsQuery } from '../../../../generated/graphql';
import { useEventDefinition } from '../../context/event-definition-context';
import { ChannelOverview } from './Overview';

export const ChannelOverviewContainer = () => {
  const eventDefinition = useEventDefinition();
  const { id } = useParams();
  const { isPayingOrTrialing } = useUser();

  const shufflEventsQuery = useGetShufflEventsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: {
      eventDefinitionId: id,
      first: 5,
    },
  });

  return (
    <IsLoadingProvider isLoading={eventDefinition.eventDefinitionQuery.loading || shufflEventsQuery.loading}>
      <ChannelOverview
        shufflEventsQuery={shufflEventsQuery}
        eventDefinitionQuery={eventDefinition.eventDefinitionQuery}
        isPayingOrTrialing={isPayingOrTrialing}
      />
    </IsLoadingProvider>
  );
};
