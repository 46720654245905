import React from 'react';
import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { useUser } from './user-context';

interface MixpanelContextInterface {
  identify: typeof mixpanel.identify;
  alias: typeof mixpanel.alias;
  track: typeof mixpanel.track;
  people: {
    set: typeof mixpanel.people.set;
  };
}

const MixpanelContext = React.createContext<MixpanelContextInterface>({ ...mixpanel });
let hasBeenCalled = false;

const MixpanelProvider: React.FC = (props) => {
  const { userQuery, tenantQuery } = useUser();

  const enabled = Boolean(process.env.REACT_APP_MIXPANEL_ENABLED ?? false) === true;
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;

  if (enabled) {
    if (token) {
      mixpanel.init(token, {
        debug: Boolean(process.env.REACT_APP_MIXPANEL_DEBUG ?? false) === true ?? false,
        verbose: Boolean(process.env.REACT_APP_MIXPANEL_VERBOSE ?? false) === true ?? false,
      });
    } else {
      throw new Error(
        'process.env.REACT_APP_MIXPANEL_TOKEN must be defined if process.env.REACT_APP_MIXPANEL_ENABLED is true',
      );
    }
  }

  const mixpanelWrapper = {
    alias: mixpanel.alias.bind(mixpanel),
    identify: mixpanel.identify.bind(mixpanel),
    people: {
      set: mixpanel.people.set.bind(mixpanel),
    },
    track: (
      event_name: string,
      properties?: Dict | undefined,
      optionsOrCallback?: RequestOptions | Callback | undefined,
      callback?: Callback | undefined,
    ) => {
      if (userQuery.data?.profile.impersonatedBy) {
        return null;
      }

      return mixpanel.track(
        event_name,
        {
          distinct_id: userQuery.data?.profile.id,
          tenantId: tenantQuery.data?.tenant.id,
          ...properties,
        },
        optionsOrCallback,
        callback,
      );
    },
  } as MixpanelContextInterface;

  if (userQuery && !userQuery.data?.profile.impersonatedBy && !hasBeenCalled) {
    mixpanelWrapper.identify(userQuery.data?.profile.id);
    mixpanelWrapper.track('user-login');
    hasBeenCalled = true;
  }

  return <MixpanelContext.Provider value={{ ...mixpanelWrapper }} {...props} />;
};

function useMixpanel() {
  const context = React.useContext(MixpanelContext);

  if (context === undefined) {
    throw new Error(`useMixpanel must be used within a MixpanelProvider`);
  }

  return context;
}

export { MixpanelProvider, useMixpanel };
