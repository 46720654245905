import React from 'react';
import moment from 'moment-timezone';
import { Table, IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { GetStripeSubscriptionQueryHookResult, GetTenantQueryHookResult } from '../../../../../generated/graphql';

export interface ProYearlyPriceProps {
  stripeSubscriptionQuery?: GetStripeSubscriptionQueryHookResult;
  tenantQuery?: GetTenantQueryHookResult;
}

export const ProYearlyPrice = ({ stripeSubscriptionQuery, tenantQuery }: ProYearlyPriceProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  const stripeSubscription = stripeSubscriptionQuery?.data?.stripeSubscription;
  const tenant = tenantQuery?.data?.tenant;
  const yearlyFormattedPrice = formatter.format(Number(stripeSubscription?.prePaidCost ?? 0) / 100); /* $2,500.00 */

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="font-medium">Shuffl Pro Yearly</span>
                <span className="text-sm text-gray-500 font-medium">
                  Effective since {moment(tenant?.payingStartAt).format('ll')}
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">
                  {numberFormatter.format(stripeSubscription?.prePaidMemberQuantity ?? 0)} prepaid members
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">Unlimited Channels</span>
                <span className="text-sm text-gray-500 font-medium">Unlimited Shuffl Events</span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{yearlyFormattedPrice} / year</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
