import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MailIcon } from '@heroicons/react/solid';
import { Form, Formik } from 'formik';
import { InputGroup } from '@shuffl/tailwind-ui-react';
import { useAddMember } from '../context';
import { useFindUserByEmailLazyQuery } from '../../../../generated/graphql';
import { SubmitButton } from '../../../../components/Form';
import { FormData, EmailSchema } from './form-schema';

export const Email = () => {
  const navigate = useNavigate();

  const { email, setEmail } = useAddMember();
  const [findUserByEmail, { data, loading, error }] = useFindUserByEmailLazyQuery();

  if (data && !loading && !error) {
    navigate('/members/add/role');
  }

  return (
    <Formik
      validationSchema={EmailSchema}
      initialValues={{ email }}
      enableReinitialize
      onSubmit={async (submitData: FormData) => {
        findUserByEmail({ variables: { email: submitData.email } });
        setEmail(submitData.email);
      }}
    >
      {(formik) => {
        if (error?.graphQLErrors[0].extensions?.code === 'Not Found') {
          formik.setFieldError('email', 'Email not found');
        }

        return (
          <Form>
            <div className="mb-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Email of the member to add</h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">Please enter their email used for Slack</p>
            </div>
            <div className="mb-8 w-1/2">
              <InputGroup
                type="email"
                name="email"
                aria-describedby="Member Email"
                placeholder="example@slack.com"
                label="Member email"
                icon={<MailIcon className="h-5 w-5" />}
                required
              />
            </div>
            <SubmitButton isLoading={loading}>Continue</SubmitButton>
          </Form>
        );
      }}
    </Formik>
  );
};
