import { useGetFeatureFlagQuery, FeatureFlagKey, FeatureFlagVariableKey } from '../generated/graphql';

export interface UseFeatureFlagProps {
  eventDefinitionId?: string;
  key: FeatureFlagKey;
}

export function useFeatureFlag({ key, eventDefinitionId }: UseFeatureFlagProps) {
  const featureFlagQuery = useGetFeatureFlagQuery({
    variables: {
      eventDefinitionId,
      key,
    },
  });

  const featureFlag = featureFlagQuery.data?.featureFlag;

  return {
    featureFlag,
    getVariable: (variableKey: FeatureFlagVariableKey) => {
      return featureFlag?.variables?.find((variable) => variable.key === variableKey);
    },
    loading: featureFlagQuery.loading,
  };
}
