import React from 'react';
import { Table, IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import {
  GetAllEventDefinitionsQueryHookResult,
  GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult,
} from '../../../../../generated/graphql';

export interface CommunityUsageProps {
  usageRecordQuery?: GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult;
  eventDefinitionsQuery?: GetAllEventDefinitionsQueryHookResult;
}

export const CommunityUsage = ({ usageRecordQuery, eventDefinitionsQuery }: CommunityUsageProps) => {
  const usageRecord = usageRecordQuery?.data?.highestUsageRecordForCurrentBillingPeriod;
  const eventDefinitionsCount = eventDefinitionsQuery?.data?.eventDefinitionConnection?.totalCount;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  const formattedPrice = currencyFormatter.format(0);
  const formattedNumberOfEventDefinitions = numberFormatter.format(
    usageRecord?.numberOfEventDefinitions ?? eventDefinitionsCount ?? 0,
  );
  const formattedNumberOfShufflEvents = numberFormatter.format(usageRecord?.numberOfShufflEvents ?? 0);

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="font-medium">Shuffl Community</span>
            </Table.Cell>
            <Table.Cell padding={false}>
              {usageRecord && (
                <div className="text-sm text-gray-500 font-medium">
                  {numberFormatter.format(usageRecord.memberQuantity ?? 0)} members
                </div>
              )}
              {!usageRecord && <div className="text-sm text-gray-500 font-medium">0 members</div>}
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">
                  {formattedNumberOfEventDefinitions} / 1 Channels
                </span>
                <span className="text-sm text-gray-500 font-medium">
                  {formattedNumberOfShufflEvents} / 4 Shuffl Events
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{formattedPrice}</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
