import { useMatch } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';
import { Panel, Steps } from '@shuffl/tailwind-ui-react';

import { Page } from '../../../components/Page';

export const AddMember = ({ children }: PropsWithChildren<any>) => {
  const emailStepUrl = '/members/add/email';
  const permissionsStepUrl = '/members/add/role';
  const matchEmail = useMatch(emailStepUrl);
  const matchPermissions = useMatch(permissionsStepUrl);

  return (
    <Page>
      <Panel padding={true}>
        <div className="flex flex-col align-center justify-center max-w-3xl mx-auto">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900 sm:truncate">Add New Admin</h1>
            <div className="mt-2 max-w-4xl text-sm text-gray-500">
              Enable Shuffl roles and permissions for team members
            </div>
          </div>
          <div className="mb-14">
            <Steps ariaLabel="Add Member">
              <Steps.Step to="/members/add/email" completed={!matchEmail || !!matchPermissions} title="Enter Email" />
              <Steps.Step to="/members/add/role" completed={!!matchPermissions} title="Assign Role" disabled />
            </Steps>
          </div>
          <div>{children}</div>
        </div>
      </Panel>
    </Page>
  );
};
