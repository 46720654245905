import React from 'react';
import moment from 'moment-timezone';
import { IsLoadingProvider, Table } from '@shuffl/tailwind-ui-react';
import { GetTenantQueryHookResult } from '../../../../../generated/graphql';

export interface CommunityPriceProps {
  tenantQuery?: GetTenantQueryHookResult;
}

export const CommunityPrice = ({ tenantQuery }: CommunityPriceProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  const formattedPrice = formatter.format(0);
  const tenant = tenantQuery?.data?.tenant;

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="font-medium">Shuffl Community</span>
                <span className="text-sm text-gray-500 font-medium">
                  Effective since {moment(tenant?.createdAt).format('ll')}
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="text-sm text-gray-500 font-medium">50 members</span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">1 Channel</span>
                <span className="text-sm text-gray-500 font-medium">4 Shuffl Events</span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{formattedPrice}</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
