import React from 'react';
import { useUser } from '../../../../context/user-context';
import { useGetSlackConversationsQuery } from '../../../../generated/graphql';
import { AddChannelSelectChannel } from './SelectChannel';

export const AddChannelSelectChannelContainer = () => {
  const slackConversationsQuery = useGetSlackConversationsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 25,
      name: '',
    },
  });

  const { tenantQuery } = useUser();

  return (
    <AddChannelSelectChannel
      tenantQuery={tenantQuery}
      slackConversationsQuery={slackConversationsQuery}
    ></AddChannelSelectChannel>
  );
};
