import React from 'react';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useGetAllAdminsQuery } from '../../generated/graphql';
import { Members } from './Members';

export const MembersContainer = () => {
  const allAdminsQuery = useGetAllAdminsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 25,
    },
  });

  return (
    <IsLoadingProvider isLoading={allAdminsQuery.loading}>
      <Members allAdminsQuery={allAdminsQuery} />
    </IsLoadingProvider>
  );
};
