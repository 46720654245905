import React from 'react';
import ReactDOM from 'react-dom';
import './load-font-awesome-icons';
import './styles/index.css';
import { AppContainer } from './apps/AppContainer';
import { AppProviders } from './context/app-providers';

ReactDOM.render(
  <AppProviders>
    <AppContainer />
  </AppProviders>,
  document.getElementById('root'),
);
