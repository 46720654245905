import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { CheckIcon } from '@heroicons/react/solid';

export interface DefaultStepProps {
  step?: any;
  isCurrentStep?: boolean;
  stepDefinition?: any;
  nextRunDate?: string;
  content?: ReactNode;
  accessory?: ReactNode;
  footer?: ReactNode;
  loading?: boolean;
}

const DefaultStep = ({ step, isCurrentStep, content, stepDefinition, accessory, footer }: DefaultStepProps) => {
  const textClassName = classnames('text-lg', {
    'mb-2': step?.instruction && isCurrentStep,
    'text-gray-400': !step?.isCompleted && !isCurrentStep,
  });

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col md:flex-row w-full">
        <div className="mr-0 md:mr-auto">
          <div className="flex flex-row w-full">
            {step?.isCompleted && (
              <span className="h-9 flex items-center mr-6">
                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-purple-600 rounded-full group-hover:bg-purple-800">
                  <CheckIcon className="h-5 w-5 text-white" />
                </span>
              </span>
            )}
            {!step?.isCompleted && isCurrentStep && (
              <span className="h-9 flex items-center mr-6" aria-hidden="true">
                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-purple-600 rounded-full">
                  <span className="h-2.5 w-2.5 bg-purple-600 rounded-full"></span>
                </span>
              </span>
            )}
            {!step?.isCompleted && !isCurrentStep && (
              <span className="h-9 flex items-center mr-6" aria-hidden="true">
                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                  <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
                </span>
              </span>
            )}
            <div className="w-full">
              <div className={textClassName}>{stepDefinition?.displayName}</div>
              {(step?.isCompleted || isCurrentStep) && stepDefinition?.instruction && (
                <p className="text-sm text-gray-700">{stepDefinition?.instruction}</p>
              )}
              {content && <div className="mt-6">{content}</div>}
            </div>
          </div>
        </div>
        {accessory && <div className="mt-6 md:mt-0 md:justify-self-end flex-none">{accessory}</div>}
      </div>
      {footer && <div className="mt-6">{footer}</div>}
    </div>
  );
};

export default DefaultStep;
