import React from 'react';
import { DocumentReportIcon } from '@heroicons/react/outline';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

import classNames from 'classnames';
import { Header, Link, Table, MediaObject, Panel, LearningHubLink, Button } from '@shuffl/tailwind-ui-react';

import {
  GetShufflEventsQueryHookResult,
  ShufflEvent,
  StripePriceLookupKey,
  useUpdateShufflEventGroupReportMutation,
} from '../../../../generated/graphql';

import { ReactComponent as UndrawOnlineResumeImage } from '../../../../images/undraw/online-resume.svg';

export interface ShufflHistoryProps {
  shufflEventsQuery: GetShufflEventsQueryHookResult;
  isPayingOrTrialing?: boolean;
}

export const ShufflHistory = ({ shufflEventsQuery, isPayingOrTrialing }: ShufflHistoryProps) => {
  const shufflEvents = shufflEventsQuery.data?.shufflEventConnection.edges
    ?.map((edge) => edge?.node)
    .filter((shufflEvent) => !!shufflEvent) as ShufflEvent[];
  const numberFormatter = new Intl.NumberFormat('en-US');
  const [getGroupReport] = useUpdateShufflEventGroupReportMutation();

  return (
    <>
      <div className="lg:col-span-12">
        <Panel padding>
          <MediaObject
            header="Channel Shuffl Details"
            media={<UndrawOnlineResumeImage />}
            mediaSize={isPayingOrTrialing ? 'md' : undefined}
          >
            <div className="flex flex-col space-y-8">
              {!isPayingOrTrialing && <div>Available starting at $50/month.</div>}{' '}
              <div className="text-sm text-gray-500 space-y-4">
                <p>Gain deep visibility into metrics for every shuffl.</p>
                <p>From KPI&apos;s to group views and of course, downloadable group reports (CSV)!</p>
              </div>
              <div className="space-y-4">
                <LearningHubLink articleId="5125731-shuffl-details" size="sm">
                  Learn more about Shuffl Details in our Learning Hub
                </LearningHubLink>
                {!isPayingOrTrialing && (
                  <div>
                    <Button to={`/account/billing/change/payment-details?price=${StripePriceLookupKey.ProMonthly}`}>
                      Upgrade team plan starting at $50/month
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </MediaObject>
        </Panel>
      </div>
      <div className="lg:col-span-12">
        <Header type="h2" id="history" className="mb-2">
          Shuffl History
        </Header>
        <Table
          isEmpty={(shufflEvents?.length ?? 0) === 0}
          emptyText="No Shuffls Yet"
          stickyHeaders
          height="fixed"
          onAtBottom={() => {
            if (shufflEventsQuery.data?.shufflEventConnection.pageInfo.hasNextPage && !shufflEventsQuery.loading) {
              shufflEventsQuery.fetchMore({
                variables: {
                  after: shufflEventsQuery.data?.shufflEventConnection.pageInfo.endCursor,
                },
              });
            }
          }}
        >
          <Table.Header>
            <Table.Header.Cell>Date</Table.Header.Cell>
            <Table.Header.Cell>Shuffl&apos;d Members</Table.Header.Cell>
            <Table.Header.Cell>Opted Out</Table.Header.Cell>
            <Table.Header.Cell>Groups</Table.Header.Cell>
            <Table.Header.Cell>Smart Shuffl</Table.Header.Cell>
            <Table.Header.Cell padding={false}></Table.Header.Cell>
          </Table.Header>
          <Table.Body>
            {shufflEvents?.map((shufflEvent, index) => {
              return (
                <Table.Row key={shufflEvent.id ?? index}>
                  <Table.Cell>
                    <Link to={`${shufflEvent?.id}`} disabled={!isPayingOrTrialing}>
                      {moment(shufflEvent?.scheduledTime)
                        .tz(shufflEvent?.timezone ?? 'UTC')
                        .format('ll')}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {numberFormatter.format(shufflEvent.eventSnapshot?.users.shuffled.total ?? 0)}
                  </Table.Cell>
                  <Table.Cell>
                    {numberFormatter.format(shufflEvent.eventSnapshot?.users.optedOut.total ?? 0)}
                  </Table.Cell>
                  <Table.Cell>
                    {numberFormatter.format(shufflEvent.eventSnapshot?.groups.created.total ?? 0)}
                  </Table.Cell>
                  <Table.Cell>{shufflEvent.eventSnapshot?.shufflStrategyToString}</Table.Cell>
                  <Table.Cell>
                    <div
                      className={classNames('flex justify-end items-center text-sm font-medium', {
                        'cursor-not-allowed text-gray-600': !isPayingOrTrialing,
                        'text-purple-600 hover:text-purple-500 cursor-pointer': isPayingOrTrialing,
                      })}
                      onClick={async () => {
                        if (!shufflEvent || !isPayingOrTrialing) {
                          return;
                        }

                        const groupReport = await getGroupReport({
                          variables: {
                            shufflEventId: shufflEvent.id,
                          },
                        });

                        if (!groupReport.data) {
                          return;
                        }

                        const blob = new Blob([groupReport.data.groupReport], { type: 'text/plain;charset=utf-8' });

                        saveAs(
                          blob,
                          `${moment(shufflEvent?.scheduledTime)
                            .tz(shufflEvent?.timezone)
                            .format('yyyy-MM-DD')}-group-report.csv`,
                        );
                      }}
                    >
                      <div className="mr-2">Group Report</div>
                      <DocumentReportIcon className="w-5 h-5" />
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};
