import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { OnboardingContainer } from '../../pages/OnboardingV2';

export const OnboardingApp = () => {
  return (
    <Routes>
      <Route element={<OnboardingContainer />} path="onboarding" />
      {/* <Route element={<Page404 />} path="*" /> */}
    </Routes>
  );
};
