import { CalendarIcon, UsersIcon } from '@heroicons/react/outline';
import moment from 'moment-timezone';
import React from 'react';
import { Header, Stats, Panel } from '@shuffl/tailwind-ui-react';

import {
  GetEventDefinitionQueryHookResult,
  GetShufflEventsQueryHookResult,
  GetUserEventDefinitionsQueryHookResult,
  ShufflEvent,
} from '../../../../generated/graphql';
import Chart from '../../../../components/Chart';

export interface ReportingProps {
  userEventDefinitionsQuery: GetUserEventDefinitionsQueryHookResult;
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;
  shufflEventsQuery: GetShufflEventsQueryHookResult;
}

function enumerateDaysBetweenDates(startDate: Date, endDate: Date) {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
}

export const Reporting = ({ eventDefinitionQuery, userEventDefinitionsQuery, shufflEventsQuery }: ReportingProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const shufflEventsCount = shufflEventsQuery.data?.shufflEventConnection.totalCount;
  const shufflEvents = shufflEventsQuery.data?.shufflEventConnection.edges
    ?.map((edge) => edge?.node)
    .filter((shufflEvent) => !!shufflEvent) as ShufflEvent[];
  const numberFormatter = new Intl.NumberFormat('en-US');

  const threeMonthsAgo = moment().subtract(1, 'month');
  const startDate = moment(eventDefinition?.createdAt).isSameOrAfter(threeMonthsAgo)
    ? threeMonthsAgo
    : moment(eventDefinition?.createdAt);

  const shufflEventDates = enumerateDaysBetweenDates(startDate.toDate(), moment().endOf('month').toDate());

  // const userPeriodSeriesAggregate = successfulShufflEvents?.map(
  // (shufflEvent) => shufflEvent.eventSnapshot?.users.shuffled.total ?? 0,
  // );
  const userPeriodSeriesAggregate = shufflEventDates.map((date, index) => {
    return index;
  });

  const latestShufflEvent = shufflEvents ? shufflEvents[0] : undefined;
  const firstShufflEvent = shufflEvents ? shufflEvents[shufflEvents.length - 1] : undefined;

  return (
    <>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<CalendarIcon className="h-5 w-5" />}
          stat={numberFormatter.format(shufflEventsCount ?? 0)}
          title="Shuffls"
          linkTo={`../history`}
        />
      </div>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<UsersIcon className="h-5 w-5" />}
          stat={numberFormatter.format(eventDefinition?.latestSnapshot?.users.shuffld.total ?? 0)}
          title="Members Shuffl'd"
          linkTo={`../members`}
        />
      </div>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<CalendarIcon className="h-5 w-5" />}
          stat={firstShufflEvent?.createdAt ? moment(firstShufflEvent?.createdAt).format('ll') : 'Never'}
          title="First Shuffl"
          linkTo={`../history/${firstShufflEvent?.id}`}
          linkText="View"
        />
      </div>
      <div className="lg:col-span-12 lg:row-start-2">
        <Header type="h2" id="shuffld-members" className="mb-">
          Shuffl&apos;d Members
        </Header>
        <Panel padding>
          <div className="h-60 pt-3">
            {!eventDefinitionQuery.loading && !userEventDefinitionsQuery.loading && (
              <Chart
                seriesData={userPeriodSeriesAggregate ?? []}
                xValues={shufflEventDates ?? []}
                tooltip="Shuffl'd Members"
              />
            )}
          </div>
        </Panel>
      </div>
      <div className="lg:col-span-12 lg:row-start-3">
        <Header type="h2" id="new-connections" className="mb-2">
          New Connections
        </Header>
        <Panel padding>
          <div className="h-60 pt-3">
            {!eventDefinitionQuery.loading && !userEventDefinitionsQuery.loading && (
              <Chart
                seriesData={userPeriodSeriesAggregate ?? []}
                xValues={shufflEventDates ?? []}
                tooltip="New Connections"
              />
            )}
          </div>
        </Panel>
      </div>
      <div className="lg:col-span-12 lg:row-start-4">
        <Header type="h2" id="avg-new-connections-per-group" className="mb-2">
          Avg. New Connections per Group
        </Header>
        <Panel padding>
          <div className="h-60 pt-3">
            {!eventDefinitionQuery.loading && !userEventDefinitionsQuery.loading && (
              <Chart
                seriesData={userPeriodSeriesAggregate ?? []}
                xValues={shufflEventDates ?? []}
                tooltip="Avg. New Connections per Group"
              />
            )}
          </div>
        </Panel>
      </div>
      <div className="lg:col-span-12 lg:row-start-5">
        <Header type="h2" id="groups" className="mb-2">
          Groups
        </Header>
        <Panel padding>
          <div className="h-60 pt-3">
            {!eventDefinitionQuery.loading && !userEventDefinitionsQuery.loading && (
              <Chart seriesData={userPeriodSeriesAggregate ?? []} xValues={shufflEventDates ?? []} tooltip="Groups" />
            )}
          </div>
        </Panel>
      </div>
      <div className="lg:col-span-12 lg:row-start-6">
        <Header type="h2" id="shuffl-bios" className="mb-2">
          Shuffl Bios
        </Header>
        <Panel padding>
          <div className="h-60 pt-3">
            {!eventDefinitionQuery.loading && !userEventDefinitionsQuery.loading && (
              <Chart
                seriesData={userPeriodSeriesAggregate ?? []}
                xValues={shufflEventDates ?? []}
                tooltip="Shuffl Bios"
              />
            )}
          </div>
        </Panel>
      </div>
    </>
  );
};
