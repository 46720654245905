import React from 'react';
import {
  OnboardingDefinitionStepId,
  useGetAllUsersLazyQuery,
  useGetAllUsersQuery,
  useGetSlackConversationUsersQuery,
  UserRole,
} from '../../../../generated/graphql';
import { useOnboarding } from '../../../OnboardingV3/context';
import { AddChannelSelectMembers } from './SelectMembers';

export const AddChannelSelectMembersContainer = () => {
  const { onboardingInstanceQuery } = useOnboarding();
  const onboardingInstance = onboardingInstanceQuery.data?.onboardingInstance;
  const selectChannelMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectChannel,
  )?.metadata;
  const selectMembersMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectMembers,
  )?.metadata;

  let conversationId;
  let previouslySelectedUsers: { id: string; role: UserRole }[] = [];

  if (selectChannelMetadata) {
    conversationId = JSON.parse(selectChannelMetadata).conversationId;
  }

  if (selectMembersMetadata) {
    previouslySelectedUsers = JSON.parse(selectMembersMetadata).users;
  }

  const usersLazyQuery = useGetAllUsersLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 25,
    },
  });

  const previouslySelectedUsersQuery = useGetAllUsersQuery({
    skip: (previouslySelectedUsers?.length ?? 0) === 0,
    variables: {
      first: previouslySelectedUsers.length,
      ids: previouslySelectedUsers?.map((user) => user.id),
    },
  });

  const slackConversationUsersQuery = useGetSlackConversationUsersQuery({
    notifyOnNetworkStatusChange: true,
    skip: !conversationId || conversationId === 'shuffl-meetups',
    variables: {
      conversationId,
    },
  });

  return (
    <AddChannelSelectMembers
      usersLazyQuery={usersLazyQuery}
      slackConversationUsersQuery={slackConversationUsersQuery}
      previouslySelectedUsersQuery={previouslySelectedUsersQuery}
    ></AddChannelSelectMembers>
  );
};
