import React from 'react';
import { Outlet, useParams } from 'react-router';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useUser } from '../../../context/user-context';
import { EventDefinitionConsumer, EventDefinitionProvider } from '../context/event-definition-context';
import { Channel } from './Channel';

export const ChannelContainer = () => {
  const user = useUser();
  const { id } = useParams();

  return (
    <EventDefinitionProvider id={id}>
      <EventDefinitionConsumer>
        {(context) => {
          return (
            <IsLoadingProvider isLoading={context.eventDefinitionQuery.loading}>
              <Channel eventDefinitionQuery={context.eventDefinitionQuery} tenantQuery={user.tenantQuery}>
                <Outlet />
              </Channel>
            </IsLoadingProvider>
          );
        }}
      </EventDefinitionConsumer>
    </EventDefinitionProvider>
  );
};
