import React from 'react';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classnames from 'classnames';
import './index.css';
import { max } from 'lodash';

export interface ChartProps {
  title?: string;
  xTitle?: string;
  tooltip: string;
  yTitle?: string;
  seriesData: any[];
  xValues: Date[];
  className?: string;
}

const Chart = (props: ChartProps) => {
  const chartClassName = classnames(props.className);
  const numberFormatter = new Intl.NumberFormat('en-US');

  const options: Highcharts.Options = {
    chart: {
      animation: false,
      className: chartClassName,
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        borderColor: 'transparent',
        borderRadius: 2,
        borderWidth: 0,
        data: props.seriesData.map((data) => ({
          className: 'text-purple-500 hover:text-purple-400 fill-current rounded-md font-sans subpixel-antialiased',
          y: data,
        })),
        point: {},
        showInLegend: false,
        tooltip: {},
        type: 'column',
      },
    ],
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      formatter() {
        return `
        <div class="flex flex-col bg-black rounded-md p-4 text-sm font-sans subpixel-antialiased font-medium">
          <div class="pb-2 text-gray-400">${moment(this.x).format('ll')}</div>
          <div class="text-white">
            <span class="mr-6">${props.tooltip}</span> <span>${numberFormatter.format(this.y)}</span>
          </div>
        </div>`;
      },
      shadow: false,
      useHTML: true,
    },
    xAxis: {
      categories: props.xValues.map((date) => date.toString()),
      labels: {
        align: 'center',
        formatter() {
          if (this.pos % 10 !== 0) {
            return '';
          }

          return `
          <div class="text-sm font-sans subpixel-antialiased font-medium whitespace-nowrap mt-2">
            ${moment(this.value).format('MMM D')}
          </div>
          `;
        },
        useHTML: true,
      },
      type: 'datetime',
    },
    yAxis: {
      className: 'font-sans subpixel-antialiased font-medium',
      gridLineColor: 'transparent',
      labels: {
        formatter() {
          return `
          <div class="text-sm font-sans subpixel-antialiased font-medium">
            ${this.value}
          </div>
          `;
        },
        useHTML: true,
      },
      max: max(props.seriesData) ?? 100,
      plotLines: new Array(5).fill(0).map((_, index) => ({
        className: 'text-gray-50 stroke-current stroke-2',
        value: index * 25,
      })),
      title: {
        text: props.yTitle,
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: '100%' } }} />;
};

export default Chart;
