import { DocumentDownloadIcon, DocumentReportIcon } from '@heroicons/react/outline';
import { startCase } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Table, IsLoadingProvider, useIsLoading } from '@shuffl/tailwind-ui-react';

import { UserContextInterface } from '../../../../context/user-context';
import { GetStripeInvoicesQueryHookResult } from '../../../../generated/graphql';

export interface InvoicesProps {
  invoicesQuery: GetStripeInvoicesQueryHookResult;
  userContext: UserContextInterface;
}

export const PaidInvoices = ({ invoicesQuery, userContext }: InvoicesProps) => {
  const invoices = invoicesQuery.data?.stripeInvoices;
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');
  const shouldShowUsageReport = userContext.isSuperAdmin && userContext.isPayingOrTrialing;
  const paidInvoices = invoices?.filter(
    (invoice) =>
      invoice.paid &&
      !moment(invoice.periodStart).isSameOrBefore(moment(userContext.tenantQuery.data?.tenant.createdAt), 'day'),
  );
  const { isLoading } = useIsLoading();

  return (
    <IsLoadingProvider isLoading={isLoading || invoicesQuery.loading || userContext.loading}>
      <Table isEmpty={paidInvoices?.length === 0} emptyText={'No Invoices'} hideBorder>
        <Table.Header hidden />
        <Table.Body>
          {paidInvoices?.map((invoice) => {
            const memberQuantity = invoice.usageRecord?.memberQuantity ?? invoice.usageAmount ?? 0;

            // NOTE: This handles Zumpers case where they paid us but through external ACH
            const amountPaid =
              invoice.amountPaid === 0 && invoice.amountRemaining > 0 ? invoice.amountRemaining : invoice.amountPaid;

            return (
              <Table.Row key={invoice.id}>
                <Table.Cell padding={false}>
                  <span className="">{moment(invoice.created).format('lll')}</span>
                </Table.Cell>
                <Table.Cell padding={false}>
                  {amountPaid !== 0 && (
                    <span className="font-medium">
                      {currencyFormatter.format(amountPaid / 100)} charged
                      {invoice.brand ? ` to ${startCase(invoice.brand?.toLowerCase())}` : ''}
                    </span>
                  )}
                  {amountPaid === 0 && <span className="font-medium">{currencyFormatter.format(0)} charged</span>}
                </Table.Cell>

                <Table.Cell padding={false}>
                  {invoice.usageRecord && (
                    <>
                      {shouldShowUsageReport && (
                        <a
                          href={invoice.usageRecord.reportUrl ?? ''}
                          className="text-sm font-medium text-purple-600 hover:text-purple-500"
                        >
                          <div className="flex items-center">
                            {invoice.usageRecord.excessMemberQuantity === 0 && (
                              <div className="mr-2">{numberFormatter.format(memberQuantity)} members</div>
                            )}
                            {invoice.usageRecord.excessMemberQuantity > 0 && (
                              <div className="mr-2">
                                {numberFormatter.format(invoice.usageRecord.excessMemberQuantity ?? 0)} excess members
                              </div>
                            )}
                            <DocumentReportIcon className="w-5 h-5" />
                          </div>
                        </a>
                      )}
                      {!shouldShowUsageReport && (
                        <div className="flex items-center text-sm text-gray-500 font-medium">
                          {invoice.usageRecord.excessMemberQuantity === 0 && (
                            <div className="mr-2">{numberFormatter.format(memberQuantity)} members</div>
                          )}
                          {invoice.usageRecord.excessMemberQuantity > 0 && (
                            <div className="mr-2">
                              {numberFormatter.format(invoice.usageRecord.excessMemberQuantity ?? 0)} excess members
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {!invoice.usageRecord && (
                    <div className="text-sm text-gray-500 font-medium">
                      {numberFormatter.format(invoice.usageAmount ?? 0)} members
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell padding={false}>
                  <a
                    href={invoice.invoicePdf ?? ''}
                    className="text-sm font-medium text-purple-600 hover:text-purple-500"
                  >
                    <div className="flex items-center">
                      <div className="mr-2">Invoice</div>
                      <DocumentDownloadIcon className="w-5 h-5" />
                    </div>
                  </a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
