import React from 'react';
import moment from 'moment-timezone';
import { startCase } from 'lodash';
import { ArrowRightIcon } from '@heroicons/react/outline';
import {
  Panel,
  VerticalNavigation,
  Badge,
  LearningHubLink,
  Header,
  DescriptionList,
  Link,
  IsLoadingProvider,
  Button,
} from '@shuffl/tailwind-ui-react';

import { UserContextInterface } from '../../context/user-context';

import {
  GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult,
  GetStripePaymentMethodsQueryHookResult,
  GetAllEventDefinitionsQueryHookResult,
  StripePriceLookupKey,
  GetStripeSubscriptionQueryHookResult,
  GetStripeInvoicesQueryHookResult,
  GetTenantWithCreatedByQueryHookResult,
} from '../../generated/graphql';
import { Page } from '../../components/Page';
import { PaidInvoices } from './components/Invoices/PaidInvoices';
import { UnpaidInvoices } from './components/Invoices/UnpaidInvoices';
import { ProYearlyUsage } from './components/Usage/ProYearly';
import { ProMonthlyUsage } from './components/Usage/ProMonthly';
import { ProTrialUsage } from './components/Usage/ProTrial';
import { CommunityUsage } from './components/Usage/Community';
import { ProYearlyPrice } from './components/Prices/ProYearly';
import { ProTrialPrice } from './components/Prices/ProTrial';
import { CommunityPrice } from './components/Prices/Community';
import { ProMonthlyPrice } from './components/Prices/ProMonthly';

export interface BillingProps {
  usageRecordQuery?: GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult;
  tenantWithCreatedByQuery?: GetTenantWithCreatedByQueryHookResult;
  stripePaymentMethodsQuery?: GetStripePaymentMethodsQueryHookResult;
  stripeSubscriptionQuery?: GetStripeSubscriptionQueryHookResult;
  eventDefinitionsQuery?: GetAllEventDefinitionsQueryHookResult;
  invoicesQuery: GetStripeInvoicesQueryHookResult;
  userContext: UserContextInterface;
  isPaying?: boolean;
}

const Billing = ({
  usageRecordQuery,
  userContext,
  tenantWithCreatedByQuery,
  stripePaymentMethodsQuery,
  invoicesQuery,
  eventDefinitionsQuery,
  isPaying,
  stripeSubscriptionQuery,
}: BillingProps) => {
  const tenantWithCreatedBy = tenantWithCreatedByQuery?.data?.tenant;
  const tenant = userContext.tenantQuery.data?.tenant;
  const paymentMethods = stripePaymentMethodsQuery?.data?.stripePaymentMethods;
  let paymentMethod;
  if (Array.isArray(paymentMethods)) {
    [paymentMethod] = paymentMethods;
  }

  const priceToString = isPaying
    ? startCase(tenant?.price?.includes('MONTHLY') ? 'Pro Monthly' : 'Pro Yearly')
    : 'Community';

  return (
    <Page>
      <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6">
        <div className="lg:col-span-6">
          <Panel padding>
            <div className="flex">
              <div className="mr-auto">
                <div className="flex">
                  {/* {isLoading && (
                    <div className="ml-0 sm:ml-3 w-full mb-4">
                      <Skeleton />
                    </div>
                  )} */}
                  {/* {!isLoading && <Header>Billing for {tenant?.slackMetadata?.team.name}</Header>} */}
                  <Header>Billing for {tenant?.slackMetadata?.team.name}</Header>
                </div>
                <div className="">
                  {!tenant?.isTrialing && (
                    <DescriptionList>
                      <DescriptionList.Item name="Billing Period:">
                        <span className="text-sm text-gray-500 font-medium">
                          {moment(tenant?.currentBillingPeriod.periodStart).format('ll')} to{' '}
                          {moment(tenant?.currentBillingPeriod.periodEnd).format('ll')}
                        </span>
                      </DescriptionList.Item>
                    </DescriptionList>
                  )}
                  {tenant?.isTrialing && (
                    <DescriptionList>
                      <DescriptionList.Item name="Trial Period:">
                        <span className="text-sm text-gray-500 font-medium">
                          {moment(tenant?.trialStartAt).format('ll')} to {moment(tenant?.trialEndAt).format('ll')}
                        </span>
                      </DescriptionList.Item>
                    </DescriptionList>
                  )}
                </div>
                <LearningHubLink articleId="5049334-what-is-shuffl-s-pricing-model" size="sm">
                  Learn more about pricing in our Learning Hub
                </LearningHubLink>
              </div>

              <div>
                {!isPaying && (
                  <Button to={`/account/billing/change/payment-details?price=${StripePriceLookupKey.ProMonthly}`}>
                    Upgrade Plan
                  </Button>
                )}
                {isPaying && <Badge color="purple">{priceToString}</Badge>}
              </div>
            </div>
          </Panel>
        </div>
        <div className="lg:col-span-3 lg:row-start-2">
          <Panel sticky>
            <VerticalNavigation>
              <VerticalNavigation.Item to={`/account/billing`} title="Overview">
                <VerticalNavigation.SubItem to={`#usage`} title="Usage" />
                <VerticalNavigation.SubItem to={`#plan`} title="Plan" />
                <VerticalNavigation.SubItem to={`#invoices`} title="Invoices" />
                <VerticalNavigation.SubItem to={`#billing-information`} title="Billing Information" />
              </VerticalNavigation.Item>
            </VerticalNavigation>
          </Panel>
        </div>
        <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6 lg:col-span-9 lg:row-start-2">
          <div className="lg:col-span-12 lg:row-start-1">
            <Header id="usage" type="h2">
              Current Period Usage
            </Header>
            <div className="flex items-center text-sm text-gray-500 font-medium mb-4">Usage details</div>
            <Panel padding>
              <div className="pb-5 border-b border-gray-200">
                <Header type="h3">Member Usage</Header>
              </div>
              <div className="pt-4">
                {!tenant?.isTrialing && tenant?.price === StripePriceLookupKey.Community && (
                  <CommunityUsage usageRecordQuery={usageRecordQuery} eventDefinitionsQuery={eventDefinitionsQuery} />
                )}
                {tenant?.isTrialing && (
                  <ProTrialUsage usageRecordQuery={usageRecordQuery} eventDefinitionsQuery={eventDefinitionsQuery} />
                )}
                {!tenant?.isTrialing && tenant?.price === StripePriceLookupKey.ProMonthly && (
                  <ProMonthlyUsage usageRecordQuery={usageRecordQuery} eventDefinitionsQuery={eventDefinitionsQuery} />
                )}
                {!tenant?.isTrialing && tenant?.price === StripePriceLookupKey.ProYearly && (
                  <ProYearlyUsage
                    usageRecordQuery={usageRecordQuery}
                    eventDefinitionsQuery={eventDefinitionsQuery}
                    stripeSubscriptionQuery={stripeSubscriptionQuery}
                    tenantQuery={userContext.tenantQuery}
                  />
                )}
              </div>
            </Panel>
          </div>
          <div className="lg:col-span-12 lg:row-start-2">
            <Header id="plan" type="h2">
              Current Plan
            </Header>
            <div className="flex items-center text-sm text-gray-500 font-medium mb-4">Subscription details</div>
            <Panel padding>
              <div className="pb-5 border-b border-gray-200">
                <Header type="h3">Team Plan</Header>
              </div>
              <div className="py-4">
                {!tenant?.isTrialing && tenant?.price === StripePriceLookupKey.Community && (
                  <CommunityPrice tenantQuery={tenantWithCreatedByQuery} />
                )}
                {tenant?.isTrialing && <ProTrialPrice tenantQuery={tenantWithCreatedByQuery} />}
                {!tenant?.isTrialing && tenant?.price === StripePriceLookupKey.ProMonthly && (
                  <ProMonthlyPrice
                    stripeSubscriptionQuery={stripeSubscriptionQuery}
                    tenantQuery={tenantWithCreatedByQuery}
                  />
                )}
                {!tenant?.isTrialing && tenant?.price === StripePriceLookupKey.ProYearly && (
                  <ProYearlyPrice
                    stripeSubscriptionQuery={stripeSubscriptionQuery}
                    tenantQuery={tenantWithCreatedByQuery}
                  />
                )}
              </div>
            </Panel>
          </div>
          <div className="lg:col-span-12 lg:row-start-3">
            <Header id="invoices" type="h2">
              Invoices
            </Header>
            <div className="flex items-center text-sm text-gray-500 font-medium mb-4">Invoice history</div>
            <div className="mb-6">
              <Panel padding>
                <div className="pb-5 border-b border-gray-200">
                  <Header type="h3">Unpaid invoices</Header>
                </div>
                <div className="pt-4">
                  <UnpaidInvoices invoicesQuery={invoicesQuery} userContext={userContext} />
                </div>
              </Panel>
            </div>
            <div>
              <Panel padding>
                <div className="pb-5 border-b border-gray-200">
                  <Header type="h3">Paid invoices</Header>
                </div>
                <div className="pt-4">
                  <PaidInvoices invoicesQuery={invoicesQuery} userContext={userContext} />
                </div>
              </Panel>
            </div>
          </div>
          <div className="lg:col-span-12 lg:row-start-4">
            <Header id="billing-information" type="h2">
              Billing Information
            </Header>
            <div className="flex items-center text-sm text-gray-500 font-medium mb-4">
              Payment and billing information for this team account
            </div>
            <div className="mb-6">
              <Panel padding>
                <div className="pb-5 border-b border-gray-200">
                  <Header type="h3">Payment Information</Header>
                </div>
                <div className="py-4">
                  <IsLoadingProvider isLoading={stripePaymentMethodsQuery?.loading}>
                    <DescriptionList>
                      <DescriptionList.Item name="Payment method">
                        {paymentMethod && (
                          <div className="flex items-center">
                            <div className="flex items-center mr-6">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-5 w-5 mr-2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                />
                              </svg>
                              <div>
                                {startCase(paymentMethod?.brand ?? '')} ending in {paymentMethod?.last4}
                              </div>
                            </div>
                            <div className="text-sm text-gray-500 font-medium">
                              {moment()
                                .set('month', paymentMethod?.expMonth ?? 1)
                                .set('year', paymentMethod?.expYear ?? 1)
                                .format('MM / YY')}
                            </div>
                          </div>
                        )}
                        {!paymentMethod && <div>No payment on file</div>}
                      </DescriptionList.Item>
                    </DescriptionList>
                  </IsLoadingProvider>
                </div>
                <div className="flex">
                  <span className="text-sm font-medium text-gray-500 mr-1">To change your payment method</span>
                  <Link
                    onClick={() => {
                      window.Intercom('showNewMessage');
                    }}
                  >
                    <span className="flex items-center space-x-2 text-sm">
                      <span> contact billing support</span>
                      <ArrowRightIcon className="w-4 h-4" />
                    </span>
                  </Link>
                </div>
                {/* <div className="mt-6">
                  <Button buttonStyle="secondary">Change Payment Method</Button>
                </div> */}
              </Panel>
            </div>
            <div>
              <Panel padding>
                <div className="pb-5 border-b border-gray-200">
                  <Header type="h3">Primary Billing Contact</Header>
                </div>
                <div className="py-4">
                  <IsLoadingProvider
                    isLoading={stripePaymentMethodsQuery?.loading || tenantWithCreatedByQuery?.loading}
                  >
                    <DescriptionList>
                      <DescriptionList.Item name="Name">
                        {paymentMethod?.billingName ?? tenantWithCreatedBy?.createdBy?.name}
                      </DescriptionList.Item>
                      <DescriptionList.Item name="Email">
                        {paymentMethod?.billingEmail ?? tenantWithCreatedBy?.createdBy?.email}
                      </DescriptionList.Item>
                      {/* <DescriptionList.Item name="Company Name">Shuffl LLC</DescriptionList.Item> */}
                      <DescriptionList.Item name="Company Address">
                        <div>{paymentMethod?.billingAddressLine1}</div>
                        <div>{paymentMethod?.billingAddressLine2}</div>
                        <div>{paymentMethod?.billingAddressCity}</div>
                        <div>{paymentMethod?.billingAddressState}</div>
                        <div>{paymentMethod?.billingAddressPostalCode}</div>
                        <div>{paymentMethod?.billingAddressCountry}</div>
                      </DescriptionList.Item>
                    </DescriptionList>
                  </IsLoadingProvider>
                </div>
                {/* <div className="">
                  <Button buttonStyle="secondary">Edit Billing Contact</Button>
                </div> */}
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Billing;
