import moment from 'moment-timezone';

export function addWeekdays(date: Date, days: number, timezone?: string) {
  let resultDate = moment(date);

  if (timezone) {
    resultDate = resultDate.tz(timezone);
  }

  let daysCopy = days;

  while (daysCopy > 0) {
    resultDate = resultDate.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (resultDate.isoWeekday() !== 6 && resultDate.isoWeekday() !== 7) {
      daysCopy -= 1;
    }
  }

  return resultDate;
}
