import React, { PropsWithChildren } from 'react';
import { useGetEventDefinitionQuery, GetEventDefinitionQueryHookResult } from '../../../generated/graphql';

export interface EventDefinitionProviderProps {
  id: string;
}

export interface EventDefinitionContextInterface {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;
}

const EventDefinitionContext = React.createContext<EventDefinitionContextInterface>({} as any);

const EventDefinitionProvider = (props: PropsWithChildren<EventDefinitionProviderProps>) => {
  if (!props.id) {
    throw new Error(`id must be defined when calling EventDefinitionProvider`);
  }

  const eventDefinitionQuery = useGetEventDefinitionQuery({
    variables: {
      eventDefinitionId: props.id,
    },
  });

  return <EventDefinitionContext.Provider value={{ eventDefinitionQuery }} {...props} />;
};

function useEventDefinition() {
  const context = React.useContext(EventDefinitionContext);

  if (context === undefined) {
    throw new Error(`useEventDefinition must be used within a EventDefinitionProvider`);
  }

  return context;
}

const EventDefinitionConsumer = EventDefinitionContext.Consumer;

export { EventDefinitionProvider, useEventDefinition, EventDefinitionConsumer };
