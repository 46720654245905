import React from 'react';
import moment from 'moment-timezone';
import { IsLoadingProvider, Table } from '@shuffl/tailwind-ui-react';
import { GetTenantQueryHookResult } from '../../../../../generated/graphql';

export interface ProTrialPriceProps {
  tenantQuery?: GetTenantQueryHookResult;
}

export const ProTrialPrice = ({ tenantQuery }: ProTrialPriceProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  const tenant = tenantQuery?.data?.tenant;

  const formattedPrice = formatter.format(0);

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="font-medium">Shuffl Pro Trial</span>
                <span className="text-sm text-gray-500 font-medium">
                  Trial ends on {moment(tenant?.trialEndAt).format('ll')}
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="text-sm text-gray-500 font-medium">
                {numberFormatter.format(1)} - {numberFormatter.format(1000)} members
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">Unlimited Channels</span>
                <span className="text-sm text-gray-500 font-medium">Unlimited Shuffl Events</span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{formattedPrice} / month</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
