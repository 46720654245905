import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { LearningHubLink } from '@shuffl/tailwind-ui-react';

export const ProDetails = () => {
  return (
    <div className="flex flex-col">
      <div className="mb-4">Create meaningful connections with fully featured smart shuffls!</div>
      <div className="flex flex-col w-full">
        <div className="text-gray-900 font-medium mb-2">Includes:</div>
        <div className="flex mb-4 w-full">
          <ul className="lg:grid lg:grid-cols-2 w-full">
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Up to 1,000 team members</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Random shuffl algorithm</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Unlimited Shuffl Channels</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">New Match Shuffl</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Unlimited Shuffls per month</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Leader Lottery Shuffl</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Custom group intro messages</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Leadership Shuffl</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Up to 8 person group shuffls</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Targeted Multi-group Shuffl</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Configurable shuffl schedules</p>
            </li>

            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Live & Email Support</p>
            </li>

            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Usage CSV Reports</p>
            </li>
          </ul>
        </div>
        <div>
          <LearningHubLink articleId="5049334-what-is-shuffl-s-pricing-model" size="sm">
            Learn more about Shuffl Pro
          </LearningHubLink>
        </div>
      </div>
    </div>
  );
};
