import React, { useEffect } from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import { OnboardingContainer } from '../../pages/OnboardingV3';
import { OnboardingConfirmSettingsContainer } from '../../pages/OnboardingV3/ConfirmSettings';
import { OnboardingWelcomeContainer } from '../../pages/OnboardingV3/Welcome';
import { OnboardingSelectChannelContainer } from '../../pages/OnboardingV3/SelectChannel';
import { OnboardingSelectMembersContainer } from '../../pages/OnboardingV3/SelectMembers';
import { OnboardingSelectScheduleContainer } from '../../pages/OnboardingV3/SelectSchedule';

export const OnboardingApp = () => {
  const matchOnboarding = useMatch({ end: false, path: '/onboarding' });
  const navigate = useNavigate();

  useEffect(() => {
    if (!matchOnboarding) {
      navigate('/onboarding', { replace: true });
    }
  });

  return (
    <Routes>
      <Route element={<OnboardingContainer />} path="onboarding">
        <Route element={<OnboardingWelcomeContainer />} path="welcome" />
        <Route element={<OnboardingSelectChannelContainer />} path="channel" />
        <Route element={<OnboardingSelectMembersContainer />} path="members" />
        <Route element={<OnboardingSelectScheduleContainer />} path="schedule" />
        <Route element={<OnboardingConfirmSettingsContainer />} path="confirm-settings" />
      </Route>
      {/* <Route element={<Page404 />} path="*" /> */}
    </Routes>
  );
};
