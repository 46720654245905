import React from 'react';
import { useGetRefreshTokenQuery } from '../generated/graphql';

interface AuthContextInterface {
  isAuthenticated: boolean;
  loading: boolean;
  updateIdToken: () => Promise<any>;
}

const AuthContext = React.createContext<AuthContextInterface>({
  isAuthenticated: false,
  loading: false,
  updateIdToken: () => Promise.resolve(),
});

const AuthProvider: React.FC = (props) => {
  const { error, loading, data, refetch } = useGetRefreshTokenQuery({
    fetchPolicy: 'network-only',
  });

  let isAuthenticated = false;

  if (Array.isArray(error?.graphQLErrors) && error?.graphQLErrors[0]?.extensions?.code !== 'Unauthorized') {
    console.error('Error getting refresh token', error);
  }

  if (data && data.refreshToken) {
    isAuthenticated = data.refreshToken.isAuthenticated;
  }

  return <AuthContext.Provider value={{ isAuthenticated, loading, updateIdToken: refetch }} {...props} />;
};

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
