import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { usePrompt } from 'react-router-dom';
import { Header, Panel, Toggle, MediaObject, LearningHubLink, Button } from '@shuffl/tailwind-ui-react';

import {
  FeatureFlagKey,
  GetEventDefinitionQueryHookResult,
  StripePriceLookupKey,
  useUpdateEventDefinitionMutation,
  useUpdateEventDefinitionAddonsMutation,
} from '../../../../generated/graphql';

import { ReactComponent as UndrawSettingsTabImage } from '../../../../images/undraw/settings-tab.svg';

import { SubmitButton, Radio, TextArea } from '../../../../components/Form';
import { useFeatureFlag } from '../../../../hooks/use-feature-flag';
import { GroupSettingsAddonsSchema, GroupSettingsFormData, GroupSettingsSchema } from './form-schema';

export interface GroupSettingsProps {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;

  isPayingOrTrialing?: boolean;
}

export const GroupSettings = ({ eventDefinitionQuery, isPayingOrTrialing }: GroupSettingsProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const [updateEventDefinition] = useUpdateEventDefinitionMutation();
  const [updateEventDefinitionAddons] = useUpdateEventDefinitionAddonsMutation();
  const [form1Dirty, setForm1Dirty] = useState(false);
  const [form2Dirty, setForm2Dirty] = useState(false);
  const biosFeatureFlag = useFeatureFlag({
    eventDefinitionId: eventDefinition?.id,
    key: FeatureFlagKey.SlackSendBioMessages,
  });

  usePrompt('You have unsaved changes!\nAre you sure you want to leave?', form1Dirty || form2Dirty);

  const groupSizes = [
    { value: 2 },
    { value: 3 },
    { disabled: !isPayingOrTrialing, value: 4 },
    { disabled: !isPayingOrTrialing, value: 5 },
    { disabled: !isPayingOrTrialing, value: 6 },
    { disabled: !isPayingOrTrialing, value: 7 },
    { disabled: !isPayingOrTrialing, value: 8 },
  ];

  return (
    <>
      <div className="lg:col-span-12">
        <Panel padding>
          <MediaObject header="Group Settings" media={<UndrawSettingsTabImage />}>
            <div className="flex flex-col space-y-8">
              {!isPayingOrTrialing && <div>Available starting at $50/month.</div>}
              <div className="text-sm text-gray-500 space-y-4">
                <p>Grant more control of your shuffl group, from group size to custom intro messages!</p>
                <p>These intro messages will accompany every shuffl, after everyone&apos;s Shuffl Bios.</p>
              </div>
              <div className="space-y-4">
                <LearningHubLink articleId="5125744-group-settings" size="sm">
                  Learn more about Group Settings in our Learning Hub
                </LearningHubLink>
                {!isPayingOrTrialing && (
                  <div>
                    <Button to={`/account/billing/change/payment-details?price=${StripePriceLookupKey.ProMonthly}`}>
                      Upgrade team plan starting at $50/month
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </MediaObject>
        </Panel>
      </div>
      <div className="lg:col-span-12 ">
        <Header type="h2" id="settings">
          Settings
        </Header>
        <Panel padding>
          <Formik
            validationSchema={GroupSettingsSchema}
            initialValues={{
              groupSize: eventDefinition?.groupSize.toString(),
              template: eventDefinition?.tenantTemplate?.template,
            }}
            enableReinitialize
            onSubmit={async (submitData: GroupSettingsFormData) => {
              if (!eventDefinition) {
                return;
              }

              await updateEventDefinition({
                variables: {
                  eventDefinitionId: eventDefinition.id,
                  groupSize: Number(submitData.groupSize),
                  template: submitData.template,
                },
              });

              await eventDefinitionQuery.refetch();
            }}
          >
            {(formik) => {
              setForm1Dirty(formik.dirty);

              return (
                <Form>
                  <div className="mb-8 flex justify-between">
                    <p className="max-w-4xl text-sm text-gray-500">Here you can update the settings for groups</p>
                  </div>
                  <div className="mb-8">
                    <div className="sm:pb-6 pb-5">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <Radio name="groupSize" label="Members per Shuffl Group">
                            {groupSizes.map((groupSize) => {
                              return (
                                <Radio.Item
                                  key={groupSize.value}
                                  value={groupSize.value.toString()}
                                  disabled={groupSize.disabled}
                                  label={groupSize.value.toString()}
                                />
                              );
                            })}
                          </Radio>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-6">
                        <TextArea
                          rows={8}
                          label="Custom Intro"
                          disabled={!isPayingOrTrialing}
                          name="template"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>

                  <SubmitButton>Update</SubmitButton>
                </Form>
              );
            }}
          </Formik>
        </Panel>
      </div>
      {biosFeatureFlag.featureFlag?.isEnabled && (
        <div className="lg:col-span-12">
          <Header type="h2" id="add-ons">
            Add Ons
          </Header>
          <Panel padding>
            <Formik
              validationSchema={GroupSettingsAddonsSchema}
              initialValues={{
                sendBiosToGroups: eventDefinition?.notifications.sendBiosToGroups ?? false,
              }}
              enableReinitialize
              onSubmit={async (submitData: GroupSettingsFormData) => {
                if (!eventDefinition) {
                  return;
                }

                await updateEventDefinitionAddons({
                  variables: {
                    eventDefinitionId: eventDefinition.id,
                    sendBiosToGroups: submitData.sendBiosToGroups,
                  },
                });

                await eventDefinitionQuery.refetch();
              }}
            >
              {(formik) => {
                setForm2Dirty(formik.dirty);

                return (
                  <Form>
                    <div className="mb-8 flex justify-between">
                      <div className="max-w-4xl text-sm text-gray-500 space-y-2">
                        <div>Toggle Add Ons for Groups</div>
                        <LearningHubLink articleId="5049289-shuffl-bios-and-intros" size="sm">
                          Learn more about Bios and Intros in our Learning Hub
                        </LearningHubLink>
                      </div>
                    </div>
                    <div className="mb-8">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 sm:col-span-6">
                          <Toggle name="sendBiosToGroups">Bios and Intros</Toggle>
                        </div>
                      </div>
                    </div>

                    <SubmitButton>Update</SubmitButton>
                  </Form>
                );
              }}
            </Formik>
          </Panel>
        </div>
      )}
    </>
  );
};
