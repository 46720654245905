import React, { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { RadioItem as TailwindRadioItem, RadioItemProps, Radio as TailwindRadio } from '@shuffl/tailwind-ui-react';

export interface RadioProps {
  name: string;
  label: ReactNode;
  className?: string;
  children: React.ReactElement<RadioItemProps>[];
}

export const Radio = ({ label, name, children }: RadioProps) => {
  const { values } = useFormikContext<any>();
  const currentValue = values[name];
  const clonedChildren = React.Children.map(children, (child) => {
    const { props } = child;
    const { value } = props;
    const checked = currentValue === value;

    return React.cloneElement(child, { checked });
  });

  return (
    <TailwindRadio label={label} name={name}>
      {clonedChildren}
    </TailwindRadio>
  );
};

Radio.Item = TailwindRadioItem;
