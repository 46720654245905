import React, { useEffect } from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import { useIsLoading } from '@shuffl/tailwind-ui-react';
import { BillingContainer } from '../../pages/Billing';
import { ChangePlanContainer } from '../../pages/Billing/ChangePlan';
import { PaymentDetailsContainer } from '../../pages/Billing/ChangePlan/PaymentDetails';
import { SelectPlanContainer } from '../../pages/Billing/ChangePlan/SelectPlan';
import { ChannelsContainer } from '../../pages/Channels';
import { MembersContainer } from '../../pages/Members';
import { MemberContainer } from '../../pages/Members/Member';
import { AddMemberContainer } from '../../pages/Members/Add';
import { RoleContainer } from '../../pages/Members/Add/Role';
import { EmailContainer } from '../../pages/Members/Add/Email';
import { ChannelContainer } from '../../pages/Channels/Channel';
import { SmartShufflContainer } from '../../pages/Channels/Channel/SmartShuffl';
import { ReportingContainer } from '../../pages/Channels/Channel/Reporting';
import { ShufflContainer } from '../../pages/Channels/Channel/History/Shuffl';
import { ShufflHistoryContainer } from '../../pages/Channels/Channel/History';
import { ChannelMembersContainer } from '../../pages/Channels/Channel/Members';
import { GroupSettingsContainer } from '../../pages/Channels/Channel/GroupSettings';
import { NotificationsContainer } from '../../pages/Channels/Channel/Notifications';
import { ScheduleContainer } from '../../pages/Channels/Channel/Schedule';
import { ChannelOverviewContainer } from '../../pages/Channels/Channel/Overview';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { FeatureFlagKey, FeatureFlagVariableKey } from '../../generated/graphql';
import { AddChannelContainer } from '../../pages/Channels/Add';
import { AddChannelSelectChannelContainer } from '../../pages/Channels/Add/SelectChannel';
import { AddChannelSelectMembersContainer } from '../../pages/Channels/Add/SelectMembers';
import { AddChannelSelectScheduleContainer } from '../../pages/Channels/Add/SelectSchedule';
import { AddChannelFinishedContainer } from '../../pages/Channels/Add/Finished';

export const AdminApp = () => {
  const matchRoot = useMatch('/');
  const matchOnboarding = useMatch({ end: false, path: '/onboarding' });
  const navigate = useNavigate();
  const { isLoading } = useIsLoading();
  const webAppNewChannelDetailsFeatureFlag = useFeatureFlag({ key: FeatureFlagKey.WebAppNewChannelDetails });

  useEffect(() => {
    if (matchRoot || matchOnboarding) {
      navigate('/channels', { replace: true });
    }
  });

  return (
    <Routes>
      <Route element={<MembersContainer />} path="members" />
      <Route element={<MemberContainer />} path="members/:id" />
      <Route element={<AddMemberContainer />} path="members/add">
        <Route element={<EmailContainer />} path="email" />
        <Route element={<RoleContainer />} path="role" />
      </Route>
      <Route element={<ChannelsContainer />} path="channels" />
      <Route element={<AddChannelContainer />} path="channels/add">
        <Route element={<AddChannelSelectChannelContainer />} path="channel" />
        <Route element={<AddChannelSelectMembersContainer />} path="members" />
        <Route element={<AddChannelSelectScheduleContainer />} path="schedule" />
        <Route element={<AddChannelFinishedContainer />} path="finish" />
      </Route>
      <Route element={<ChannelContainer />} path="channels/:id">
        <Route element={<ChannelOverviewContainer />} path="overview" />
        <Route element={<SmartShufflContainer />} path="smart-shuffl" />
        <Route element={<ShufflHistoryContainer />} path="history" />
        <Route element={<ShufflContainer />} path="history/:id" />
        <Route element={<ChannelMembersContainer />} path="members" />
        <Route element={<ScheduleContainer />} path="schedule" />
        <Route element={<GroupSettingsContainer />} path="group-settings" />
        {webAppNewChannelDetailsFeatureFlag.getVariable(FeatureFlagVariableKey.WebAppChannelDetailsReporting)
          ?.defaultValue === 'true' && <Route element={<ReportingContainer />} path="reporting" />}
        {webAppNewChannelDetailsFeatureFlag.getVariable(FeatureFlagVariableKey.WebAppChannelDetailsNotifications)
          ?.defaultValue === 'true' && <Route element={<NotificationsContainer />} path="notifications" />}
      </Route>

      <Route element={<BillingContainer />} path="account/billing" />
      <Route element={<ChangePlanContainer />} path="account/billing/change">
        <Route element={<SelectPlanContainer />} path="select-plan" />
        <Route element={<PaymentDetailsContainer />} path="payment-details" />
      </Route>
      {/* <Route element={<Page404 />} path="*" /> */}
    </Routes>
  );
};
