import React from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Badge, Link, RadioGroup } from '@shuffl/tailwind-ui-react';
import { ProDetails } from '../components/ProDetails';
import { CommunityDetails } from '../components/CommunityDetails';
import { useChangePrice } from '../context';
import { StripePriceLookupKey } from '../../../../generated/graphql';
import { SubmitButton } from '../../../../components/Form';
import { useUser } from '../../../../context/user-context';

import { FormData, PriceSchema } from './form-schema';

export const SelectPlan = () => {
  const { setPrice, price } = useChangePrice();
  const { tenantQuery } = useUser();
  const currentPrice = tenantQuery.data?.tenant.price;
  const navigate = useNavigate();

  return (
    <Formik
      validationSchema={PriceSchema}
      enableReinitialize
      initialValues={{ price: currentPrice }}
      onSubmit={async (submitData: FormData) => {
        setPrice(submitData.price);
        navigate('../payment-details');
      }}
    >
      <Form>
        <div className="mb-8">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Pick a plan</h3>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            Experience shuffl generously with Community - then explore smart shufflin&apos; with Pro!
          </p>
        </div>
        <div className="mb-8">
          <RadioGroup name="price" legend="Plan">
            <RadioGroup.Item
              value={StripePriceLookupKey.Community}
              label={
                <div className="flex">
                  <div className="mr-auto">
                    <span className="mr-2">Shuffl Community</span>
                    {currentPrice === StripePriceLookupKey.Community && <Badge color="green">Current Plan</Badge>}
                  </div>
                  <div>Free</div>
                </div>
              }
              subLabel={<CommunityDetails />}
            />
            <RadioGroup.Item
              value={StripePriceLookupKey.ProMonthly}
              label={
                <div className="flex">
                  <div className="mr-auto">
                    <span className="mr-2">Shuffl Pro</span>
                    {currentPrice === StripePriceLookupKey.ProMonthly && <Badge color="green">Current Plan</Badge>}
                  </div>
                  <div>Starting at $50</div>
                </div>
              }
              subLabel={<ProDetails />}
            />
          </RadioGroup>
        </div>
        <div className="flex mb-8 text-sm font-medium text-gray-500 space-x-1">
          <span>Non-profit or a student group from an educational institute? </span>
          <Link
            onClick={() => {
              window.Intercom('showNewMessage');
            }}
          >
            <span className="flex items-center space-x-2 text-sm">
              <span>Contact billing support</span>
              <ArrowRightIcon className="w-4 h-4" />
            </span>
          </Link>
        </div>
        <SubmitButton>Select Plan</SubmitButton>
      </Form>
    </Formik>
  );
};
