import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Schedule, useCreateDefaultEventDefinitionMutation, User } from '../../../generated/graphql';

export interface AddChannelContextInterface {
  channelId?: string;
  users?: User[];
  schedule?: Partial<Schedule>;
  setChannelId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setUsers: React.Dispatch<React.SetStateAction<User[] | undefined>>;
  setSchedule: React.Dispatch<React.SetStateAction<Partial<Schedule> | undefined>>;
  addChannel: ReturnType<typeof useCreateDefaultEventDefinitionMutation>[0];
}

export interface AddChannelProviderProps {
  channelId?: string;
  users?: User[];
  schedule?: Partial<Schedule>;
}

const AddChannelContext = React.createContext<AddChannelContextInterface>({} as any);

const AddChannelProvider = (props: PropsWithChildren<AddChannelProviderProps>) => {
  const [channelId, setChannelId] = useState(props.channelId);
  const [users, setUsers] = useState(props.users);
  const [schedule, setSchedule] = useState(props.schedule);
  const [addChannel] = useCreateDefaultEventDefinitionMutation({
    variables: {
      // channelId,
      // schedule,
      // users,
    },
  });

  useEffect(() => {
    setChannelId(props.channelId);
  }, [props.channelId]);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  useEffect(() => {
    setSchedule(props.schedule);
  }, [props.schedule]);

  return (
    <AddChannelContext.Provider
      value={{ addChannel, channelId, schedule, setChannelId, setSchedule, setUsers, users }}
      {...props}
    />
  );
};

function useAddChannel() {
  const context = React.useContext(AddChannelContext);

  if (context === undefined) {
    throw new Error(`useAddChannel must be used within a AddChannelContext`);
  }

  return context;
}

export { AddChannelProvider, useAddChannel };
