import { Outlet, useSearchParams } from 'react-router-dom';
import React from 'react';
import { useUser } from '../../../context/user-context';
import { StripePriceLookupKey } from '../../../generated/graphql';
import { ChangePlan } from './ChangePlan';
import { ChangePriceProvider } from './context';

export const ChangePlanContainer = () => {
  const { tenantQuery } = useUser();
  const [searchParams] = useSearchParams();
  const priceParam = searchParams.get('price') as string;
  let price = tenantQuery.data?.tenant.price ?? StripePriceLookupKey.ProMonthly;

  if (priceParam) {
    switch ((priceParam as string).toUpperCase()) {
      case StripePriceLookupKey.Community:
        price = StripePriceLookupKey.Community;
        break;
      case StripePriceLookupKey.ProMonthly:
        price = StripePriceLookupKey.ProMonthly;
        break;
      case StripePriceLookupKey.ProYearly:
        price = StripePriceLookupKey.ProYearly;
        break;
      default:
        price = StripePriceLookupKey.Community;
    }
  }

  return (
    <ChangePriceProvider price={price} monthlyUsers={50}>
      <ChangePlan>
        <Outlet />
      </ChangePlan>
    </ChangePriceProvider>
  );
};
