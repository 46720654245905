import { Divider, InputGroup, IsLoadingProvider, LearningHubLink, RadioGroup, Alert } from '@shuffl/tailwind-ui-react';
import { mapKeys } from 'lodash';
import { Formik, Form } from 'formik';
import React, { PropsWithChildren, KeyboardEvent } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router';
import pluralize from 'pluralize';
import { SubmitButton } from '../../../../components/Form';
import {
  GetSlackConversationsQueryHookResult,
  GetTenantQueryHookResult,
  OnboardingDefinitionStepId,
  SlackConversationsInfoResponse,
  useUpdateOnboardingInstanceMutation,
} from '../../../../generated/graphql';
import { useOnboarding } from '../../../OnboardingV3/context';
import { FormData, SelectChannelSchema } from './form-schema';

export interface AddChannelSelectChannelProps {
  slackConversationsQuery: GetSlackConversationsQueryHookResult;
  tenantQuery: GetTenantQueryHookResult;
}

export const AddChannelSelectChannel = ({
  slackConversationsQuery,
  tenantQuery,
}: PropsWithChildren<AddChannelSelectChannelProps>) => {
  const tenant = tenantQuery.data?.tenant;
  const navigate = useNavigate();
  const { onboardingInstanceQuery } = useOnboarding();
  const [updateOnboardingInstance] = useUpdateOnboardingInstanceMutation();
  const onboardingInstance = onboardingInstanceQuery.data?.onboardingInstance;

  const selectChannelMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectChannel,
  )?.metadata;

  let previouslySelectedConversationId;

  if (selectChannelMetadata) {
    previouslySelectedConversationId = JSON.parse(selectChannelMetadata).conversationId;
  }

  const slackConversations = slackConversationsQuery.data?.slackConversations.edges
    ?.map((edge) => edge?.node)
    .filter((slackConversation) => !!slackConversation) as SlackConversationsInfoResponse[];
  const numberFormatter = new Intl.NumberFormat('en-US');
  const slackConversationsById = mapKeys(slackConversations, 'id');

  function onKeyDown(keyEvent: KeyboardEvent<HTMLFormElement>) {
    if (keyEvent.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (keyEvent.key !== undefined && keyEvent.key === 'Enter') {
      handled = true;
    } else if (keyEvent.keyCode !== undefined && keyEvent.keyCode === 13) {
      handled = true;
    }

    if (handled) {
      // Suppress "double action" if event handled
      keyEvent.preventDefault();
    }
  }

  return (
    <Formik
      validationSchema={SelectChannelSchema}
      initialValues={{
        slackConversationId:
          previouslySelectedConversationId ?? tenant?.slackMetadata?.permissions?.createConversation
            ? 'shuffl-meetups'
            : undefined,
      }}
      enableReinitialize
      onSubmit={async (submitData: FormData) => {
        const slackConversation =
          submitData.slackConversationId === 'shuffl-meetups'
            ? { id: 'shuffl-meetups', name: 'shuffl-meetups' }
            : slackConversationsById[submitData.slackConversationId];

        await updateOnboardingInstance({
          variables: {
            isCompleted: true,
            metadata: JSON.stringify({
              conversationId: slackConversation.id,
              conversationName: slackConversation.name,
            }),
            stepId: OnboardingDefinitionStepId.OnboardingV3SelectChannel,
          },
        });
        await onboardingInstanceQuery.refetch();
        navigate('../members');
      }}
    >
      {(formik) => {
        const selectedSlackConversationId = formik.values['slackConversationId'];

        const selectedSlackConversation = selectedSlackConversationId
          ? slackConversationsById[selectedSlackConversationId]
          : undefined;

        return (
          <Form onKeyDown={onKeyDown}>
            <div className="mb-8">
              <div>
                <div className="mb-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Create a New Shuffl Channel</h3>
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    Shuffl will create a new channel in Slack and add itself to this channel.
                  </p>
                </div>
                {tenant?.slackMetadata?.permissions.createConversation && (
                  <div className="space-y-8">
                    <RadioGroup name="slackConversationId" legend="Channel">
                      <RadioGroup.Item value={`shuffl-meetups`} label={`#shuffl-meetups`} />
                    </RadioGroup>
                    <SubmitButton disabled={selectedSlackConversationId !== 'shuffl-meetups'}>
                      Select Channel
                    </SubmitButton>
                  </div>
                )}
                {!tenant?.slackMetadata?.permissions.createConversation && (
                  <Alert
                    type="warn"
                    title="Shuffl does not have the required Slack Bot Permissions to create a new channel"
                    details={
                      <div className="space-y-4">
                        <div>Please select an existing channel below instead.</div>
                        <LearningHubLink articleId="">
                          Learn more about Slack Bot Permission in our Learning Hub
                        </LearningHubLink>
                      </div>
                    }
                  />
                )}
                <div className="my-10">
                  <Divider>
                    <h1 className="text-lg font-medium">OR</h1>
                  </Divider>
                </div>
                <IsLoadingProvider isLoading={slackConversationsQuery.loading}>
                  <div className="flex justify-between mb-8 items-center">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Select an existing Channel</h3>
                      <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        The shuffl bot will be added to this channel.
                      </p>
                    </div>
                    <div className="w-1/2">
                      <InputGroup
                        name="slackConversationSearch"
                        label="Search for Channel"
                        icon={<SearchIcon />}
                        hideOptional
                        autoComplete="off"
                        debounce={{
                          wait: 500,
                        }}
                        onChange={(e: any) => {
                          slackConversationsQuery.refetch({
                            name: e.target.value ?? '',
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-8">
                    <Alert
                      title={<span className="font-medium">Looking for a private channel?</span>}
                      details={
                        <div className="space-y-4">
                          <div>
                            To add Shuffl to a private channel, go to the channel and add the Shuffl app directly
                          </div>
                          <LearningHubLink articleId="5754865-how-to-add-shuffl-to-a-private-channel" size="sm">
                            Learn more about private channels in our Learning Hub
                          </LearningHubLink>
                        </div>
                      }
                    />
                  </div>
                  <RadioGroup
                    isEmpty={(slackConversations?.length ?? 0) === 0}
                    emptyText="No Channels"
                    height="lg"
                    name="slackConversationId"
                    legend="Channel"
                    onAtBottom={() => {
                      if (
                        slackConversationsQuery.data?.slackConversations.pageInfo.hasNextPage &&
                        !slackConversationsQuery.loading
                      ) {
                        slackConversationsQuery.fetchMore({
                          variables: {
                            after: slackConversationsQuery.data?.slackConversations.pageInfo.endCursor,
                          },
                        });
                      }
                    }}
                  >
                    {slackConversations?.length > 0 &&
                      slackConversations?.map((slackConversation) => {
                        return (
                          <RadioGroup.Item
                            key={slackConversation.id}
                            value={slackConversation.id}
                            label={
                              <div className="flex justify-between items-center">
                                <span>#{slackConversation.name}</span>
                                <span className="text-gray-500 text-sm items-center space-x-1">
                                  <span>{numberFormatter.format(slackConversation.numberOfMembers ?? 0)} </span>
                                  <span>{pluralize('Member', slackConversation.numberOfMembers ?? 0)}</span>
                                </span>
                              </div>
                            }
                          />
                        );
                      })}
                  </RadioGroup>
                </IsLoadingProvider>
              </div>
            </div>
            {(selectedSlackConversation?.numberOfMembers ?? 0) > 50 && (
              <div className="mb-8">
                <Alert
                  title={
                    <div>
                      <span className="font-medium">#{selectedSlackConversation?.name} </span>
                      <span>has more than 50 members in it.</span>
                    </div>
                  }
                  details={
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <div>
                          Shuffl Community supports up to 50 people, so a random selection of 50 lucky members will get
                          shuffl&apos;d!
                        </div>
                        <div>
                          To shuffl everyone please upgrade to Shuffl Pro on the Billing page after you finish
                          onboarding.
                        </div>
                      </div>
                      <LearningHubLink articleId="5049334-what-is-shuffl-s-pricing-model" size="sm">
                        Learn more about Shuffl Pro in our Learning Hub
                      </LearningHubLink>
                    </div>
                  }
                />
              </div>
            )}
            <SubmitButton disabled={selectedSlackConversationId === 'shuffl-meetups'}>Select Channel</SubmitButton>
          </Form>
        );
      }}
    </Formik>
  );
};
