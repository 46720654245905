import { object, string, InferType, boolean } from 'yup';

export const GroupSettingsSchema = object()
  .shape({
    groupSize: string().required(),
    template: string()
      .min(10, 'Custom Intro must be at least 10 characters')
      .max(2000, 'Custom Intro must be less than 2000 characters')
      .optional(),
  })
  .defined();

export const GroupSettingsAddonsSchema = object()
  .shape({
    sendBiosToGroups: boolean().required(),
  })
  .defined();

export type GroupSettingsFormData = InferType<typeof GroupSettingsSchema>;
export type GroupSettingsAddonsFormData = InferType<typeof GroupSettingsAddonsSchema>;
