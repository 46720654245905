/* eslint-disable no-await-in-loop */
import React from 'react';
import { Alert, Badge, Button, DescriptionList, Header, LearningHubLink } from '@shuffl/tailwind-ui-react';

import { startCase } from 'lodash';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { OnboardingDefinitionStepId, useUpdateOnboardingInstanceMutation } from '../../../generated/graphql';
import { useOnboarding } from '../context';
import { useUser } from '../../../context/user-context';

export const OnboardingConfirmSettings = () => {
  const { onboardingInstanceQuery } = useOnboarding();
  const { isOnboardingInstanceCompletedQuery } = useUser();
  const navigate = useNavigate();
  const onboardingInstance = onboardingInstanceQuery.data?.onboardingInstance;
  const selectMembersMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectMembers,
  )?.metadata;
  const selectChannelMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectChannel,
  )?.metadata;
  const selectScheduleMetadata = onboardingInstance?.stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectSchedule,
  )?.metadata;

  let users = [];
  let conversationName;
  let frequency;
  let scheduledTime = moment();

  if (selectMembersMetadata) {
    users = JSON.parse(selectMembersMetadata).users;
  }

  if (selectChannelMetadata) {
    conversationName = JSON.parse(selectChannelMetadata).conversationName;
  }

  if (selectScheduleMetadata) {
    const parsedMetadata = JSON.parse(selectScheduleMetadata);
    frequency = parsedMetadata.frequency;
    scheduledTime = moment(parsedMetadata.scheduledTime ?? new Date()).tz(parsedMetadata.timezone);
  }

  const scheduledTimeFormat = 'dddd (MMM Do)';
  const scheduledFooFormat = 'h:mm A zz';
  // eventDefinitionSection.addField(`*First Shuffl*\n${eventDefinition.nextRunDateToString()}`);
  // eventDefinitionSection.addField(`*Schedule*\n${eventDefinition.schedule.scheduleToString()}`);

  const [updateOnboardingInstance] = useUpdateOnboardingInstanceMutation();

  const numberFormatter = new Intl.NumberFormat('en-US');

  return (
    <>
      <div className="mb-8">
        <h3 className="flex flex-col space-y-3 text-lg leading-6 font-medium text-gray-900">
          <span>
            <span>You&apos;re almost there! </span>
            <span role="img" aria-label="emoji tada">
              🎉
            </span>
          </span>
        </h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          One last step, please confirm these are the correct settings.
        </p>
      </div>
      <div className="grid grid-cols-2 gap-8 mb-8">
        <div>
          <div className="mb-2">
            <div className="flex justify-between items-center">
              <Header type="h3">Channel</Header>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <DescriptionList>
              <DescriptionList.Item name="Name">#{conversationName}</DescriptionList.Item>
              <DescriptionList.Item name="Members">{numberFormatter.format(users.length ?? 0)}</DescriptionList.Item>
            </DescriptionList>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <div className="flex justify-between items-center">
              <Header type="h3">Smart Shuffl</Header>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <DescriptionList>
              <DescriptionList.Item name="Shuffl Algorithm">Random</DescriptionList.Item>
            </DescriptionList>
          </div>
        </div>
        <div>
          <div className="mb-2 ">
            <div className="flex justify-between items-center">
              <Header type="h3">Schedule</Header>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <DescriptionList>
              <DescriptionList.Item name="First Shuffl">
                {scheduledTime.format(scheduledTimeFormat)} at {scheduledTime.format(scheduledFooFormat)}
              </DescriptionList.Item>
              <DescriptionList.Item name="Frequency">
                {startCase(frequency?.toLowerCase() ?? '')} on {scheduledTime.format('dddd')} at{' '}
                {scheduledTime.format(scheduledFooFormat)}
              </DescriptionList.Item>
            </DescriptionList>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <div className="flex justify-between items-center">
              <Header type="h3">Group Settings</Header>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <DescriptionList>
              <DescriptionList.Item name="Group Size">3</DescriptionList.Item>
              <DescriptionList.Item name="Bios and Intros">
                <Badge color="green">Enabled</Badge>
              </DescriptionList.Item>
            </DescriptionList>
          </div>
        </div>
      </div>
      {(users?.length ?? 0) > 50 && (
        <div className="mb-8">
          <Alert
            title={
              <div>
                <span className="font-medium">#{conversationName} </span>
                <span>has more than 50 members in it.</span>
              </div>
            }
            details={
              <div className="space-y-4">
                <div className="space-y-2">
                  <div>
                    Shuffl Community supports up to 50 people, so a random selection of 50 lucky members will get
                    shuffl&apos;d!
                  </div>
                  <div>
                    To shuffl everyone please upgrade to Shuffl Pro on the Billing page after you finish onboarding.
                  </div>
                </div>
                <LearningHubLink articleId="5049334-what-is-shuffl-s-pricing-model" size="sm">
                  Learn more about Shuffl Pro in our Learning Hub
                </LearningHubLink>
              </div>
            }
          />
        </div>
      )}
      <div className="mb-8 w-1/2">
        <Button
          onClick={async () => {
            await updateOnboardingInstance({
              variables: {
                isCompleted: true,
                stepId: OnboardingDefinitionStepId.OnboardingV3ConfirmSettings,
              },
            });
            let isOnboardingInstanceCompleted = await isOnboardingInstanceCompletedQuery.refetch();

            while (!isOnboardingInstanceCompleted.data?.isOnboardingInstanceCompleted) {
              await new Promise((resolve) => setTimeout(resolve, 500));
              isOnboardingInstanceCompleted = await isOnboardingInstanceCompletedQuery.refetch();
            }

            await onboardingInstanceQuery.refetch();

            navigate('/channels', { replace: true });
          }}
        >
          Confirm Settings
        </Button>
      </div>
    </>
  );
};
