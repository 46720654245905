import React from 'react';
import {
  useGetOnboardingInstanceQuery,
  useGetIsOnboardingInstanceCompletedForPollingQuery,
  useGetDefaultEventDefinitionQuery,
  useGetNextRunEventDefinitionQuery,
} from '../../generated/graphql';
import { useUser } from '../../context/user-context';

import Onboarding from './Onboarding';

const POLLING_INTERVAL = 2000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OnboardingContainer = () => {
  const { loading, data: onboardingInstance } = useGetOnboardingInstanceQuery({
    pollInterval: POLLING_INTERVAL,
  });

  const { data: isOnboardingCompletedData } = useGetIsOnboardingInstanceCompletedForPollingQuery({
    pollInterval: POLLING_INTERVAL,
  });

  let isOnboardingCompleted = false;

  if (isOnboardingCompletedData && isOnboardingCompletedData.isOnboardingInstanceCompletedForPolling) {
    isOnboardingCompleted = isOnboardingCompletedData.isOnboardingInstanceCompletedForPolling;
  }

  const { data: defaultEventDefinition } = useGetDefaultEventDefinitionQuery({
    pollInterval: POLLING_INTERVAL,
  });
  const { data: nextRunEventDefinition, refetch } = useGetNextRunEventDefinitionQuery({
    pollInterval: POLLING_INTERVAL,
  });

  const { tenantQuery, updateTenant } = useUser();

  return (
    <Onboarding
      refetchNextRunEventDefinition={refetch}
      loading={loading}
      onboardingInstance={onboardingInstance}
      isOnboardingCompleted={isOnboardingCompleted}
      nextRunEventDefinition={nextRunEventDefinition?.nextRunEventDefinition}
      defaultEventDefinition={defaultEventDefinition}
      tenantQuery={tenantQuery}
      updateTenant={updateTenant}
    />
  );
};
