import moment from 'moment-timezone';
import React, { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Link,
  Badge,
  Header,
  ExternalLink,
  Panel,
  VerticalNavigation,
  DescriptionList,
  useIsLoading,
} from '@shuffl/tailwind-ui-react';
import { Page } from '../../../components/Page';

import {
  EventDefinitionState,
  FeatureFlagKey,
  FeatureFlagVariableKey,
  GetEventDefinitionQueryHookResult,
  GetTenantQueryHookResult,
} from '../../../generated/graphql';
import { useFeatureFlag } from '../../../hooks/use-feature-flag';

export interface ChannelProps {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;
  tenantQuery: GetTenantQueryHookResult;
}

export const Channel = (props: PropsWithChildren<ChannelProps>) => {
  const eventDefinition = props.eventDefinitionQuery.data?.eventDefinition;
  const scheduledNextRunDate = moment(eventDefinition?.nextShufflEvent?.scheduledTime).tz(
    eventDefinition?.schedule.timezone ?? 'UTC',
  );
  const webAppNewChannelDetailsFeatureFlag = useFeatureFlag({ key: FeatureFlagKey.WebAppNewChannelDetails });
  const biosFeatureFlag = useFeatureFlag({
    eventDefinitionId: eventDefinition?.id,
    key: FeatureFlagKey.SlackSendBioMessages,
  });
  const { isLoading } = useIsLoading();

  return (
    <Page>
      <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6">
        <div className="lg:col-span-6">
          <Panel padding>
            <div className="flex">
              <div className="mr-auto">
                <div className="flex">
                  {isLoading && (
                    <div className="ml-0 sm:ml-3 w-full mb-4">
                      <Skeleton />
                    </div>
                  )}
                  {!isLoading && (
                    <Header>
                      <ExternalLink
                        href={`slack://channel?team=${props.tenantQuery.data?.tenant.slackMetadata?.team.id}&id=${eventDefinition?.slackMetadata?.channel.id}`}
                      >
                        #{eventDefinition?.slackMetadata?.channel.name}
                      </ExternalLink>
                    </Header>
                  )}
                </div>
                <div className="-mb-4">
                  <DescriptionList divided={false}>
                    <DescriptionList.Item name="Next shuffl:">
                      <span className="text-sm text-gray-500 font-medium">
                        {eventDefinition?.state === EventDefinitionState.Enabled && (
                          <>
                            {scheduledNextRunDate.format('dddd (MMM Do)')} at {scheduledNextRunDate.format('h A zz')}
                          </>
                        )}
                        {eventDefinition?.state !== EventDefinitionState.Enabled && <>Never</>}
                      </span>
                    </DescriptionList.Item>
                    <DescriptionList.Item name="Created by:">
                      <span className="text-sm text-gray-500 font-medium">
                        <Link to={`/members/${eventDefinition?.createdBy.id}`}>{eventDefinition?.createdBy.name}</Link>{' '}
                      </span>
                      <span className="text-sm text-gray-500 font-medium">
                        {moment(eventDefinition?.createdAt).fromNow()}
                      </span>
                    </DescriptionList.Item>
                  </DescriptionList>
                </div>
              </div>
              <div>
                {eventDefinition?.state === EventDefinitionState.Enabled && <Badge color="green">Enabled</Badge>}
                {eventDefinition?.state !== EventDefinitionState.Enabled && <Badge color="red">Paused</Badge>}
              </div>
            </div>
          </Panel>
        </div>
        <div className="lg:col-span-3 lg:row-start-2">
          <Panel sticky>
            <VerticalNavigation>
              <VerticalNavigation.Item
                to="overview"
                matchTo="channels/:id/overview"
                title="Overview"
              ></VerticalNavigation.Item>
              <VerticalNavigation.Item to="smart-shuffl" title="Smart Shuffl" matchTo="channels/:id/smart-shuffl" />
              <VerticalNavigation.Item to="schedule" title="Schedule" matchTo="channels/:id/schedule">
                <VerticalNavigation.SubItem
                  title="Current Schedule"
                  to="#current-schedule"
                  matchTo="channels/:id/schedule#current-schedule"
                />
                <VerticalNavigation.SubItem
                  title="Overrides"
                  to="#overrides"
                  matchTo="channels/:id/schedule#overrides"
                />
              </VerticalNavigation.Item>
              <VerticalNavigation.Item to="history" title="Shuffl History" matchTo="channels/:id/history" />
              <VerticalNavigation.Item to="members" title="Members" matchTo="channels/:id/members" />
              <VerticalNavigation.Item to="group-settings" title="Group Settings" matchTo="channels/:id/group-settings">
                <VerticalNavigation.SubItem
                  title="Settings"
                  to="#settings"
                  matchTo="channels/:id/group-settings#settings"
                />
                {biosFeatureFlag.featureFlag?.isEnabled && (
                  <VerticalNavigation.SubItem
                    title="Add Ons"
                    to="#add-ons"
                    matchTo="channels/:id/group-settings#add-ons"
                  />
                )}
              </VerticalNavigation.Item>
              {webAppNewChannelDetailsFeatureFlag.getVariable(FeatureFlagVariableKey.WebAppChannelDetailsNotifications)
                ?.defaultValue === 'true' && (
                <VerticalNavigation.Item
                  to="notifications"
                  title="Notifications"
                  matchTo="channels/:id/notifications"
                />
              )}
              {webAppNewChannelDetailsFeatureFlag.getVariable(FeatureFlagVariableKey.WebAppChannelDetailsReporting)
                ?.defaultValue === 'true' && (
                <VerticalNavigation.Item to="reporting" title="Reporting" matchTo="channels/:id/reporting">
                  <VerticalNavigation.SubItem
                    title="Shuffl'd Members"
                    to="#shuffld-members"
                    matchTo="channels/:id/reporting#shuffld-members"
                  />
                  <VerticalNavigation.SubItem
                    title="New Connections"
                    to="#new-connections"
                    matchTo="channels/:id/reporting#new-connections"
                  />
                  <VerticalNavigation.SubItem
                    title="Avg. New Connections"
                    to="#avg-new-connections-per-group"
                    matchTo="channels/:id/reporting#avg-new-connections-per-group"
                  />
                  <VerticalNavigation.SubItem title="Groups" to="#groups" matchTo="channels/:id/reporting#groups" />
                  <VerticalNavigation.SubItem
                    title="Shuffl Bios"
                    to="#shuffl-bios"
                    matchTo="channels/:id/reporting#shuffl-bios"
                  />
                </VerticalNavigation.Item>
              )}
            </VerticalNavigation>
          </Panel>
        </div>
        <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6 lg:col-span-9 lg:row-start-2">
          {props.children}
        </div>
      </div>
    </Page>
  );
};
