import React, { PropsWithChildren, ReactNode } from 'react';
import './index.css';
import classnames from 'classnames';
import Footer from '../Footer';
import { Nav } from '../Nav';

export interface PageProps {
  header?: string | ReactNode;
  hideNavLinks?: boolean;
}

export const Page = ({ header, children, hideNavLinks }: PropsWithChildren<PageProps>) => {
  const mainClassName = classnames('flex-grow', {
    '-mt-16': !header,
    '-mt-20': !!header,
  });

  return (
    <div className="font-body antialiased text-gray-900 bg-gray-100 flex flex-col min-h-screen">
      <div className="bg-gradient-to-r from-purple-700 to-purple-500 pb-24 font-body antialiased">
        <Nav hideNavLinks={hideNavLinks} />
        {header && (
          <header className="py-10 text-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 ">
              <h1 className="text-3xl leading-9 font-semibold text-white">{header}</h1>
            </div>
          </header>
        )}
      </div>
      <main className={mainClassName}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 pb-12 px-4">{children}</div>
      </main>
      <Footer />
    </div>
  );
};
