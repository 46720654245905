import React, { useState } from 'react';
import classnames from 'classnames';
import { Link, LinkProps, useMatch } from 'react-router-dom';
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton';
import { Transition } from '@headlessui/react';
import { useUser } from '../../context/user-context';
import { useLogoutMutation } from '../../generated/graphql';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavMenuLink = (props: LinkProps) => {
  const match = useMatch(`${props.to}/*`);

  const className = classnames(
    'transition hover:opacity-100 mr-8 py-4 cursor-pointer font-semibold text-white',
    {
      'mr-8 py-4 opacity-100': !!match,
      'opacity-50': !match,
    },
    props.className,
  );

  return <Link {...props} className={className} />;
};

export interface NavProps {
  hideNavLinks?: boolean;
}

export const Nav = ({ hideNavLinks }: NavProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [logout] = useLogoutMutation();

  const {
    userQuery,
    // isShufflAdmin,
    // impersonatedBy,
    // isPayingOrTrialing,
    // updateTenant,
    isAdmin,
    isSuperAdmin,
    // tenant: { tenant },
  } = useUser();
  // const [stopImpersonating] = useStopImpersonatingMutation();

  const { email, name, slackMetadata } = userQuery.data?.profile ?? {};

  return (
    <nav className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 text-2xl font-light text-white font-saira uppercase">
              SHUFFL
            </Link>
            {!hideNavLinks && (
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline">
                  <NavMenuLink to="/channels">Channels</NavMenuLink>
                  {isAdmin && <NavMenuLink to="/members">Members</NavMenuLink>}
                  {isSuperAdmin && (
                    <NavMenuLink to="/account/billing" className="mr-auto">
                      Billing
                    </NavMenuLink>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              {/* <!-- Profile dropdown --> */}
              <div className="ml-3 relative">
                <div>
                  {email ? (
                    <button
                      className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:border-gray-300 border-2 border-transparent hover:border-gray-300 transition duration-150 ease-in-out"
                      id="user-menu"
                      aria-label="User menu"
                      aria-haspopup="true"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <Avatar
                        email={email ?? ''}
                        src={slackMetadata?.image ?? ''}
                        name={name ?? ''}
                        alt={name ?? ''}
                        round={true}
                        size={'40'}
                        color="#3182CE"
                      />
                    </button>
                  ) : (
                    <Skeleton circle={true} height={50} width={50} />
                  )}
                </div>
                <Transition
                  show={isOpen}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {(ref) => (
                    <div ref={ref} className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                      <div className="py-1 rounded-md bg-white shadow-xs">
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          onClick={async () => {
                            await logout();
                            window.location.assign('/');
                          }}
                        >
                          Sign out
                        </div>
                      </div>
                    </div>
                  )}
                </Transition>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            {/* <!-- Mobile menu button --> */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
              onClick={() => setIsOpen(!isOpen)}
            >
              {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
              <svg
                className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
              <svg
                className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* <!--
        Mobile menu, toggle classes based on menu state.

        Open: "block", closed: "hidden"
      --> */}
      <div className={`${isOpen ? 'block' : 'hidden'} border-b border-gray-700 md:hidden`}>
        <div className="px-2 py-3 space-y-1 sm:px-3"></div>
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-5 space-x-3">
            <div className="flex-shrink-0">
              <Avatar
                email={userQuery.data?.profile.email || undefined}
                name={`${userQuery.data?.profile.firstName} ${userQuery.data?.profile.lastName}`}
                round={true}
                size={'40'}
                color="#3182CE"
              />
            </div>
            <div className="space-y-1">
              <div className="text-base font-medium leading-none text-white">
                {userQuery.data?.profile.firstName} {userQuery.data?.profile.lastName}
              </div>
            </div>
          </div>
          <div className="mt-3 px-2 space-y-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <div
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 cursor-pointer"
              role="menuitem"
              onClick={async () => {
                await logout();
                window.location.assign('/');
              }}
            >
              Sign out
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
