import React from 'react';
import { Button } from '@shuffl/tailwind-ui-react';

import DefaultStep, { DefaultStepProps } from '../DefaultStep';

export interface AddUserToChannelProps extends DefaultStepProps {
  slackTeamId: string;
  useNativeClient?: boolean;
  channelId: string;
}

const AddUserToChannel = (props: AddUserToChannelProps) => {
  const useNativeClient = props.useNativeClient ?? true;
  const prefixLink = useNativeClient ? 'slack://channel' : 'https://slack.com/app_redirect';

  return (
    <DefaultStep
      {...props}
      accessory={
        props.isCurrentStep && (
          <Button url={`${prefixLink}?team=${props.slackTeamId}&id=${props.channelId}`}>Open Channel</Button>
        )
      }
    />
  );
};

export default AddUserToChannel;
