import { startCase } from 'lodash';
import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { UsersIcon } from '@heroicons/react/solid';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Table, InputGroup } from '@shuffl/tailwind-ui-react';
import { useChangePrice } from '../context';
import { StripePriceLookupKey, useUpdateTenantStripeSubscriptionMutation } from '../../../../generated/graphql';
import { CommunityDetails } from '../components/CommunityDetails';
import { ProDetails } from '../components/ProDetails';
import { StripePaymentFormContainer } from '../../components/StripePaymentForm';
import { useUser } from '../../../../context/user-context';
import { EstimatedCostSchema } from './form-schema';

const userCountValues = [
  { price: { monthly: 50, yearly: 46 }, quantity: { max: 50, min: 1 } },
  { price: { monthly: 150, yearly: 138 }, quantity: { max: 100, min: 51 } },
  { price: { monthly: 400, yearly: 367 }, quantity: { max: 200, min: 101 } },
  { price: { monthly: 600, yearly: 550 }, quantity: { max: 300, min: 201 } },
  { price: { monthly: 800, yearly: 733 }, quantity: { max: 400, min: 301 } },
  { price: { monthly: 1250, yearly: 1146 }, quantity: { max: 500, min: 401 } },
  { price: { monthly: 1875, yearly: 1719 }, quantity: { max: 750, min: 501 } },
  { price: { monthly: 2500, yearly: 2292 }, quantity: { max: 1000, min: 751 } },
];

function findCostForMemberQuantity(quantity: number) {
  let cost = userCountValues[0];

  userCountValues.forEach((value) => {
    if (cost.quantity.max >= quantity) {
      return;
    }

    cost = value;
  });

  return cost;
}

export const PaymentDetails = () => {
  const { price, monthlyUsers } = useChangePrice();
  const { addToast } = useToasts();
  const { updateTenant } = useUser();
  const navigate = useNavigate();

  const [updateStripeSubscription] = useUpdateTenantStripeSubscriptionMutation({
    onCompleted: async () => {
      addToast('Updated subscription', { appearance: 'success' });
      navigate(`/account/billing`);
      // TODO: Refetch tenant
    },
    onError: (error) => {
      addToast('Could not update subscription', { appearance: 'error' });
      console.error('An error occurred trying to update tenant subscription', error);
    },
  });

  useEffect(() => {
    if (!price) {
      navigate('../select-plan', { replace: true });
    }
  });

  async function onSubmit() {
    if (price) {
      await updateStripeSubscription({ variables: { priceLookupKey: price } });
      await updateTenant({
        isPayingOrTrialing: price !== StripePriceLookupKey.Community,
      });
    }
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  const formattedPriceName = startCase(price?.toLowerCase());

  return (
    <>
      <div className="flex flex-col mb-12">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Select {formattedPriceName}</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500 mb-8">Features and pricing details</p>
        <div className="bg-gray-50 -m-4 p-4 border-gray-300 text-sm text-gray-500">
          <div className="flex font-medium text-gray-900 mb-2">
            <div className="mr-auto">
              <span className="mr-2">{formattedPriceName}</span>
            </div>
            {price === StripePriceLookupKey.Community && <div>Free</div>}
            {price === StripePriceLookupKey.ProMonthly && <div>Starting at $50</div>}
          </div>
          {price === StripePriceLookupKey.Community && <CommunityDetails />}
          {price === StripePriceLookupKey.ProMonthly && <ProDetails />}
        </div>
      </div>
      {price === StripePriceLookupKey.ProMonthly && (
        <>
          <div className="mb-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Estimated Monthly Price</h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Monthly plans bill at the end of period based on usage (number of unique members shuffl&apos;d)
              </p>
            </div>
            <div className="w-full">
              <Formik validationSchema={EstimatedCostSchema} initialValues={{ monthlyUsers }} onSubmit={() => {}}>
                {(formik) => {
                  const cost = findCostForMemberQuantity(formik.values.monthlyUsers ?? 50);
                  const formattedCost = currencyFormatter.format(cost.price.monthly);
                  return (
                    <Form>
                      <Table>
                        <Table.Header hidden />
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell padding={false}>
                              <span className="flex flex-col">
                                <span className="font-medium">Monthly Users</span>
                                <span className="text-sm text-gray-500 font-medium">Plan starts with 50 members</span>
                              </span>
                            </Table.Cell>
                            <Table.Cell padding={false}>
                              <span className="flex justify-center w-32">
                                <InputGroup
                                  label="Monthly Users"
                                  hideLabel
                                  type="number"
                                  name="monthlyUsers"
                                  icon={<UsersIcon className="h-5 w-5" />}
                                  showNumberSpinButtons
                                />
                              </span>
                            </Table.Cell>
                            <Table.Cell padding={false}>
                              <span className="flex justify-end">{formattedCost} / month</span>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className="mb-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Billing Information</h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">Enter billing and payment details</p>
            </div>
            <StripePaymentFormContainer price={price} onSubmit={onSubmit} />
          </div>
        </>
      )}
    </>
  );
};
