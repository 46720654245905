/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useParams } from 'react-router-dom';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useEventDefinition } from '../../context/event-definition-context';
import { useGetUserEventDefinitionsQuery } from '../../../../generated/graphql';
import { ChannelMembers } from './Members';

export const ChannelMembersContainer = () => {
  const { eventDefinitionQuery } = useEventDefinition();
  const { id } = useParams();

  const userEventDefinitionsQuery = useGetUserEventDefinitionsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: {
      eventDefinitionId: id,
      first: 25,
    },
  });

  return (
    <IsLoadingProvider isLoading={eventDefinitionQuery.loading || userEventDefinitionsQuery.loading}>
      <ChannelMembers
        userEventDefinitionsQuery={userEventDefinitionsQuery}
        eventDefinitionQuery={eventDefinitionQuery}
      />
    </IsLoadingProvider>
  );
};
