import React, { ReactNode, useEffect, useState } from 'react';
import HtmlToReact from 'html-to-react';
import { useLocation } from 'react-router-dom';
import { LoadingIcon } from '@shuffl/tailwind-ui-react';

export interface SlackButtonProps {
  redirectUrl?: string;
  oauthType?: 'sign-in' | 'add-to-slack';
}

const SlackButton = (props?: SlackButtonProps) => {
  const [html, setHtml] = useState('');
  const { search } = useLocation();
  const defaultRedirectUrl = process.env.REACT_APP_SLACK_REDIRECT_URL as string;
  const redirectUrl = props?.redirectUrl || defaultRedirectUrl;
  const searchParams = new URLSearchParams(search);
  const utmSource = searchParams.get('utm_source') ?? 'portal';
  const utmMedium = searchParams.get('utm_medium');
  const utmLocale = searchParams.get('utm_locale');
  const oauthButtonUrl = new URL(`${process.env.REACT_APP_SERVER_URL}/slack/oauth-button`);

  oauthButtonUrl.searchParams.append('redirectUrl', redirectUrl);
  oauthButtonUrl.searchParams.append('oauthType', props?.oauthType ?? 'add-to-slack');

  if (utmSource) oauthButtonUrl.searchParams.append('utm_source', utmSource);
  if (utmMedium) oauthButtonUrl.searchParams.append('utm_medium', utmMedium);
  if (utmLocale) oauthButtonUrl.searchParams.append('utm_locale', utmLocale);

  useEffect(() => {
    fetch(oauthButtonUrl.toString())
      .then((response) => response.text())
      .then((data) => setHtml(data));
  }, []);

  const isValidNode = () => {
    return true;
  };

  // Order matters. Instructions are processed in the order they're defined
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      processNode(node: any) {
        return node.data.toUpperCase();
      },
      // Custom <h1> processing
      shouldProcessNode(node: any) {
        return node.parent && node.parent.name && node.parent.name === 'h1';
      },
    },
    {
      processNode: processNodeDefinitions.processDefaultNode,
      // Anything else
      shouldProcessNode() {
        return true;
      },
    },
  ];
  const htmlToReactParser = new HtmlToReact.Parser();
  const reactComponent = htmlToReactParser.parseWithInstructions(html, isValidNode, processingInstructions);

  return (
    <div>
      {html && (reactComponent as ReactNode)}
      {!html && <LoadingIcon />}
      <div></div>
    </div>
  );
};

export default SlackButton;
