import { sortBy, keyBy } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { Panel } from '@shuffl/tailwind-ui-react';
import {
  GetOnboardingInstanceQuery,
  GetDefaultEventDefinitionQuery,
  GetNextRunEventDefinitionQuery,
  GetTenantQueryHookResult,
  OnboardingDefinitionStepId,
} from '../../generated/graphql';

import { Page } from '../../components/Page';
import DefaultStep, { DefaultStepProps } from './steps/DefaultStep';
import { AddToSlack } from './steps/AddToSlack';
import { ConfigureChannel } from './steps/ConfigureChannel';
import { FinishedOnboarding } from './steps/FinishedOnboarding';
import AddUserToChannel from './steps/AddUserToChannel';

export interface OnboardingProps {
  onboardingInstance?: GetOnboardingInstanceQuery;
  loading?: boolean;
  isOnboardingCompleted: boolean;
  defaultEventDefinition?: GetDefaultEventDefinitionQuery;
  nextRunEventDefinition?: GetNextRunEventDefinitionQuery['nextRunEventDefinition'];
  tenantQuery: GetTenantQueryHookResult;
  refetchNextRunEventDefinition: () => Promise<any>;
  updateTenant: () => Promise<any>;
}

const Onboarding = (props: OnboardingProps) => {
  const stepsProgress = props.onboardingInstance?.onboardingInstance
    ? props.onboardingInstance?.onboardingInstance.stepsProgress
    : [];
  const definitionStepsProgress = props.onboardingInstance?.onboardingInstance?.onboardingDefinition
    ? props.onboardingInstance?.onboardingInstance?.onboardingDefinition.steps
    : [];
  const definitionStepsProgressById = keyBy(definitionStepsProgress, 'id');
  let channelId = '';
  let nextRunDate = '';
  let strategy = '';
  let scheduleToString = '';
  let groupSize = 4;
  let channelName = 'shuffl-meetups';

  if (props.defaultEventDefinition && props.defaultEventDefinition.defaultEventDefinition) {
    const { defaultEventDefinition } = props.defaultEventDefinition;
    strategy = defaultEventDefinition.shufflStrategy;
    groupSize = defaultEventDefinition.groupSize;
    scheduleToString = defaultEventDefinition.schedule.scheduleToString;
    channelId = defaultEventDefinition.slackMetadata?.channel.id ?? '';
    channelName = defaultEventDefinition.slackMetadata?.channel.name ?? '';
    nextRunDate = moment(defaultEventDefinition.nextRunDate).format('LLL');
  }

  const slackTeamId = props?.tenantQuery.data?.tenant.slackMetadata?.team.id ?? '';
  const slackName = props?.tenantQuery.data?.tenant.slackMetadata?.team.name ?? '';
  const slackIcon = props?.tenantQuery.data?.tenant.slackMetadata?.team.icon ?? '';

  return (
    <Page
      hideNavLinks
      header={
        <div className="flex flex-col justify-center items-center text-center">
          <h1>Thanks for installing Shuffl!</h1>
          <p className="w-3/4 md:w-full text-xl">Welcome to the Future of Work</p>
        </div>
      }
    >
      {props.loading && (
        <div className="flex flex-1 flex-col justify-center align-center h-full items-center">
          <div className="flex flex-col h-full w-full md:w-2/3">
            <div className="flex flex-col lg:flex-row mb-6 last:mb-0 shadow rounded p-6 bg-white w-full">
              <DefaultStep
                content={
                  <div>
                    <Skeleton count={6} />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      )}
      {!props.loading && (
        <div className="flex flex-1 flex-col justify-center align-center h-full items-center">
          <div className="flex flex-col h-full w-full md:w-2/3">
            {sortBy(stepsProgress, 'index').map((step) => {
              const lastStepCompleted = step.index > 0 ? stepsProgress[step.index - 1].isCompleted : false;
              const isCurrentStep = lastStepCompleted && !step.isCompleted;

              const stepDefinition = definitionStepsProgressById[step.id];
              const stepComponentProps: DefaultStepProps = {
                isCurrentStep,
                loading: props.loading,
                nextRunDate,
                step,
                stepDefinition,
              };

              let stepComponent;

              switch (step.id) {
                case OnboardingDefinitionStepId.OnboardingV2AddToSlack:
                  stepComponent = <AddToSlack tenantIcon={slackIcon} tenantName={slackName} {...stepComponentProps} />;
                  break;
                case OnboardingDefinitionStepId.OnboardingV2ConfigureChannel:
                  stepComponent = (
                    <ConfigureChannel
                      scheduleToString={scheduleToString}
                      channelName={channelName}
                      groupSize={groupSize}
                      strategy={strategy}
                      {...stepComponentProps}
                    />
                  );
                  break;
                case OnboardingDefinitionStepId.OnboardingV2AddUserToChannel:
                  stepComponent = (
                    <AddUserToChannel channelId={channelId} slackTeamId={slackTeamId} {...stepComponentProps} />
                  );
                  break;
                case OnboardingDefinitionStepId.OnboardingV2FireShufflEvent:
                  // NOTE: Don't show this step for now
                  // stepComponent = <FireShufflEvent {...stepComponentProps} />;
                  break;
                default:
                  stepComponent = <DefaultStep {...stepComponentProps} />;
                  break;
              }

              if (stepComponent) {
                return (
                  <div className="mb-6" key={step.id}>
                    <Panel padding>{stepComponent}</Panel>
                  </div>
                );
              }

              return null;
            })}
            {props.isOnboardingCompleted && (
              <Panel padding>
                <FinishedOnboarding scheduleToString={scheduleToString} />
              </Panel>
            )}
          </div>
        </div>
      )}
    </Page>
  );
};

export default Onboarding;
