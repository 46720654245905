import React from 'react';
import { useParams } from 'react-router-dom';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useGetShufflEventsQuery, useGetUserEventDefinitionsQuery } from '../../../../generated/graphql';
import { useEventDefinition } from '../../context/event-definition-context';
import { Reporting } from './Reporting';

export const ReportingContainer = () => {
  const { eventDefinitionQuery } = useEventDefinition();

  const { id } = useParams();

  const userEventDefinitionsQuery = useGetUserEventDefinitionsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: {
      eventDefinitionId: id,
      first: 25,
    },
  });

  const shufflEventsQuery = useGetShufflEventsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: {
      eventDefinitionId: id,
    },
  });
  return (
    <IsLoadingProvider
      isLoading={eventDefinitionQuery.loading || shufflEventsQuery.loading || userEventDefinitionsQuery.loading}
    >
      <Reporting
        shufflEventsQuery={shufflEventsQuery}
        userEventDefinitionsQuery={userEventDefinitionsQuery}
        eventDefinitionQuery={eventDefinitionQuery}
      />
    </IsLoadingProvider>
  );
};
