import { object, string, InferType } from 'yup';

export const NotificationsSchema = object()
  .shape({
    groupSize: string().required(),
    template: string().optional(),
  })
  .defined();

export type FormData = InferType<typeof NotificationsSchema>;
