import { object, InferType, mixed } from 'yup';
import { StripePriceLookupKey } from '../../../../generated/graphql';

export const PriceSchema = object()
  .shape({
    plan: mixed<StripePriceLookupKey>().oneOf(Object.values(StripePriceLookupKey)),
  })
  .defined();

export type FormData = InferType<typeof PriceSchema>;
