import React from 'react';
import moment from 'moment-timezone';
import { DocumentReportIcon } from '@heroicons/react/outline';
import { Table, IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import {
  GetAllEventDefinitionsQueryHookResult,
  GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult,
  GetStripeSubscriptionQueryHookResult,
  GetTenantQueryHookResult,
} from '../../../../../generated/graphql';

export interface ProYearlyUsageProps {
  usageRecordQuery?: GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult;
  eventDefinitionsQuery?: GetAllEventDefinitionsQueryHookResult;
  stripeSubscriptionQuery?: GetStripeSubscriptionQueryHookResult;
  tenantQuery?: GetTenantQueryHookResult;
}

export const ProYearlyUsage = ({
  usageRecordQuery,
  eventDefinitionsQuery,
  stripeSubscriptionQuery,
  tenantQuery,
}: ProYearlyUsageProps) => {
  const usageRecord = usageRecordQuery?.data?.highestUsageRecordForCurrentBillingPeriod;
  const eventDefinitionsCount = eventDefinitionsQuery?.data?.eventDefinitionConnection?.totalCount;

  const stripeSubscription = stripeSubscriptionQuery?.data?.stripeSubscription;
  const tenant = tenantQuery?.data?.tenant;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  const excessMonthlyFormattedPrice = currencyFormatter.format(
    Number(usageRecord?.excessCost ?? 0) / 100,
  ); /* $2,500.00 */
  const finalQuantity =
    Number(usageRecord?.excessMemberQuantity ?? 0) > 0
      ? usageRecord?.prePaidMemberQuantity
      : usageRecord?.memberQuantity;
  const formattedQuantity = numberFormatter.format(finalQuantity ?? 0);
  const formattedNumberOfEventDefinitions = numberFormatter.format(
    usageRecord?.numberOfEventDefinitions ?? eventDefinitionsCount ?? 0,
  );
  const formattedNumberOfShufflEvents = numberFormatter.format(usageRecord?.numberOfShufflEvents ?? 0);

  return (
    <IsLoadingProvider isLoading={false}>
      <Table hideBorder>
        <Table.Header hidden />
        <Table.Body>
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="font-medium">Shuffl Pro Yearly</span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="text-sm text-gray-500 font-medium">
                {formattedQuantity} / {numberFormatter.format(stripeSubscription?.prePaidMemberQuantity ?? 0)} prepaid
                members
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="text-sm text-gray-500 font-medium">{formattedNumberOfEventDefinitions} Channels</span>
                <span className="text-sm text-gray-500 font-medium">{formattedNumberOfShufflEvents} Shuffl Events</span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">$0.00</span>
            </Table.Cell>
          </Table.Row>
          {usageRecord?.excessMemberQuantity && usageRecord?.excessMemberQuantity > 0 && (
            <Table.Row>
              <Table.Cell padding={false}>
                <span className="flex flex-col">
                  <span className="font-medium">Excess Monthly Usage</span>
                  <span className="text-sm text-gray-500 font-medium">
                    Monthly Usage Tier {numberFormatter.format(usageRecord?.priceExcessTierMin ?? 0)} -{' '}
                    {numberFormatter.format(usageRecord?.priceExcessTierMax ?? 0)} members
                  </span>
                </span>
              </Table.Cell>
              <Table.Cell padding={false}>
                <span className="text-sm text-gray-500 font-medium">
                  {numberFormatter.format(usageRecord?.excessMemberQuantity ?? 0)} members
                </span>
              </Table.Cell>
              <Table.Cell padding={false}></Table.Cell>
              <Table.Cell padding={false}>
                <span className="font-medium">{excessMonthlyFormattedPrice}</span>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell padding={false}>
              <span className="flex flex-col">
                <span className="font-medium">Total Usage</span>
                <span className="text-sm text-gray-500 font-medium">
                  {moment(usageRecord?.billingPeriodStart ?? tenant?.currentBillingPeriod.periodStart).format(
                    'MMM DD YYYY',
                  )}{' '}
                  -{' '}
                  {moment(usageRecord?.billingPeriodEnd ?? tenant?.currentBillingPeriod.periodEnd).format(
                    'MMM DD YYYY',
                  )}
                </span>
              </span>
            </Table.Cell>
            <Table.Cell padding={false}>
              {usageRecord && usageRecord.reportUrl && (
                <a href={usageRecord.reportUrl} className="text-sm font-medium text-purple-600 hover:text-purple-500">
                  <div className="flex items-center">
                    <div className="mr-2">{numberFormatter.format(usageRecord.memberQuantity ?? 0)} members</div>
                    <DocumentReportIcon className="w-5 h-5" />
                  </div>
                </a>
              )}
              {(!usageRecord || !usageRecord.reportUrl) && (
                <span className="text-sm text-gray-500 font-medium">0 members</span>
              )}
            </Table.Cell>
            <Table.Cell padding={false}></Table.Cell>
            <Table.Cell padding={false}>
              <span className="font-medium">{excessMonthlyFormattedPrice}</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </IsLoadingProvider>
  );
};
