import React from 'react';
import Avatar from 'react-avatar';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Panel, Table, Header, Link, LearningHubLink, Button } from '@shuffl/tailwind-ui-react';

import { Page } from '../../components/Page';
import { GetAllAdminsQueryHookResult, User } from '../../generated/graphql';

export interface MembersProps {
  allAdminsQuery: GetAllAdminsQueryHookResult;
}

export const Members = ({ allAdminsQuery }: MembersProps) => {
  const navigate = useNavigate();
  const users = allAdminsQuery.data?.adminConnection.edges
    ?.map((edge) => edge?.node)
    .filter((user) => !!user) as User[];

  return (
    <Page>
      <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6">
        <div className="lg:col-span-6">
          <Panel padding>
            <div className="flex">
              <div className="mr-auto">
                <div className="flex flex-col space-y-4">
                  <Header>Team Members</Header>
                  <LearningHubLink articleId="5125747-members-manager" size="sm">
                    Learn more about admin roles in our Learning Hub
                  </LearningHubLink>
                </div>
              </div>
              <div>
                <Button to="add/email">Add Admin</Button>
              </div>
            </div>
          </Panel>
        </div>
        <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6 lg:col-span-12 lg:row-start-2">
          <div className="lg:col-span-12 lg:row-start-1">
            <Header type="h2" id="history" className="mb-2">
              Admins
            </Header>
            <Table
              isEmpty={(users?.length ?? 0) === 0}
              emptyText="No Members"
              stickyHeaders
              height="fixed"
              onAtBottom={() => {
                if (allAdminsQuery.data?.adminConnection.pageInfo.hasNextPage && !allAdminsQuery.loading) {
                  allAdminsQuery.fetchMore({
                    variables: {
                      after: allAdminsQuery.data?.adminConnection.pageInfo.endCursor,
                    },
                  });
                }
              }}
            >
              <Table.Header>
                <Table.Header.Cell>Name</Table.Header.Cell>
                <Table.Header.Cell>Role</Table.Header.Cell>
              </Table.Header>
              <Table.Body>
                {users?.map((user, index) => {
                  return (
                    <Table.Row key={user.id ?? index}>
                      <Table.Cell>
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <Avatar
                              size={'40'}
                              round={true}
                              email={user.email || ''}
                              src={user.slackMetadata?.image || ''}
                              alt={`${user.firstName} ${user.lastName}`}
                              name={`${user.firstName} ${user.lastName}`}
                            />
                          </div>
                          <div className="ml-4">
                            <Link to={user.id}>
                              {user.firstName} {user.lastName}
                            </Link>
                            <div className="text-sm text-gray-500">{user.email}</div>
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <span className="text-sm text-gray-500">{capitalize(user.role.replace('_', ' '))}</span>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </Page>
  );
};
