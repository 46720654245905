import React, { PropsWithChildren, useEffect, useState } from 'react';
import { StripePriceLookupKey } from '../../../generated/graphql';

export interface ChangePriceContextInterface {
  price?: StripePriceLookupKey;
  monthlyUsers?: number;
  setPrice: React.Dispatch<React.SetStateAction<StripePriceLookupKey | undefined>>;
  setMonthlyUsers: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export interface ChangePriceProviderProps {
  price?: StripePriceLookupKey;
  monthlyUsers?: number;
}

const ChangePriceContext = React.createContext<ChangePriceContextInterface>({} as any);

const ChangePriceProvider = (props: PropsWithChildren<ChangePriceProviderProps>) => {
  const [price, setPrice] = useState(props.price);
  const [monthlyUsers, setMonthlyUsers] = useState(props.monthlyUsers);

  useEffect(() => {
    setPrice(props.price);
  }, [props.price]);

  useEffect(() => {
    setMonthlyUsers(props.monthlyUsers);
  }, [props.monthlyUsers]);

  return <ChangePriceContext.Provider value={{ monthlyUsers, price, setMonthlyUsers, setPrice }} {...props} />;
};

function useChangePrice() {
  const context = React.useContext(ChangePriceContext);

  if (context === undefined) {
    throw new Error(`useChangePrice must be used within a ChangePriceContext`);
  }

  return context;
}

export { ChangePriceProvider, useChangePrice };
