import { useLocation, Outlet } from 'react-router-dom';
import React from 'react';
import { UserRole } from '../../../generated/graphql';
import { AddMember } from './Add';
import { AddMemberProvider } from './context';

export const AddMemberContainer = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailParam = searchParams.get('email') as string;
  const roleParam = searchParams.get('role') as string | undefined;
  let role = UserRole.Admin;
  if (roleParam) {
    switch ((roleParam as string).toUpperCase()) {
      case UserRole.Admin:
        role = UserRole.Admin;
        break;
      case UserRole.SuperAdmin:
        role = UserRole.SuperAdmin;
        break;
      default:
        role = UserRole.Admin;
    }
  }

  return (
    <AddMemberProvider email={emailParam} role={role}>
      <AddMember>
        <Outlet />
      </AddMember>
    </AddMemberProvider>
  );
};
