import React, { PropsWithChildren } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Toast } from '../components/react-toast-notifications/Toast';
import { Error } from '../pages/Error';
import { ScrollToTop } from '../components/ScrollToTop';
import { ApolloProvider } from './apollo-context';
import { AuthProvider } from './auth-context';
import { MixpanelProvider } from './mixpanel-context';
import { IntercomProvider } from './intercom-context';
import { UserProvider } from './user-context';

export const AppProviders = ({ children }: PropsWithChildren<any>) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: Boolean(process.env.REACT_APP_SENTRY_ENABLED),
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        // routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    release: process.env.COMMIT_REF,
    tracesSampleRate: 1.0,
  });

  return (
    <ToastProvider autoDismiss={true} autoDismissTimeout={3000} components={{ Toast }}>
      <ApolloProvider>
        <AuthProvider>
          <BrowserRouter>
            <ScrollToTop />
            <UserProvider>
              <IntercomProvider>
                <MixpanelProvider>
                  <Sentry.ErrorBoundary fallback={Error}>{children}</Sentry.ErrorBoundary>
                </MixpanelProvider>
              </IntercomProvider>
            </UserProvider>
          </BrowserRouter>
        </AuthProvider>
      </ApolloProvider>
    </ToastProvider>
  );
};
