/* eslint-disable no-await-in-loop */
import { UsersIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';
import moment from 'moment-timezone';
import React from 'react';
import Avatar from 'react-avatar';
import { Header, Link, Stats, Table, Button } from '@shuffl/tailwind-ui-react';

import {
  FeatureFlagKey,
  GetEventDefinitionQueryHookResult,
  GetUserEventDefinitionsQueryHookResult,
  UserEventDefinition,
  UserEventDefinitionOptState,
  UserEventDefinitionState,
  useSetOptStateMutation,
} from '../../../../generated/graphql';
import { useFeatureFlag } from '../../../../hooks/use-feature-flag';

export interface ChannelMembersProps {
  userEventDefinitionsQuery: GetUserEventDefinitionsQueryHookResult;
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;
}

export const ChannelMembers = ({ eventDefinitionQuery, userEventDefinitionsQuery }: ChannelMembersProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const userEventDefinitions = userEventDefinitionsQuery.data?.userEventDefinitionConnection.edges
    ?.map((edge) => edge?.node)
    .filter((userEventDefinition) => !!userEventDefinition) as UserEventDefinition[];

  const numberFormatter = new Intl.NumberFormat('en-US');
  const [setOptState] = useSetOptStateMutation();
  const biosFeatureFlag = useFeatureFlag({
    eventDefinitionId: eventDefinition?.id,
    key: FeatureFlagKey.SlackSendBioMessages,
  });

  const totalUsersInChannel =
    (eventDefinition?.latestSnapshot?.users.optedIn.total ?? 0) +
    (eventDefinition?.latestSnapshot?.users.optedOut.total ?? 0);

  return (
    <>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<UsersIcon className="h-5 w-5" />}
          stat={numberFormatter.format(totalUsersInChannel)}
          title="Members in Channel"
          // linkTo={`members`}
        />
      </div>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<PlusCircleIcon className="h-5 w-5" />}
          stat={numberFormatter.format(eventDefinition?.latestSnapshot?.users.optedIn.total ?? 0)}
          title="Members Opted In"
          // linkTo={`?opt-state=${UserEventDefinitionOptState.OptedIn}`}
        />
      </div>
      <div className="lg:col-span-4 lg:row-start-1">
        <Stats
          icon={<MinusCircleIcon className="h-5 w-5" />}
          stat={numberFormatter.format(eventDefinition?.latestSnapshot?.users.optedOut.total ?? 0)}
          title="Members Opted Out"
          // linkTo={`?opt-state=${UserEventDefinitionOptState.OptedOut}`}
        />
      </div>
      <div className="lg:col-span-12 lg:row-start-2">
        <Header type="h2" className="mb-2">
          Members in Channel
        </Header>
        <Table
          isEmpty={(userEventDefinitions?.length ?? 0) === 0}
          emptyText="No Members"
          stickyHeaders
          height="fixed"
          onAtBottom={() => {
            if (
              userEventDefinitionsQuery.data?.userEventDefinitionConnection.pageInfo.hasNextPage &&
              !userEventDefinitionsQuery.loading
            ) {
              userEventDefinitionsQuery.fetchMore({
                variables: {
                  after: userEventDefinitionsQuery.data?.userEventDefinitionConnection.pageInfo.endCursor,
                },
              });
            }
          }}
        >
          <Table.Header>
            <Table.Header.Cell>Name</Table.Header.Cell>
            <Table.Header.Cell>Last Shuffl</Table.Header.Cell>
            <Table.Header.Cell>Joined Channel</Table.Header.Cell>
            <Table.Header.Cell>Shuffls</Table.Header.Cell>
            {biosFeatureFlag.featureFlag?.isEnabled && <Table.Header.Cell>Shuffl Bio</Table.Header.Cell>}
            <Table.Header.Cell>Opt Status</Table.Header.Cell>
          </Table.Header>
          <Table.Body>
            {userEventDefinitions?.map((userEventDefinition, index) => {
              return (
                <Table.Row key={userEventDefinition.id ?? index}>
                  <Table.Cell>
                    <Link to={`/members/${userEventDefinition.user.id}`}>
                      <Avatar
                        email={userEventDefinition?.user.email ?? ''}
                        src={userEventDefinition?.user.slackMetadata?.image ?? ''}
                        alt={userEventDefinition?.user.name}
                        name={userEventDefinition?.user.name}
                        round={true}
                        size={'40'}
                        color="#3182CE"
                        className="mr-3"
                      />
                      <span>{userEventDefinition.user.name}</span>
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {userEventDefinition.lastShufflEvent && (
                      <Link to={`../history/${userEventDefinition.lastShufflEvent.id}`}>
                        {moment(userEventDefinition.lastShufflEvent.createdAt).format('ll')}
                      </Link>
                    )}
                    {!userEventDefinition.lastShufflEvent && <div>Never</div>}
                  </Table.Cell>
                  <Table.Cell> {moment(userEventDefinition.createdAt).format('ll')}</Table.Cell>
                  <Table.Cell>{userEventDefinition.totalShuffls}</Table.Cell>
                  {biosFeatureFlag.featureFlag?.isEnabled && (
                    <Table.Cell>
                      {userEventDefinition.user.isBioSet && (
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        </div>
                      )}
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    {userEventDefinition.state === UserEventDefinitionState.InEventDefinition &&
                      userEventDefinition.optState === UserEventDefinitionOptState.OptedIn && (
                        <Button
                          buttonStyle="secondary"
                          onClick={async () => {
                            await setOptState({
                              variables: {
                                optState: UserEventDefinitionOptState.OptedOut,
                                userEventDefinitionId: userEventDefinition.id,
                              },
                            });

                            await Promise.all([
                              userEventDefinitionsQuery.refetch(),
                              async () => {
                                let doesMatch = false;
                                const previousTotal = eventDefinition?.latestSnapshot?.users.optedOut.total;
                                do {
                                  const eventDefinitionRefetchQuery = await eventDefinitionQuery.refetch();
                                  doesMatch =
                                    eventDefinitionRefetchQuery.data.eventDefinition?.latestSnapshot?.users.optedOut
                                      .total !== previousTotal;
                                  if (!doesMatch) {
                                    await new Promise((resolve) => setTimeout(resolve, 500));
                                  }
                                } while (!doesMatch);
                              },
                            ]);
                          }}
                        >
                          Opt Out
                        </Button>
                      )}
                    {userEventDefinition.state === UserEventDefinitionState.InEventDefinition &&
                      userEventDefinition.optState === UserEventDefinitionOptState.OptedOut && (
                        <Button
                          buttonStyle="primary"
                          onClick={async () => {
                            await setOptState({
                              variables: {
                                optState: UserEventDefinitionOptState.OptedIn,
                                userEventDefinitionId: userEventDefinition.id,
                              },
                            });

                            await Promise.all([
                              userEventDefinitionsQuery.refetch(),
                              async () => {
                                let doesMatch = false;
                                const previousTotal = eventDefinition?.latestSnapshot?.users.optedIn.total;
                                do {
                                  const eventDefinitionRefetchQuery = await eventDefinitionQuery.refetch();
                                  doesMatch =
                                    eventDefinitionRefetchQuery.data.eventDefinition?.latestSnapshot?.users.optedIn
                                      .total !== previousTotal;
                                  if (!doesMatch) {
                                    await new Promise((resolve) => setTimeout(resolve, 500));
                                  }
                                } while (!doesMatch);
                              },
                            ]);
                          }}
                        >
                          Opt In
                        </Button>
                      )}
                    {userEventDefinition.state === UserEventDefinitionState.LeftEventDefinition && (
                      <span>Left Channel</span>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};
