import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

export const CommunityDetails = () => {
  return (
    <div className="flex flex-col">
      <div className="mb-4">Shuffl up to 50 members, regardless of team size.</div>
      <div className="flex flex-col w-full">
        <div className="text-gray-900 font-medium mb-2">Includes:</div>
        <div className="flex w-full">
          <ul className="lg:grid lg:grid-cols-2 w-full">
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Supports 1 Shuffl Channel</p>
            </li>

            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">1:1 pairs and 3 person group shuffls</p>
            </li>

            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Shuffl up to 4x a month</p>
            </li>

            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Random shuffl algorithm</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Configurable shuffl settings</p>
            </li>
            <li className="flex items-start lg:col-span-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              </div>
              <p className="ml-3 leading-6 text-gray-700">Bios and Intros</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
