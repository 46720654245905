import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';
import { RadioGroup } from '@shuffl/tailwind-ui-react';
import { SubmitButton } from '../../../../components/Form';
import { useUser } from '../../../../context/user-context';
import { UserRole, useUpsertUserMutation } from '../../../../generated/graphql';
import { useAddMember } from '../context';
import { FormData, RoleSchema } from './form-schema';

export const Role = () => {
  const { email, role } = useAddMember();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [upsertUser] = useUpsertUserMutation({
    onCompleted: async (data) => {
      addToast('Added new Admin', { appearance: 'success' });
      navigate(`/members/${data.upsertUser.id}`);
    },
    onError: (error) => {
      addToast('Could not add member', { appearance: 'error' });
      console.error('An error occurred trying to create a new member', error);
    },
  });
  const { isSuperAdmin } = useUser();

  useEffect(() => {
    if (!email) {
      navigate('/members/add/email', { replace: true });
    }
  });

  return (
    <Formik
      validationSchema={RoleSchema}
      initialValues={{ role }}
      enableReinitialize
      onSubmit={async (submitData: FormData) => {
        if (!email) {
          return;
        }

        await upsertUser({
          variables: {
            email,
            role: submitData.role as UserRole,
          },
        });
      }}
    >
      <Form>
        <div className="mb-8">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Role for the new Admin</h3>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">A member&apos;s role determines their permissions</p>
        </div>
        <div className="mb-8">
          <RadioGroup name="role" legend="Role">
            <RadioGroup.Item
              value={UserRole.Admin}
              label="Admin"
              subLabel="Admins have the ability to add other Admins and manage Shuffl channel settings"
            />
            <RadioGroup.Item
              value={UserRole.SuperAdmin}
              disabled={!isSuperAdmin}
              label="Super Admin"
              subLabel="Super Admins can perform all abilities granted to Admins and manage Billing settings"
            />
          </RadioGroup>
        </div>
        <SubmitButton alwaysEnabled>Add Admin</SubmitButton>
      </Form>
    </Formik>
  );
};
