import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** Moment date scalar type */
  MomentDate: any;
};

export type Audience = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  featureFlagRules?: Maybe<Array<FeatureFlagRule>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  conditions: AudienceCondition;
};

export type AudienceCondition = {
  and: Scalars['String'];
  or: Scalars['String'];
  not: Scalars['String'];
};

export type BaseEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type BillingPeriod = {
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
};

export type Connections = {
  new: TotalDelta;
  event: TotalDelta;
  eventDefinition: TotalDelta;
};

export type CreateUserInput = {
  role: UserRole;
  email: Scalars['String'];
};


/** DayOfWeek */
export enum DayOfWeek {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type DefaultTemplate = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: DefaultTemplateKey;
  template: Scalars['String'];
};

/** DefaultTemplateKey */
export enum DefaultTemplateKey {
  UserJoinedEventDefinitionMessageUserEphemeral = 'USER_JOINED_EVENT_DEFINITION_MESSAGE_USER_EPHEMERAL',
  ShufflExecutionMessageEdNextEvent = 'SHUFFL_EXECUTION_MESSAGE_ED_NEXT_EVENT',
  ShufflExecutionMessageEdNotEnoughUsers = 'SHUFFL_EXECUTION_MESSAGE_ED_NOT_ENOUGH_USERS',
  ShufflExecutionMessageEdSubscriptionEnforcement = 'SHUFFL_EXECUTION_MESSAGE_ED_SUBSCRIPTION_ENFORCEMENT',
  ShufflExecutionMessageGroup = 'SHUFFL_EXECUTION_MESSAGE_GROUP',
  ShufflExecutionDefaultMessageGroup = 'SHUFFL_EXECUTION_DEFAULT_MESSAGE_GROUP',
  ShufflExecutionDefaultMessageGroupV2 = 'SHUFFL_EXECUTION_DEFAULT_MESSAGE_GROUP_V2',
  ShufflExecutionMessageGroupV2 = 'SHUFFL_EXECUTION_MESSAGE_GROUP_V2',
  DefaultIntroMessage = 'DEFAULT_INTRO_MESSAGE',
  SetupBioMessageUserEphemeral = 'SETUP_BIO_MESSAGE_USER_EPHEMERAL',
  InviteBotUserMessage = 'INVITE_BOT_USER_MESSAGE',
  InviteBotAdminMessage = 'INVITE_BOT_ADMIN_MESSAGE',
  InviteBotSubscriptionEnforcementAdminMessage = 'INVITE_BOT_SUBSCRIPTION_ENFORCEMENT_ADMIN_MESSAGE',
  SayAnything = 'SAY_ANYTHING',
  OnboardingMessageEdAdminJoinEphemeral = 'ONBOARDING_MESSAGE_ED_ADMIN_JOIN_EPHEMERAL',
  OnboardingMessageEdCompleteAdminEphemeral = 'ONBOARDING_MESSAGE_ED_COMPLETE_ADMIN_EPHEMERAL',
  OnboardingMessageEdComplete = 'ONBOARDING_MESSAGE_ED_COMPLETE',
  MessageEdScheduleUpdated = 'MESSAGE_ED_SCHEDULE_UPDATED',
  MessageDowngradedEdTrialEnded = 'MESSAGE_DOWNGRADED_ED_TRIAL_ENDED',
  MessageEdDisabled = 'MESSAGE_ED_DISABLED',
  MessageDefaultEdTrialEnded = 'MESSAGE_DEFAULT_ED_TRIAL_ENDED'
}

export type EventDefinition = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  createdBy: User;
  updatedBy: User;
  slackMetadata?: Maybe<EventDefinitionSlackMetadata>;
  shufflStrategy: ShufflStrategy;
  createdFor: EventDefinitionCreatedFor;
  stateReason: EventDefinitionStateReason;
  state: EventDefinitionState;
  schedule: Schedule;
  notifications: Notifications;
  groupSize: Scalars['Float'];
  defaultTemplate: DefaultTemplate;
  tenantTemplate?: Maybe<TenantTemplate>;
  eventLastRunAt?: Maybe<Scalars['DateTime']>;
  isDefaultEventDefinition: Scalars['Boolean'];
  maxNumberOfShuffls: Scalars['Float'];
  maxGroupSize: Scalars['Float'];
  tags: Array<Scalars['String']>;
  eventSnapshots?: Maybe<Array<EventSnapshot>>;
  groups?: Maybe<Array<Group>>;
  shufflEvents?: Maybe<Array<ShufflEvent>>;
  userEventDefinitions?: Maybe<Array<UserEventDefinition>>;
  pairingAudits?: Maybe<Array<PairingAudit>>;
  groupUsers?: Maybe<Array<GroupUsers>>;
  snapshots?: Maybe<EventDefinitionSnapshot>;
  shufflStrategyToString: Scalars['String'];
  nextRunDateToString: Scalars['String'];
  nextRunDate: Scalars['MomentDate'];
  latestSnapshot?: Maybe<EventDefinitionSnapshot>;
  groupsCreatedTotal?: Maybe<Scalars['Int']>;
  nextShufflEvent?: Maybe<ShufflEvent>;
};


export type EventDefinitionShufflEventsArgs = {
  take?: Maybe<Scalars['Int']>;
};

export type EventDefinitionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventDefinitionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type EventDefinitionConnectionOrderArguments = {
  createdAt?: Maybe<OrderBy>;
};

export type EventDefinitionConnectionWhereArguments = {
  query?: Maybe<Scalars['String']>;
  state?: Maybe<EventDefinitionState>;
};

/** EventDefinitionCreatedFor */
export enum EventDefinitionCreatedFor {
  Cron = 'CRON',
  UserCommand = 'USER_COMMAND'
}

export type EventDefinitionEdge = {
  /** The item at the end of the edge. */
  node: EventDefinition;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type EventDefinitionSlackMetadata = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  eventDefinition: EventDefinition;
  channel: EventDefinitionSlackMetadataChannel;
};

export type EventDefinitionSlackMetadataChannel = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isOrgShared?: Maybe<Scalars['Boolean']>;
  isShared?: Maybe<Scalars['Boolean']>;
  creatorSlackId?: Maybe<Scalars['String']>;
  previousNames: Array<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  isGeneral?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type EventDefinitionSnapshot = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  eventDefinition: EventDefinition;
  groups: EventDefinitionSnapshotGroups;
  users: EventDefinitionSnapshotUsers;
  shufflEvents: TotalDelta;
  connections: EventDefinitionSnapshotConnections;
};

export type EventDefinitionSnapshotConnections = {
  total: TotalDelta;
  new: TotalDelta;
};

export type EventDefinitionSnapshotGroups = {
  created: TotalDelta;
};

export type EventDefinitionSnapshotUsers = {
  optedIn: TotalDelta;
  optedOut: TotalDelta;
  userAttributeBios: TotalDelta;
  shuffld: TotalDelta;
  shuffldUnique: TotalDelta;
  inEventDefinition: TotalDelta;
  leftEventDefinition: TotalDelta;
};

/** EventDefinitionState */
export enum EventDefinitionState {
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
  Enabled = 'ENABLED',
  PausedThroughPortal = 'PAUSED_THROUGH_PORTAL'
}

/** EventDefinitionStateReason */
export enum EventDefinitionStateReason {
  SlackChannelArchived = 'SLACK_CHANNEL_ARCHIVED',
  SlackChannelDeleted = 'SLACK_CHANNEL_DELETED',
  SlackChannelLeft = 'SLACK_CHANNEL_LEFT',
  UpdatedThroughPortal = 'UPDATED_THROUGH_PORTAL',
  AddedEnoughUsersInitial = 'ADDED_ENOUGH_USERS_INITIAL',
  AddedEnoughUsersOptIn = 'ADDED_ENOUGH_USERS_OPT_IN',
  AddedEnoughUsersExistingChannel = 'ADDED_ENOUGH_USERS_EXISTING_CHANNEL',
  NotEnoughUsersOptOut = 'NOT_ENOUGH_USERS_OPT_OUT',
  NotEnoughUsersInitial = 'NOT_ENOUGH_USERS_INITIAL',
  CanceledOnboarding = 'CANCELED_ONBOARDING',
  TenantUninstall = 'TENANT_UNINSTALL',
  TenantPaused = 'TENANT_PAUSED',
  TenantDisabled = 'TENANT_DISABLED',
  TenantEnabled = 'TENANT_ENABLED',
  TrialEnded = 'TRIAL_ENDED',
  TenantSubscriptionCanceled = 'TENANT_SUBSCRIPTION_CANCELED'
}

export type EventSnapshot = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  eventDefinition: EventDefinition;
  shufflEvent: ShufflEvent;
  maxNumberOfShuffls?: Maybe<Scalars['Float']>;
  maxNumberOfUsersToBeShuffled?: Maybe<Scalars['Int']>;
  isPaying?: Maybe<Scalars['Boolean']>;
  isTrialing?: Maybe<Scalars['Boolean']>;
  channelName: Scalars['String'];
  enforcedSubscriptionEnforcement?: Maybe<Scalars['Boolean']>;
  createdFor: EventDefinitionCreatedFor;
  shufflStrategy: ShufflStrategy;
  platform: TenantPlatform;
  groupSize: Scalars['Float'];
  schedule: Schedule;
  groups: Groups;
  users: Users;
  elapsedTimeToShufflUsersMs: Scalars['Float'];
  elapsedTimeToCompleteEventMs: Scalars['Float'];
  numberOfShuffls: Scalars['Float'];
  indexOfLowestWeightResult: Scalars['Float'];
  indexOfHighestNewConnectionResult: Scalars['Float'];
  connections: Connections;
  tags?: Maybe<Array<Scalars['String']>>;
  shufflStrategyToString: Scalars['String'];
};

export type FeatureFlag = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  rules?: Maybe<Array<FeatureFlagRule>>;
  key: FeatureFlagKey;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<FeatureFlagVariable>>;
  enabledState: FeatureFlagEnabledState;
  enabledStateReason: FeatureFlagEnabledStateReason;
};

/** FeatureFlagEnabledState */
export enum FeatureFlagEnabledState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

/** FeatureFlagEnabledStateReason */
export enum FeatureFlagEnabledStateReason {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

/** FeatureFlagKey */
export enum FeatureFlagKey {
  OnboardingV3 = 'ONBOARDING_V3',
  SlackMinimizeHomeTab = 'SLACK_MINIMIZE_HOME_TAB',
  SlackSendBioMessages = 'SLACK_SEND_BIO_MESSAGES',
  WebAppNewChannelDetails = 'WEB_APP_NEW_CHANNEL_DETAILS',
  SlackEventsRetry = 'SLACK_EVENTS_RETRY'
}

export type FeatureFlagRule = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  featureFlag: FeatureFlag;
  audienceCombinations: Array<Audience>;
  trafficPercent: Scalars['Int'];
  index: Scalars['Int'];
  variables?: Maybe<Array<FeatureFlagVariable>>;
  enabledState: FeatureFlagEnabledState;
  enabledStateReason: FeatureFlagEnabledStateReason;
};

export type FeatureFlagVariable = {
  type: Scalars['String'];
  key: FeatureFlagVariableKey;
  defaultValue: Scalars['String'];
};

/** FeatureFlagVariableKey */
export enum FeatureFlagVariableKey {
  HomeTabShowShufflBioButton = 'HOME_TAB_SHOW_SHUFFL_BIO_BUTTON',
  WebAppChannelDetailsNotifications = 'WEB_APP_CHANNEL_DETAILS_NOTIFICATIONS',
  WebAppChannelDetailsReporting = 'WEB_APP_CHANNEL_DETAILS_REPORTING'
}

/** Frequency */
export enum Frequency {
  EveryMinute = 'EVERY_MINUTE',
  EveryHour = 'EVERY_HOUR',
  EveryDay = 'EVERY_DAY',
  EveryWeek = 'EVERY_WEEK',
  EveryTwoWeeks = 'EVERY_TWO_WEEKS',
  EveryThreeWeeks = 'EVERY_THREE_WEEKS',
  EveryFourWeeks = 'EVERY_FOUR_WEEKS',
  EveryFiveWeeks = 'EVERY_FIVE_WEEKS',
  EverySixWeeks = 'EVERY_SIX_WEEKS',
  EveryMonth = 'EVERY_MONTH',
  StartOfEveryMonth = 'START_OF_EVERY_MONTH',
  EndOfEveryMonth = 'END_OF_EVERY_MONTH'
}

export type Group = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  shufflEvent: ShufflEvent;
  eventDefinition: EventDefinition;
  size: Scalars['Float'];
  weight: Scalars['Float'];
  connectionsTotal: Scalars['Float'];
  newConnections: Scalars['Float'];
  users?: Maybe<Array<GroupUsers>>;
  slackMetadata: GroupSlackMetadata;
  pairingAudits?: Maybe<Array<PairingAudit>>;
  didSendMessage: Scalars['Boolean'];
};

export type GroupConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type GroupConnectionOrderArguments = {
  createdAt?: Maybe<OrderBy>;
};

export type GroupConnectionWhereArguments = {
  shufflEventId?: Maybe<Scalars['String']>;
};

export type GroupEdge = {
  /** The item at the end of the edge. */
  node: Group;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type GroupSlackMetadata = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  group: Group;
  slackChannelId: Scalars['String'];
};

export type GroupUsers = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  shufflEvent: ShufflEvent;
  eventDefinition: EventDefinition;
  group: Group;
  user: User;
  isBioSet: Scalars['Boolean'];
  matchedTags?: Maybe<Array<Scalars['String']>>;
};

export type Groups = {
  sizes: StatisticsDelta;
  weights: StatisticsDelta;
  newConnections: StatisticsDelta;
  created: TotalDelta;
  delta: Scalars['Float'];
  total: Scalars['Float'];
};

export type MatchingFeatureFlag = {
  matchingRules?: Maybe<Array<FeatureFlagRule>>;
  matchingAudience?: Maybe<Array<Audience>>;
  key: FeatureFlagKey;
  isEnabled: Scalars['Boolean'];
  variables?: Maybe<Array<FeatureFlagVariable>>;
};

export type MessageToUser = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user: User;
  slackMetadata?: Maybe<MessageToUserSlackMetadata>;
  message: Scalars['String'];
};

export type MessageToUserSlackMetadata = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user: User;
  messageToUser: MessageToUser;
  messageId?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};


export type Mutation = {
  updateEventDefinition: EventDefinition;
  updateEventDefinitionSchedule: EventDefinition;
  updateEventDefinitionState: EventDefinition;
  updateEventDefinitionAddons: EventDefinition;
  updateEventDefinitionStrategy: EventDefinition;
  createDefaultEventDefinition: Scalars['Boolean'];
  rescheduleEventDefinitions: Scalars['Boolean'];
  updateOnboardingInstance: OnboardingInstance;
  updateTenantStripeCompanyName: StripeCustomer;
  updateTenantStripeSubscription: StripeSubscription;
  updateTenantStripePaymentMethod: StripePaymentMethod;
  logout: Scalars['Boolean'];
  updateUser: User;
  upsertUser: User;
  setOptState: UserEventDefinition;
  groupReport: Scalars['String'];
};


export type MutationUpdateEventDefinitionArgs = {
  data: UpdateEventDefinitionInput;
  eventDefinitionId: Scalars['ID'];
};


export type MutationUpdateEventDefinitionScheduleArgs = {
  data: UpdateEventDefinitionScheduleInput;
  eventDefinitionId: Scalars['ID'];
};


export type MutationUpdateEventDefinitionStateArgs = {
  data: UpdateEventDefinitionStateInput;
  eventDefinitionId: Scalars['ID'];
};


export type MutationUpdateEventDefinitionAddonsArgs = {
  data: UpdateEventDefinitionAddonsInput;
  eventDefinitionId: Scalars['ID'];
};


export type MutationUpdateEventDefinitionStrategyArgs = {
  data: UpdateEventDefinitionStrategyInput;
  eventDefinitionId: Scalars['ID'];
};


export type MutationUpdateOnboardingInstanceArgs = {
  data: UpdateOnboardingInstanceInput;
  stepId: OnboardingDefinitionStepId;
};


export type MutationUpdateTenantStripeCompanyNameArgs = {
  data: UpdateTenantStripeCompanyNameInput;
};


export type MutationUpdateTenantStripeSubscriptionArgs = {
  data: UpdateTenantStripeSubscriptionInput;
};


export type MutationUpdateTenantStripePaymentMethodArgs = {
  data: UpdateTenantStripePaymentMethodInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  userId: Scalars['ID'];
};


export type MutationUpsertUserArgs = {
  data: CreateUserInput;
};


export type MutationSetOptStateArgs = {
  data: UpdateUserEventDefinitionOptStateInput;
  userEventDefinitionId: Scalars['ID'];
};


export type MutationGroupReportArgs = {
  shufflEventId: Scalars['ID'];
};

export type NextScheduledTimeFromScheduleScheduleInput = {
  dayOfWeek?: Maybe<DayOfWeek>;
  frequency?: Maybe<Frequency>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  scheduledTime?: Maybe<Scalars['DateTime']>;
};

/** An object with a global ID. */
export type Node = {
  /** The global ID of the object. */
  id: Scalars['ID'];
};

export type Notifications = {
  sendMessageToChannel: Scalars['Boolean'];
  sendMessageToGroups: Scalars['Boolean'];
  sendBiosToGroups: Scalars['Boolean'];
};

export type OnboardingDefinition = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: OnboardingDefinitionName;
  description: Scalars['String'];
  steps: Array<OnboardingDefinitionStep>;
  onboardingInstances: Array<OnboardingInstance>;
};

/** OnboardingDefinitionName */
export enum OnboardingDefinitionName {
  OnboardingV2 = 'ONBOARDING_V2',
  OnboardingV3 = 'ONBOARDING_V3'
}

export type OnboardingDefinitionStep = {
  id: OnboardingDefinitionStepId;
  index: Scalars['Int'];
  isRequiredForCompletion: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

/** OnboardingDefinitionStepId */
export enum OnboardingDefinitionStepId {
  OnboardingV2AddToSlack = 'ONBOARDING_V2_ADD_TO_SLACK',
  OnboardingV2ConfigureChannel = 'ONBOARDING_V2_CONFIGURE_CHANNEL',
  OnboardingV2AddUserToChannel = 'ONBOARDING_V2_ADD_USER_TO_CHANNEL',
  OnboardingV2FireShufflEvent = 'ONBOARDING_V2_FIRE_SHUFFL_EVENT',
  OnboardingV3AddToSlack = 'ONBOARDING_V3_ADD_TO_SLACK',
  OnboardingV3StartOnboarding = 'ONBOARDING_V3_START_ONBOARDING',
  OnboardingV3SelectChannel = 'ONBOARDING_V3_SELECT_CHANNEL',
  OnboardingV3SelectMembers = 'ONBOARDING_V3_SELECT_MEMBERS',
  OnboardingV3SelectSchedule = 'ONBOARDING_V3_SELECT_SCHEDULE',
  OnboardingV3ConfirmSettings = 'ONBOARDING_V3_CONFIRM_SETTINGS',
  OnboardingV3SetupChannel = 'ONBOARDING_V3_SETUP_CHANNEL',
  OnboardingV3FireShufflEvent = 'ONBOARDING_V3_FIRE_SHUFFL_EVENT'
}

export type OnboardingInstance = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  current: Scalars['Boolean'];
  onboardingDefinition: OnboardingDefinition;
  tenant: Tenant;
  stepsProgress: Array<OnboardingInstanceStepsProgress>;
  executionId?: Maybe<Scalars['String']>;
  lastCompletedStep: OnboardingInstanceStepsProgress;
};

export type OnboardingInstanceStepsProgress = {
  index: Scalars['Int'];
  id: OnboardingDefinitionStepId;
  isCompleted: Scalars['Boolean'];
  completedBy?: Maybe<User>;
  completedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['String']>;
};

/** OrderBy */
export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating backwards, are there more items? */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** When paginating forwards, are there more items? */
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type Pairing = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user1: User;
  user2: User;
  weight: Scalars['Float'];
  xorIds: Scalars['String'];
  blocked: Scalars['Boolean'];
  pairingAudits: Array<Maybe<PairingAudit>>;
};

export type PairingAudit = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  pairing: Pairing;
  eventDefinition: EventDefinition;
  shufflEvent: ShufflEvent;
  group: Group;
  user1: User;
  user2: User;
  weight: Scalars['Float'];
};

export type Query = {
  featureFlag?: Maybe<MatchingFeatureFlag>;
  refreshToken?: Maybe<RefreshToken>;
  /** Fetches an object given its global ID. */
  node?: Maybe<Node>;
  /** Fetches objects given their global IDs. */
  nodes: Array<Maybe<Node>>;
  nextRunEventDefinition?: Maybe<EventDefinition>;
  eventDefinitionConnection: EventDefinitionConnection;
  eventDefinitions: Array<EventDefinition>;
  eventDefinition?: Maybe<EventDefinition>;
  defaultEventDefinition: EventDefinition;
  getNextScheduledTimeFromSchedule?: Maybe<Scalars['MomentDate']>;
  eventSnapshots: Array<EventSnapshot>;
  averageGroupWeight: Statistics;
  onboardingInstance?: Maybe<OnboardingInstance>;
  isOnboardingInstanceCompleted: Scalars['Boolean'];
  isOnboardingInstanceCompletedForPolling: Scalars['Boolean'];
  pairingAudits?: Maybe<Array<PairingAudit>>;
  slackConversations: SlackConversationConnection;
  slackConversationUsers: SlackConversationUsersConnection;
  slackUserGroups: Array<SlackUserGroup>;
  stripeCoupon?: Maybe<StripeCoupon>;
  stripeInvoices?: Maybe<Array<StripeInvoice>>;
  stripeUpcomingInvoice?: Maybe<StripeInvoice>;
  stripePrice?: Maybe<StripePrice>;
  stripePrices: Array<StripePrice>;
  stripeSubscription?: Maybe<StripeSubscription>;
  stripePaymentMethods?: Maybe<Array<StripePaymentMethod>>;
  groupConnection: GroupConnection;
  tenant: Tenant;
  profile: User;
  users: Array<User>;
  userConnection: UserConnection;
  adminConnection: UserConnection;
  user: User;
  userByEmail: SlackUserInfoResponse;
  highestUsageRecordForCurrentBillingPeriod?: Maybe<UsageRecord>;
  userEventDefinitions: Array<UserEventDefinition>;
  userEventDefinitionConnection: UserEventDefinitionConnection;
  shufflEvent?: Maybe<ShufflEvent>;
  shufflEvents: Array<ShufflEvent>;
  shufflEventConnection: ShufflEventConnection;
  pairingReportUrl: Scalars['String'];
};


export type QueryFeatureFlagArgs = {
  eventDefinitionId?: Maybe<Scalars['ID']>;
  key: FeatureFlagKey;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryEventDefinitionConnectionArgs = {
  where?: Maybe<EventDefinitionConnectionWhereArguments>;
  order?: Maybe<EventDefinitionConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEventDefinitionArgs = {
  eventDefinitionId: Scalars['ID'];
};


export type QueryGetNextScheduledTimeFromScheduleArgs = {
  schedule: NextScheduledTimeFromScheduleScheduleInput;
  eventDefinitionId: Scalars['ID'];
};


export type QueryEventSnapshotsArgs = {
  count?: Maybe<Scalars['Int']>;
  eventDefinitionId?: Maybe<Scalars['ID']>;
};


export type QueryAverageGroupWeightArgs = {
  endDate?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
};


export type QueryPairingAuditsArgs = {
  count?: Maybe<Scalars['Int']>;
  eventDefinitionId?: Maybe<Scalars['ID']>;
};


export type QuerySlackConversationsArgs = {
  where?: Maybe<SlackConversationConnectionWhereArguments>;
  order?: Maybe<SlackConversationConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySlackConversationUsersArgs = {
  where?: Maybe<SlackConversationUsersConnectionWhereArguments>;
  order?: Maybe<SlackConversationUsersConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStripeCouponArgs = {
  priceLookupKey?: Maybe<StripePriceLookupKey>;
  couponId?: Maybe<Scalars['String']>;
};


export type QueryStripePriceArgs = {
  priceLookupKey: StripePriceLookupKey;
};


export type QueryGroupConnectionArgs = {
  where?: Maybe<GroupConnectionWhereArguments>;
  order?: Maybe<GroupConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserConnectionArgs = {
  where?: Maybe<UserConnectionWhereArguments>;
  order?: Maybe<UserConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAdminConnectionArgs = {
  where?: Maybe<UserConnectionWhereArguments>;
  order?: Maybe<UserConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserEventDefinitionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  eventDefinitionId: Scalars['ID'];
};


export type QueryUserEventDefinitionConnectionArgs = {
  where?: Maybe<UserEventDefinitionConnectionWhereArguments>;
  order?: Maybe<UserEventDefinitionConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShufflEventArgs = {
  shufflEventId: Scalars['ID'];
};


export type QueryShufflEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  eventDefinitionId: Scalars['ID'];
};


export type QueryShufflEventConnectionArgs = {
  where?: Maybe<ShufflEventConnectionWhereArguments>;
  order?: Maybe<ShufflEventConnectionOrderArguments>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPairingReportUrlArgs = {
  shufflEventId?: Maybe<Scalars['ID']>;
};

export type RefreshToken = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user: User;
  impersonatedBy?: Maybe<User>;
  enabled: Scalars['Boolean'];
  expiresAt: Scalars['DateTime'];
  lastRefreshAt?: Maybe<Scalars['DateTime']>;
  lastUsedAt?: Maybe<Scalars['DateTime']>;
  isAuthenticated: Scalars['Boolean'];
};

export type Schedule = {
  timezone: Scalars['String'];
  frequency: Frequency;
  dayOfWeek: DayOfWeek;
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  secondOfMinute: Scalars['Int'];
  frequencyToString: Scalars['String'];
  dayOfWeekToNumber: Scalars['Int'];
  scheduleToString: Scalars['String'];
  momentSchedule: Scalars['MomentDate'];
};

export type ShufflEvent = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  eventDefinition: EventDefinition;
  eventSnapshot?: Maybe<EventSnapshot>;
  pairingAudits?: Maybe<Array<PairingAudit>>;
  scheduledTime: Scalars['DateTime'];
  executedEndAt?: Maybe<Scalars['DateTime']>;
  executedStartAt?: Maybe<Scalars['DateTime']>;
  isPreview: Scalars['Boolean'];
  executionId?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  stateReason: ShufflEventStateReason;
  state: ShufflEventState;
  notifications: Notifications;
  groupUsers?: Maybe<Array<GroupUsers>>;
  newPairingAudits?: Maybe<Array<PairingAudit>>;
  groups?: Maybe<Array<Group>>;
};

export type ShufflEventConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShufflEventEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type ShufflEventConnectionOrderArguments = {
  createdAt?: Maybe<OrderBy>;
};

export type ShufflEventConnectionWhereArguments = {
  eventDefinitionId?: Maybe<Scalars['String']>;
  state?: Maybe<ShufflEventState>;
};

export type ShufflEventEdge = {
  /** The item at the end of the edge. */
  node: ShufflEvent;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** ShufflEventState */
export enum ShufflEventState {
  Scheduled = 'SCHEDULED',
  Running = 'RUNNING',
  Canceled = 'CANCELED',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}

/** ShufflEventStateReason */
export enum ShufflEventStateReason {
  Failed = 'FAILED',
  CanceledByTenantUninstall = 'CANCELED_BY_TENANT_UNINSTALL',
  StartedFromScheduler = 'STARTED_FROM_SCHEDULER',
  FailedToMessageUsers = 'FAILED_TO_MESSAGE_USERS',
  CanceledByScheduler = 'CANCELED_BY_SCHEDULER',
  CanceledByManually = 'CANCELED_BY_MANUALLY',
  ScheduledByScheduler = 'SCHEDULED_BY_SCHEDULER',
  ScheduledManually = 'SCHEDULED_MANUALLY',
  ScheduledByOnboardingComplete = 'SCHEDULED_BY_ONBOARDING_COMPLETE',
  RescheduledByEventDefinitionUpdate = 'RESCHEDULED_BY_EVENT_DEFINITION_UPDATE',
  ScheduledByEventDefinitionUpdate = 'SCHEDULED_BY_EVENT_DEFINITION_UPDATE',
  ScheduledByEventDefinitionCreated = 'SCHEDULED_BY_EVENT_DEFINITION_CREATED',
  CanceledByEventDefinitionUpdate = 'CANCELED_BY_EVENT_DEFINITION_UPDATE',
  CanceledByEventDefinitionOnboardingComplete = 'CANCELED_BY_EVENT_DEFINITION_ONBOARDING_COMPLETE',
  CanceledByEventDefinitionDisabled = 'CANCELED_BY_EVENT_DEFINITION_DISABLED',
  CanceledByEventDefinitionDeleted = 'CANCELED_BY_EVENT_DEFINITION_DELETED',
  CanceledByTenantSubscriptionCanceled = 'CANCELED_BY_TENANT_SUBSCRIPTION_CANCELED',
  Succeeded = 'SUCCEEDED',
  SucceededWithSubscriptionEnforcement = 'SUCCEEDED_WITH_SUBSCRIPTION_ENFORCEMENT',
  Unknown = 'UNKNOWN'
}

/** ShufflStrategy */
export enum ShufflStrategy {
  Random = 'RANDOM',
  GroupFrequencyWeighted = 'GROUP_FREQUENCY_WEIGHTED',
  GroupFrequencyWeightedV2 = 'GROUP_FREQUENCY_WEIGHTED_V2',
  SingleTagWeighted = 'SINGLE_TAG_WEIGHTED',
  SingleTagWeightedOneTagPerGroup = 'SINGLE_TAG_WEIGHTED_ONE_TAG_PER_GROUP',
  SingleTagWeightedMultipleTagsPerGroup = 'SINGLE_TAG_WEIGHTED_MULTIPLE_TAGS_PER_GROUP',
  LeaderLotteryWeighted = 'LEADER_LOTTERY_WEIGHTED'
}

export type SlackBot = {
  id?: Maybe<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type SlackConversationConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SlackConversationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type SlackConversationConnectionOrderArguments = {
  name?: Maybe<OrderBy>;
  memberCount?: Maybe<OrderBy>;
};

export type SlackConversationConnectionWhereArguments = {
  name?: Maybe<Scalars['String']>;
};

export type SlackConversationEdge = {
  /** The item at the end of the edge. */
  node: SlackConversationsInfoResponse;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type SlackConversationUsersConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SlackConversationUsersEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type SlackConversationUsersConnectionOrderArguments = {
  name?: Maybe<OrderBy>;
};

export type SlackConversationUsersConnectionWhereArguments = {
  conversationId?: Maybe<Scalars['String']>;
};

export type SlackConversationUsersEdge = {
  /** The item at the end of the edge. */
  node: User;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type SlackConversationsInfoResponse = {
  numberOfMembers: Scalars['Float'];
  name: Scalars['String'];
  id: Scalars['ID'];
  purpose: Scalars['String'];
  topic: Scalars['String'];
};

export type SlackEnterprise = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  migrationStartAt?: Maybe<Scalars['DateTime']>;
  migrationFinishedAt?: Maybe<Scalars['DateTime']>;
};

export type SlackPermissions = {
  createConversation?: Maybe<Scalars['Boolean']>;
  archiveConversation?: Maybe<Scalars['Boolean']>;
};

export type SlackTeam = {
  id: Scalars['String'];
  name: Scalars['String'];
  domain?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type SlackUserGroup = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  userCount: Scalars['Float'];
  isExternal: Scalars['Boolean'];
};

export type SlackUserInfoResponse = {
  id: Scalars['String'];
};

export type Statistics = {
  max: Scalars['Float'];
  mean: Scalars['Float'];
  min: Scalars['Float'];
  p50: Scalars['Float'];
  p99: Scalars['Float'];
};

export type StatisticsDelta = {
  max: Scalars['Float'];
  mean: Scalars['Float'];
  min: Scalars['Float'];
  p50: Scalars['Float'];
  p99: Scalars['Float'];
  maxDelta: Scalars['Float'];
  meanDelta: Scalars['Float'];
  minDelta: Scalars['Float'];
  p50Delta: Scalars['Float'];
  p99Delta: Scalars['Float'];
};

export type StripeCoupon = {
  id: Scalars['String'];
  amountOff?: Maybe<Scalars['Int']>;
  created: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
  duration: Scalars['String'];
  durationInMonths?: Maybe<Scalars['Int']>;
  maxRedemptions?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percentOff?: Maybe<Scalars['Float']>;
  redeemBy?: Maybe<Scalars['Int']>;
  timesRedeemed: Scalars['Int'];
  valid: Scalars['Boolean'];
};

export type StripeCustomer = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type StripeInvoice = {
  id: Scalars['String'];
  amountDue: Scalars['Int'];
  amountRemaining: Scalars['Int'];
  usageAmount: Scalars['Int'];
  amountPaid: Scalars['Int'];
  last4?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  paid: Scalars['Boolean'];
  isExcessCharge: Scalars['Boolean'];
  isAnnualPayment: Scalars['Boolean'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  invoicePdf?: Maybe<Scalars['String']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  usageRecord?: Maybe<UsageRecord>;
};

export type StripePaymentMethod = {
  id: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  billingAddressCity?: Maybe<Scalars['String']>;
  billingAddressCountry?: Maybe<Scalars['String']>;
  billingAddressLine1?: Maybe<Scalars['String']>;
  billingAddressLine2?: Maybe<Scalars['String']>;
  billingAddressPostalCode?: Maybe<Scalars['String']>;
  billingAddressState?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingName?: Maybe<Scalars['String']>;
  billingPhone?: Maybe<Scalars['String']>;
};

export type StripePrice = {
  stripeLookupKey: Scalars['String'];
  lookupKey: StripePriceLookupKey;
  version: StripePriceVersion;
  nickname: Scalars['String'];
  id: Scalars['String'];
  trialInDays?: Maybe<Scalars['Int']>;
  interval: Scalars['String'];
  currency: Scalars['String'];
  active: Scalars['Boolean'];
  amount?: Maybe<Scalars['Int']>;
  metadata: StripePriceMetadata;
  tiers?: Maybe<Array<StripePriceTiers>>;
};

export enum StripePriceLookupKey {
  CommunityDeprecated = 'COMMUNITY_DEPRECATED',
  Community = 'COMMUNITY',
  GrowthMonthly = 'GROWTH_MONTHLY',
  GrowthPlusMonthly = 'GROWTH_PLUS_MONTHLY',
  GrowthPlusYearly = 'GROWTH_PLUS_YEARLY',
  ProMonthly = 'PRO_MONTHLY',
  ProYearly = 'PRO_YEARLY'
}

export type StripePriceMetadata = {
  tier: Scalars['Int'];
  minUserLimit: Scalars['Int'];
  maxUserLimit: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type StripePriceTier = {
  max: Scalars['Int'];
  min: Scalars['Int'];
  tier: StripePriceTiers;
};

export type StripePriceTiers = {
  upTo?: Maybe<Scalars['Int']>;
  flatAmount?: Maybe<Scalars['Int']>;
};

export enum StripePriceVersion {
  V_2020_10 = 'V_2020_10',
  V_2021_07 = 'V_2021_07'
}

export type StripeSubscription = {
  id: Scalars['String'];
  currentPeriodEnd: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  items: StripeSubscriptionItemList;
  price?: Maybe<StripePrice>;
  usageAmount?: Maybe<Scalars['Int']>;
  prePaidMemberQuantity?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  isTrialing: Scalars['Boolean'];
  priceTier?: Maybe<StripePriceTier>;
  cost?: Maybe<Scalars['Int']>;
  prePaidCost?: Maybe<Scalars['Int']>;
};

export type StripeSubscriptionItem = {
  id: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  subscription: Scalars['String'];
  price: StripePrice;
};

export type StripeSubscriptionItemList = {
  data: Array<StripeSubscriptionItem>;
};

export type Tenant = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  trialStartAt?: Maybe<Scalars['DateTime']>;
  trialEndAt?: Maybe<Scalars['DateTime']>;
  payingStartAt?: Maybe<Scalars['DateTime']>;
  payingEndAt?: Maybe<Scalars['DateTime']>;
  price: StripePriceLookupKey;
  priceVersion: StripePriceVersion;
  shufflEvents?: Maybe<Array<ShufflEvent>>;
  trialingStateReason?: Maybe<TenantStateReason>;
  lastNewConnectionAt?: Maybe<Scalars['DateTime']>;
  timezone: Scalars['String'];
  stateReason: TenantStateReason;
  creationSource?: Maybe<TenantCreationSource>;
  state: TenantState;
  platform: TenantPlatform;
  updatedBy?: Maybe<User>;
  users?: Maybe<Array<User>>;
  eventDefinitions?: Maybe<Array<EventDefinition>>;
  eventSnapshots?: Maybe<Array<EventSnapshot>>;
  groups?: Maybe<Array<Group>>;
  pairings?: Maybe<Array<Pairing>>;
  tenantTemplates?: Maybe<Array<TenantTemplate>>;
  refreshTokens?: Maybe<Array<RefreshToken>>;
  userAudits?: Maybe<Array<UserAudit>>;
  onboardingInstances?: Maybe<Array<OnboardingInstance>>;
  userAuditEventDefinitions?: Maybe<Array<UserEventDefinitionAudit>>;
  userEventDefinitions?: Maybe<Array<UserEventDefinition>>;
  eventDefinitionSlackMetadatas?: Maybe<EventDefinitionSlackMetadata>;
  userSlackMetadatas?: Maybe<UserSlackMetadata>;
  messagesToUsers?: Maybe<MessageToUser>;
  messageToUserSlackMetadatas?: Maybe<MessageToUserSlackMetadata>;
  groupSlackMetadatas?: Maybe<GroupSlackMetadata>;
  usageRecords?: Maybe<UsageRecord>;
  pairingAudits?: Maybe<PairingAudit>;
  groupUsers?: Maybe<Array<GroupUsers>>;
  userAttributes?: Maybe<Array<UserAttribute>>;
  snapshots?: Maybe<TenantSnapshot>;
  eventDefinitionSnapshots?: Maybe<EventDefinitionSnapshot>;
  slackMetadata?: Maybe<TenantSlackMetadata>;
  currentBillingPeriod: BillingPeriod;
  previousBillingPeriod?: Maybe<BillingPeriod>;
  isPaying: Scalars['Boolean'];
  isEligibleForTrial: Scalars['Boolean'];
  hasTrialed: Scalars['Boolean'];
  isTrialing: Scalars['Boolean'];
  isPayingOrTrialing: Scalars['Boolean'];
  createdBy?: Maybe<User>;
  stripeSubscription?: Maybe<StripeSubscription>;
  onboardingInstance: OnboardingInstance;
  nextRunEventDefinition?: Maybe<EventDefinition>;
};

/** TenantCreationSource */
export enum TenantCreationSource {
  SlackDirectory = 'SLACK_DIRECTORY',
  MarketingSite = 'MARKETING_SITE',
  Portal = 'PORTAL'
}

/** TenantPlatform */
export enum TenantPlatform {
  Slack = 'SLACK',
  MsTeams = 'MS_TEAMS',
  Discord = 'DISCORD',
  Facebook = 'FACEBOOK'
}

export type TenantSlackMetadata = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  bot: SlackBot;
  team: SlackTeam;
  enterprise?: Maybe<SlackEnterprise>;
  appId?: Maybe<Scalars['String']>;
  tokenType: Scalars['String'];
  permissions: SlackPermissions;
};

export type TenantSnapshot = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  groups: TenantSnapshotGroups;
  users: TenantSnapshotUsers;
  eventDefinitions: TenantSnapshotEventDefinitions;
  shufflEvents: TotalDelta;
  connections: TenantSnapshotConnections;
};

export type TenantSnapshotConnections = {
  total: TotalDelta;
  new: TotalDelta;
};

export type TenantSnapshotEventDefinitions = {
  enabled: TotalDelta;
  disabled: TotalDelta;
};

export type TenantSnapshotGroups = {
  created: TotalDelta;
};

export type TenantSnapshotUsers = {
  total: TotalDelta;
  optedIn: TotalDelta;
  optedOut: TotalDelta;
  userAttributeBios: TotalDelta;
  shuffled: TotalDelta;
  shuffledUnique: TotalDelta;
  inEventDefinition: TotalDelta;
  leftEventDefinition: TotalDelta;
  admins: TotalDelta;
};

/** TenantState */
export enum TenantState {
  Enabled = 'ENABLED',
  ExceededSubscription = 'EXCEEDED_SUBSCRIPTION',
  Disabled = 'DISABLED',
  Paused = 'PAUSED'
}

/** TenantStateReason */
export enum TenantStateReason {
  Delinquent = 'DELINQUENT',
  TenantUninstall = 'TENANT_UNINSTALL',
  TenantPaused = 'TENANT_PAUSED',
  TenantDisabled = 'TENANT_DISABLED',
  TenantEnabled = 'TENANT_ENABLED',
  TenantReInstalled = 'TENANT_RE_INSTALLED'
}

export type TenantTemplate = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  createdBy: User;
  updatedBy: User;
  eventDefinition: EventDefinition;
  name: DefaultTemplateKey;
  template: Scalars['String'];
};

export type TotalDelta = {
  total: Scalars['Float'];
  totalDelta: Scalars['Float'];
};

export type UpdateEventDefinitionAddonsInput = {
  sendBiosToGroups: Scalars['Boolean'];
};

export type UpdateEventDefinitionInput = {
  groupSize: Scalars['Int'];
  template?: Maybe<Scalars['String']>;
};

export type UpdateEventDefinitionScheduleInput = {
  frequency: Frequency;
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  timezone: Scalars['String'];
  scheduledTime: Scalars['DateTime'];
};

export type UpdateEventDefinitionStateInput = {
  state: EventDefinitionState;
};

export type UpdateEventDefinitionStrategyInput = {
  shufflStrategy: ShufflStrategy;
};

export type UpdateOnboardingInstanceInput = {
  metadata?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
};

export type UpdateTenantStripeCompanyNameInput = {
  companyName: Scalars['String'];
};

export type UpdateTenantStripePaymentMethodInput = {
  paymentMethodId: Scalars['String'];
};

export type UpdateTenantStripeSubscriptionInput = {
  priceLookupKey: StripePriceLookupKey;
};

export type UpdateUserEventDefinitionOptStateInput = {
  optState: UserEventDefinitionOptState;
};

export type UpdateUserInput = {
  role: UserRole;
};

export type UsageRecord = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  memberQuantity: Scalars['Float'];
  numberOfEventDefinitions: Scalars['Float'];
  numberOfShufflEvents: Scalars['Float'];
  priceTierMin?: Maybe<Scalars['Int']>;
  priceTierMax?: Maybe<Scalars['Int']>;
  priceExcessTierMin?: Maybe<Scalars['Int']>;
  priceExcessTierMax?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  excessCost?: Maybe<Scalars['Int']>;
  prePaidMemberQuantity?: Maybe<Scalars['Int']>;
  prePaidCost?: Maybe<Scalars['Int']>;
  billingPeriodEnd: Scalars['DateTime'];
  billingPeriodStart: Scalars['DateTime'];
  isTrialing: Scalars['Boolean'];
  price: StripePriceLookupKey;
  priceVersion: StripePriceVersion;
  idempotencyKey: Scalars['String'];
  excessMemberQuantity: Scalars['Int'];
  reportUrl?: Maybe<Scalars['String']>;
};

export type User = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  slackMetadata?: Maybe<UserSlackMetadata>;
  role: UserRole;
  creationSource: UserCreationSource;
  stateReason: UserStateReason;
  state: UserState;
  timezone: Scalars['String'];
  lastShufflAt?: Maybe<Scalars['DateTime']>;
  lastNewConnectionAt?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Scalars['String']>>;
  pairings1?: Maybe<Array<Pairing>>;
  pairings2?: Maybe<Array<Pairing>>;
  groups?: Maybe<Array<GroupUsers>>;
  refreshTokens?: Maybe<Array<RefreshToken>>;
  impersonatedRefreshTokens?: Maybe<Array<RefreshToken>>;
  userAudits?: Maybe<Array<UserAudit>>;
  tenantTemplatesCreated?: Maybe<Array<TenantTemplate>>;
  tenantTemplatesUpdated?: Maybe<Array<TenantTemplate>>;
  tenantsCreated?: Maybe<Array<Tenant>>;
  tenantsUpdated?: Maybe<Array<Tenant>>;
  messagesToUser?: Maybe<MessageToUser>;
  messageToUserSlackMetadatas?: Maybe<MessageToUserSlackMetadata>;
  eventDefinitionsCreated?: Maybe<Array<EventDefinition>>;
  eventDefinitionsUpdated?: Maybe<Array<EventDefinition>>;
  userEventDefinitions?: Maybe<Array<UserEventDefinition>>;
  userAuditEventDefinitions?: Maybe<Array<UserEventDefinitionAudit>>;
  pairingAudits1?: Maybe<Array<PairingAudit>>;
  pairingAudits2?: Maybe<Array<PairingAudit>>;
  userAttributes?: Maybe<Array<UserAttribute>>;
  impersonatedBy?: Maybe<User>;
  name: Scalars['String'];
  intercomHashId?: Maybe<Scalars['String']>;
  isBioSet?: Maybe<Scalars['Boolean']>;
};

export type UserAttribute = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user: User;
  key: UserAttributeKey;
  value: Scalars['String'];
  valueEncrypted: Scalars['String'];
};

/** UserAttributeKey */
export enum UserAttributeKey {
  Intro = 'INTRO',
  Pronoun = 'PRONOUN',
  Location = 'LOCATION',
  Interest = 'INTEREST',
  Title = 'TITLE',
  Goal = 'GOAL'
}

export type UserAudit = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user: User;
  eventName: Scalars['String'];
};

export type UserConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type UserConnectionOrderArguments = {
  createdAt?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

export type UserConnectionWhereArguments = {
  role?: Maybe<UserRole>;
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
};

/** UserCreationSource */
export enum UserCreationSource {
  Portal = 'PORTAL',
  SlackInstall = 'SLACK_INSTALL',
  ShufflEvent = 'SHUFFL_EVENT',
  OnboardingSelectChannel = 'ONBOARDING_SELECT_CHANNEL',
  SlackAppMessage = 'SLACK_APP_MESSAGE',
  SlackOauth = 'SLACK_OAUTH',
  SlackAppHome = 'SLACK_APP_HOME',
  SlackMemberChannelJoin = 'SLACK_MEMBER_CHANNEL_JOIN',
  SlackBotChannelJoin = 'SLACK_BOT_CHANNEL_JOIN',
  EventDefinitionUpdated = 'EVENT_DEFINITION_UPDATED',
  SlackUserChangeEvent = 'SLACK_USER_CHANGE_EVENT'
}

export type UserEdge = {
  /** The item at the end of the edge. */
  node: User;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type UserEventDefinition = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  eventDefinition: EventDefinition;
  user: User;
  stateReason: UserEventDefinitionStateReason;
  state: UserEventDefinitionState;
  optStateReason: UserEventDefinitionOptStateReason;
  optState: UserEventDefinitionOptState;
  connectionsTotal?: Maybe<Scalars['Int']>;
  lastShufflEvent?: Maybe<ShufflEvent>;
  totalShuffls?: Maybe<Scalars['Int']>;
};

export type UserEventDefinitionAudit = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  eventDefinition: EventDefinition;
  user: User;
  userEventDefinition: UserEventDefinition;
  stateReason: UserEventDefinitionStateReason;
  state: UserEventDefinitionState;
  optStateReason: UserEventDefinitionOptStateReason;
  optState: UserEventDefinitionOptState;
};

export type UserEventDefinitionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEventDefinitionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type UserEventDefinitionConnectionOrderArguments = {
  createdAt?: Maybe<OrderBy>;
  firstName?: Maybe<OrderBy>;
};

export type UserEventDefinitionConnectionWhereArguments = {
  eventDefinitionId?: Maybe<Scalars['String']>;
};

export type UserEventDefinitionEdge = {
  /** The item at the end of the edge. */
  node: UserEventDefinition;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** UserEventDefinitionOptState */
export enum UserEventDefinitionOptState {
  OptedIn = 'OPTED_IN',
  OptedOut = 'OPTED_OUT'
}

/** UserEventDefinitionOptStateReason */
export enum UserEventDefinitionOptStateReason {
  OptedInFromShufflEvent = 'OPTED_IN_FROM_SHUFFL_EVENT',
  OptedInFromHomeTab = 'OPTED_IN_FROM_HOME_TAB',
  OptedInFromPortal = 'OPTED_IN_FROM_PORTAL',
  OptedOutFromPortal = 'OPTED_OUT_FROM_PORTAL',
  OptedInFromEventDefinitionCreated = 'OPTED_IN_FROM_EVENT_DEFINITION_CREATED',
  OptedInFromEventDefinitionUpdated = 'OPTED_IN_FROM_EVENT_DEFINITION_UPDATED',
  OptedOutFromHomeTab = 'OPTED_OUT_FROM_HOME_TAB',
  OptedInFromChannelJoin = 'OPTED_IN_FROM_CHANNEL_JOIN',
  OptedOutFromChannelLeave = 'OPTED_OUT_FROM_CHANNEL_LEAVE',
  OptedOutFromDeletedFromSlack = 'OPTED_OUT_FROM_DELETED_FROM_SLACK',
  IsBot = 'IS_BOT'
}

/** UserEventDefinitionState */
export enum UserEventDefinitionState {
  InEventDefinition = 'IN_EVENT_DEFINITION',
  LeftEventDefinition = 'LEFT_EVENT_DEFINITION'
}

/** UserEventDefinitionStateReason */
export enum UserEventDefinitionStateReason {
  JoinedEventDefinition = 'JOINED_EVENT_DEFINITION',
  LeftEventDefinition = 'LEFT_EVENT_DEFINITION',
  EventDefinitionDisabled = 'EVENT_DEFINITION_DISABLED',
  EventDefinitionDeleted = 'EVENT_DEFINITION_DELETED',
  TenantUninstall = 'TENANT_UNINSTALL'
}

/** UserRole */
export enum UserRole {
  Bot = 'BOT',
  User = 'USER',
  InternalSuperAdmin = 'INTERNAL_SUPER_ADMIN',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN'
}

export type UserSlackMetadata = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isInternal?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  user: User;
  slackUserId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  realName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  skype?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isBot: Scalars['Boolean'];
  isAppUser: Scalars['Boolean'];
  isPrimaryOwner: Scalars['Boolean'];
  isRestricted: Scalars['Boolean'];
  isUltraRestricted: Scalars['Boolean'];
  isStranger: Scalars['Boolean'];
  isInvitedUser: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  scopes?: Maybe<Array<Scalars['String']>>;
};

/** UserState */
export enum UserState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Paused = 'PAUSED'
}

/** UserStateReason */
export enum UserStateReason {
  IsBot = 'IS_BOT',
  UserPaused = 'USER_PAUSED',
  UserDisabled = 'USER_DISABLED',
  UserDeletedFromSlack = 'USER_DELETED_FROM_SLACK',
  UserEnabled = 'USER_ENABLED',
  UserCreatedFromEventDefinitionCreated = 'USER_CREATED_FROM_EVENT_DEFINITION_CREATED',
  UserCreatedFromOnboardingSelectChannel = 'USER_CREATED_FROM_ONBOARDING_SELECT_CHANNEL',
  UserCreatedFromEventDefinitionUpdated = 'USER_CREATED_FROM_EVENT_DEFINITION_UPDATED',
  UserCreatedFromShufflEvent = 'USER_CREATED_FROM_SHUFFL_EVENT',
  TenantUninstall = 'TENANT_UNINSTALL',
  TenantPaused = 'TENANT_PAUSED',
  TenantDisabled = 'TENANT_DISABLED',
  TenantEnabled = 'TENANT_ENABLED'
}

export type Users = {
  optedIn: TotalDelta;
  optedOut: TotalDelta;
  bios: TotalDelta;
  shuffled: TotalDelta;
  redistributedNew: TotalDelta;
  inEventDefinition: TotalDelta;
  redistributed: Scalars['Float'];
  delta: Scalars['Float'];
  total: Scalars['Float'];
  inChannel: Scalars['Float'];
};

export type GetRefreshTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRefreshTokenQuery = { refreshToken?: Maybe<{ id: string, isAuthenticated: boolean }> };

export type UpdateEventDefinitionMutationVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  groupSize: Scalars['Int'];
  template?: Maybe<Scalars['String']>;
}>;


export type UpdateEventDefinitionMutation = { updateEventDefinition: { id: string } };

export type UpdateEventDefinitionScheduleMutationVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  frequency: Frequency;
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  timezone: Scalars['String'];
  scheduledTime: Scalars['DateTime'];
}>;


export type UpdateEventDefinitionScheduleMutation = { updateEventDefinitionSchedule: { id: string } };

export type UpdateEventDefinitionStateMutationVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  state: EventDefinitionState;
}>;


export type UpdateEventDefinitionStateMutation = { updateEventDefinitionState: { id: string } };

export type UpdateEventDefinitionStrategyMutationVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  shufflStrategy: ShufflStrategy;
}>;


export type UpdateEventDefinitionStrategyMutation = { updateEventDefinitionStrategy: { id: string } };

export type UpdateEventDefinitionAddonsMutationVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  sendBiosToGroups: Scalars['Boolean'];
}>;


export type UpdateEventDefinitionAddonsMutation = { updateEventDefinitionAddons: { id: string } };

export type RescheduleEventDefinitionsMutationVariables = Exact<{ [key: string]: never; }>;


export type RescheduleEventDefinitionsMutation = { rescheduleEventDefinitions: boolean };

export type CreateDefaultEventDefinitionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateDefaultEventDefinitionMutation = { createDefaultEventDefinition: boolean };

export type GetNextRunEventDefinitionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNextRunEventDefinitionQuery = { nextRunEventDefinition?: Maybe<{ id: string, shufflStrategy: ShufflStrategy, groupSize: number, nextRunDateToString: string, state: EventDefinitionState, nextRunDate: any, nextShufflEvent?: Maybe<{ id: string, scheduledTime: Date }>, schedule: { scheduleToString: string }, slackMetadata?: Maybe<{ id: string, channel: { name?: Maybe<string>, id: string } }> }> };

export type GetDefaultEventDefinitionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultEventDefinitionQuery = { defaultEventDefinition: { id: string, shufflStrategy: ShufflStrategy, groupSize: number, nextRunDate: any, slackMetadata?: Maybe<{ id: string, channel: { name?: Maybe<string>, id: string } }>, schedule: { scheduleToString: string } } };

export type GetAllEventDefinitionsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  state?: Maybe<EventDefinitionState>;
  createdAtOrder?: Maybe<OrderBy>;
}>;


export type GetAllEventDefinitionsQuery = { eventDefinitionConnection: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { nextRunDate: any, isDefaultEventDefinition: boolean, id: string, state: EventDefinitionState, groupSize: number, shufflStrategyToString: string, createdFor: EventDefinitionCreatedFor, createdAt: Date, latestSnapshot?: Maybe<{ users: { optedIn: { total: number } } }>, shufflEvents?: Maybe<Array<{ id: string, scheduledTime: Date, timezone: string }>>, nextShufflEvent?: Maybe<{ id: string, scheduledTime: Date }>, createdBy: { id: string, name: string }, schedule: { timezone: string, scheduleToString: string, frequency: Frequency, dayOfWeek: DayOfWeek }, slackMetadata?: Maybe<{ id: string, channel: { name?: Maybe<string> } }> } }>>> } };

export type GetEventDefinitionQueryVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
}>;


export type GetEventDefinitionQuery = { eventDefinition?: Maybe<{ shufflStrategy: ShufflStrategy, groupsCreatedTotal?: Maybe<number>, nextRunDate: any, isDefaultEventDefinition: boolean, id: string, state: EventDefinitionState, groupSize: number, createdFor: EventDefinitionCreatedFor, shufflStrategyToString: string, createdAt: Date, eventLastRunAt?: Maybe<Date>, latestSnapshot?: Maybe<{ users: { shuffld: { total: number }, optedIn: { total: number }, optedOut: { total: number }, userAttributeBios: { total: number } } }>, slackMetadata?: Maybe<{ id: string, channel: { id: string, name?: Maybe<string> } }>, tenantTemplate?: Maybe<{ id: string, template: string }>, defaultTemplate: { id: string, template: string }, createdBy: { id: string, name: string }, schedule: { timezone: string, frequency: Frequency, dayOfWeek: DayOfWeek, hourOfDay: number, minuteOfHour: number }, nextShufflEvent?: Maybe<{ id: string, scheduledTime: Date }>, notifications: { sendBiosToGroups: boolean, sendMessageToChannel: boolean, sendMessageToGroups: boolean } }> };

export type GetNextScheduledTimeFromScheduleQueryVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  schedule: NextScheduledTimeFromScheduleScheduleInput;
}>;


export type GetNextScheduledTimeFromScheduleQuery = { getNextScheduledTimeFromSchedule?: Maybe<any> };

export type GetEventSnapshotsQueryVariables = Exact<{
  count?: Maybe<Scalars['Int']>;
  eventDefinitionId?: Maybe<Scalars['ID']>;
}>;


export type GetEventSnapshotsQuery = { eventSnapshots: Array<{ id: string, createdAt: Date, groupSize: number, users: { delta: number, inChannel: number, shuffled: { total: number, totalDelta: number }, inEventDefinition: { total: number } }, groups: { total: number, delta: number, created: { total: number, totalDelta: number } } }> };

export type GetFeatureFlagQueryVariables = Exact<{
  key: FeatureFlagKey;
  eventDefinitionId?: Maybe<Scalars['ID']>;
}>;


export type GetFeatureFlagQuery = { featureFlag?: Maybe<{ key: FeatureFlagKey, isEnabled: boolean, variables?: Maybe<Array<{ defaultValue: string, key: FeatureFlagVariableKey }>>, matchingAudience?: Maybe<Array<{ id: string, name: string, description?: Maybe<string> }>>, matchingRules?: Maybe<Array<{ id: string, variables?: Maybe<Array<{ key: FeatureFlagVariableKey, defaultValue: string }>> }>> }> };

export type GetGroupsForShufflEventQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  shufflEventId?: Maybe<Scalars['String']>;
  createdAtOrder?: Maybe<OrderBy>;
}>;


export type GetGroupsForShufflEventQuery = { groupConnection: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, connectionsTotal: number, newConnections: number, users?: Maybe<Array<{ id: string, isBioSet: boolean, matchedTags?: Maybe<Array<string>>, user: { id: string, name: string, email?: Maybe<string>, slackMetadata?: Maybe<{ image?: Maybe<string> }> } }>> } }>>> } };

export type UpdateOnboardingInstanceMutationVariables = Exact<{
  stepId: OnboardingDefinitionStepId;
  metadata?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
}>;


export type UpdateOnboardingInstanceMutation = { updateOnboardingInstance: { id: string } };

export type GetIsOnboardingInstanceCompletedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIsOnboardingInstanceCompletedQuery = { isOnboardingInstanceCompleted: boolean };

export type GetIsOnboardingInstanceCompletedForPollingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIsOnboardingInstanceCompletedForPollingQuery = { isOnboardingInstanceCompletedForPolling: boolean };

export type GetOnboardingInstanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnboardingInstanceQuery = { onboardingInstance?: Maybe<{ lastCompletedStep: { index: number, id: OnboardingDefinitionStepId }, stepsProgress: Array<{ completedAt?: Maybe<Date>, id: OnboardingDefinitionStepId, index: number, isCompleted: boolean, metadata?: Maybe<string> }>, onboardingDefinition: { steps: Array<{ id: OnboardingDefinitionStepId, index: number, isRequiredForCompletion: boolean, description?: Maybe<string>, instruction?: Maybe<string>, displayName?: Maybe<string> }> } }> };

export type GetPairingAuditsQueryVariables = Exact<{
  eventDefinitionId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
}>;


export type GetPairingAuditsQuery = { pairingAudits?: Maybe<Array<{ id: string, pairing: { id: string }, eventDefinition: { id: string }, shufflEvent: { id: string } }>> };

export type UpdateShufflEventGroupReportMutationVariables = Exact<{
  shufflEventId: Scalars['ID'];
}>;


export type UpdateShufflEventGroupReportMutation = { groupReport: string };

export type GetShufflEventsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  eventDefinitionId?: Maybe<Scalars['String']>;
  state?: Maybe<ShufflEventState>;
  createdAtOrder?: Maybe<OrderBy>;
}>;


export type GetShufflEventsQuery = { shufflEventConnection: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, createdAt: Date, state: ShufflEventState, scheduledTime: Date, timezone: string, eventSnapshot?: Maybe<{ id: string, shufflStrategyToString: string, connections: { new: { total: number }, eventDefinition: { total: number }, event: { total: number } }, groups: { created: { total: number } }, users: { optedOut: { total: number }, bios: { total: number }, inEventDefinition: { total: number }, shuffled: { total: number } } }> } }>>> } };

export type GetShufflEventQueryVariables = Exact<{
  shufflEventId: Scalars['ID'];
}>;


export type GetShufflEventQuery = { shufflEvent?: Maybe<{ id: string, scheduledTime: Date, state: ShufflEventState, stateReason: ShufflEventStateReason, timezone: string, notifications: { sendBiosToGroups: boolean }, eventSnapshot?: Maybe<{ id: string, tags?: Maybe<Array<string>>, shufflStrategyToString: string, groupSize: number, connections: { event: { total: number }, new: { total: number } }, users: { bios: { total: number }, inEventDefinition: { total: number }, shuffled: { total: number }, optedIn: { total: number }, optedOut: { total: number } }, groups: { created: { total: number }, newConnections: { mean: number, max: number, min: number } } }> }> };

export type GetSlackConversationsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}>;


export type GetSlackConversationsQuery = { slackConversations: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, name: string, numberOfMembers: number, topic: string, purpose: string } }>>> } };

export type GetSlackConversationUsersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  conversationId?: Maybe<Scalars['String']>;
}>;


export type GetSlackConversationUsersQuery = { slackConversationUsers: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, name: string, role: UserRole, slackMetadata?: Maybe<{ id: string, slackUserId: string, displayName?: Maybe<string>, realName?: Maybe<string>, image?: Maybe<string> }> } }>>> } };

export type GetStripeCouponByCouponIdQueryVariables = Exact<{
  couponId: Scalars['String'];
}>;


export type GetStripeCouponByCouponIdQuery = { stripeCoupon?: Maybe<{ percentOff?: Maybe<number>, valid: boolean }> };

export type GetStripeCouponByPriceLookupKeyQueryVariables = Exact<{
  priceLookupKey?: Maybe<StripePriceLookupKey>;
}>;


export type GetStripeCouponByPriceLookupKeyQuery = { stripeCoupon?: Maybe<{ percentOff?: Maybe<number>, valid: boolean }> };

export type UpdateTenantStripeCompanyNameMutationVariables = Exact<{
  companyName: Scalars['String'];
}>;


export type UpdateTenantStripeCompanyNameMutation = { updateTenantStripeCompanyName: { id: string } };

export type GetStripeInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeInvoicesQuery = { stripeInvoices?: Maybe<Array<{ id: string, amountDue: number, amountRemaining: number, amountPaid: number, currency: string, paid: boolean, created: Date, periodStart: Date, brand?: Maybe<string>, last4?: Maybe<string>, usageAmount: number, periodEnd: Date, status?: Maybe<string>, dueDate?: Maybe<Date>, invoicePdf?: Maybe<string>, usageRecord?: Maybe<{ id: string, memberQuantity: number, prePaidMemberQuantity?: Maybe<number>, excessMemberQuantity: number, numberOfEventDefinitions: number, numberOfShufflEvents: number, priceExcessTierMax?: Maybe<number>, priceExcessTierMin?: Maybe<number>, priceTierMax?: Maybe<number>, priceTierMin?: Maybe<number>, cost?: Maybe<number>, excessCost?: Maybe<number>, reportUrl?: Maybe<string> }> }>> };

export type GetStripeUpcomingInvoiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeUpcomingInvoiceQuery = { stripeUpcomingInvoice?: Maybe<{ id: string, amountDue: number, amountRemaining: number, amountPaid: number, currency: string, paid: boolean, periodStart: Date, usageAmount: number, periodEnd: Date, status?: Maybe<string>, dueDate?: Maybe<Date>, hostedInvoiceUrl?: Maybe<string> }> };

export type GetStripePaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripePaymentMethodsQuery = { stripePaymentMethods?: Maybe<Array<{ id: string, last4?: Maybe<string>, expYear?: Maybe<number>, expMonth?: Maybe<number>, brand?: Maybe<string>, billingName?: Maybe<string>, billingAddressCity?: Maybe<string>, billingAddressCountry?: Maybe<string>, billingAddressLine1?: Maybe<string>, billingAddressLine2?: Maybe<string>, billingAddressPostalCode?: Maybe<string>, billingAddressState?: Maybe<string>, billingPhone?: Maybe<string>, billingEmail?: Maybe<string> }>> };

export type UpdateTenantStripePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type UpdateTenantStripePaymentMethodMutation = { updateTenantStripePaymentMethod: { id: string } };

export type GetStripePriceQueryVariables = Exact<{
  priceLookupKey: StripePriceLookupKey;
}>;


export type GetStripePriceQuery = { stripePrice?: Maybe<{ id: string, lookupKey: StripePriceLookupKey, amount?: Maybe<number>, interval: string, tiers?: Maybe<Array<{ upTo?: Maybe<number>, flatAmount?: Maybe<number> }>>, metadata: { description: string, name: string, tier: number, minUserLimit: number, maxUserLimit: number } }> };

export type GetStripeSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeSubscriptionQuery = { stripeSubscription?: Maybe<{ id: string, currentPeriodEnd: Date, cost?: Maybe<number>, prePaidCost?: Maybe<number>, prePaidMemberQuantity?: Maybe<number>, priceTier?: Maybe<{ min: number, max: number }>, items: { data: Array<{ quantity?: Maybe<number> }> }, price?: Maybe<{ lookupKey: StripePriceLookupKey, amount?: Maybe<number>, tiers?: Maybe<Array<{ upTo?: Maybe<number>, flatAmount?: Maybe<number> }>>, metadata: { name: string, tier: number, maxUserLimit: number } }> }> };

export type UpdateTenantStripeSubscriptionMutationVariables = Exact<{
  priceLookupKey: StripePriceLookupKey;
}>;


export type UpdateTenantStripeSubscriptionMutation = { updateTenantStripeSubscription: { id: string } };

export type GetTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantQuery = { tenant: { id: string, createdAt: Date, isEligibleForTrial: boolean, isPayingOrTrialing: boolean, isPaying: boolean, isTrialing: boolean, timezone: string, trialEndAt?: Maybe<Date>, trialStartAt?: Maybe<Date>, price: StripePriceLookupKey, payingStartAt?: Maybe<Date>, currentBillingPeriod: { periodEnd: Date, periodStart: Date }, slackMetadata?: Maybe<{ id: string, appId?: Maybe<string>, permissions: { createConversation?: Maybe<boolean> }, team: { icon?: Maybe<string>, name: string, id: string } }> } };

export type GetTenantWithCreatedByQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantWithCreatedByQuery = { tenant: { id: string, createdAt: Date, timezone: string, isEligibleForTrial: boolean, isPayingOrTrialing: boolean, isPaying: boolean, isTrialing: boolean, trialEndAt?: Maybe<Date>, trialStartAt?: Maybe<Date>, price: StripePriceLookupKey, payingStartAt?: Maybe<Date>, currentBillingPeriod: { periodEnd: Date, periodStart: Date }, createdBy?: Maybe<{ id: string, name: string, email?: Maybe<string> }>, slackMetadata?: Maybe<{ id: string, appId?: Maybe<string>, permissions: { createConversation?: Maybe<boolean> }, team: { icon?: Maybe<string>, name: string, id: string } }> } };

export type GetHighestUsageRecordForCurrentBillingPeriodQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHighestUsageRecordForCurrentBillingPeriodQuery = { highestUsageRecordForCurrentBillingPeriod?: Maybe<{ id: string, memberQuantity: number, numberOfEventDefinitions: number, numberOfShufflEvents: number, prePaidMemberQuantity?: Maybe<number>, prePaidCost?: Maybe<number>, billingPeriodEnd: Date, billingPeriodStart: Date, excessMemberQuantity: number, priceExcessTierMax?: Maybe<number>, priceExcessTierMin?: Maybe<number>, priceTierMax?: Maybe<number>, priceTierMin?: Maybe<number>, reportUrl?: Maybe<string>, price: StripePriceLookupKey, cost?: Maybe<number>, excessCost?: Maybe<number> }> };

export type SetOptStateMutationVariables = Exact<{
  userEventDefinitionId: Scalars['ID'];
  optState: UserEventDefinitionOptState;
}>;


export type SetOptStateMutation = { setOptState: { id: string } };

export type GetUserEventDefinitionsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  eventDefinitionId?: Maybe<Scalars['String']>;
  createdAtOrder?: Maybe<OrderBy>;
}>;


export type GetUserEventDefinitionsQuery = { userEventDefinitionConnection: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, totalShuffls?: Maybe<number>, createdAt: Date, state: UserEventDefinitionState, optState: UserEventDefinitionOptState, user: { email?: Maybe<string>, id: string, name: string, isBioSet?: Maybe<boolean>, tags?: Maybe<Array<string>>, slackMetadata?: Maybe<{ id: string, image?: Maybe<string> }> }, lastShufflEvent?: Maybe<{ createdAt: Date, id: string }> } }>>> } };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  role: UserRole;
}>;


export type UpdateUserMutation = { updateUser: { id: string } };

export type UpsertUserMutationVariables = Exact<{
  email: Scalars['String'];
  role: UserRole;
}>;


export type UpsertUserMutation = { upsertUser: { id: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: boolean };

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { profile: { id: string, intercomHashId?: Maybe<string>, createdAt: Date, updatedAt: Date, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, role: UserRole, name: string, phoneNumber?: Maybe<string>, slackMetadata?: Maybe<{ id: string, slackUserId: string, displayName?: Maybe<string>, realName?: Maybe<string>, image?: Maybe<string> }>, impersonatedBy?: Maybe<{ id: string, email?: Maybe<string> }> } };

export type GetAllUsersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  createdAtOrder?: Maybe<OrderBy>;
}>;


export type GetAllUsersQuery = { userConnection: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, name: string, role: UserRole, slackMetadata?: Maybe<{ id: string, slackUserId: string, displayName?: Maybe<string>, realName?: Maybe<string>, image?: Maybe<string> }> } }>>> } };

export type GetAllAdminsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAtOrder?: Maybe<OrderBy>;
}>;


export type GetAllAdminsQuery = { adminConnection: { totalCount: number, pageInfo: { hasNextPage?: Maybe<boolean>, endCursor?: Maybe<string> }, edges?: Maybe<Array<Maybe<{ cursor: string, node: { id: string, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, name: string, role: UserRole, slackMetadata?: Maybe<{ id: string, slackUserId: string, displayName?: Maybe<string>, realName?: Maybe<string>, image?: Maybe<string> }> } }>>> } };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserQuery = { user: { id: string, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, name: string, createdAt: Date, role: UserRole, slackMetadata?: Maybe<{ id: string, slackUserId: string, image?: Maybe<string> }>, eventDefinitionsCreated?: Maybe<Array<{ id: string }>> } };

export type FindUserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type FindUserByEmailQuery = { userByEmail: { id: string } };


export const GetRefreshTokenDocument = gql`
    query GetRefreshToken {
  refreshToken {
    id
    isAuthenticated
  }
}
    `;

/**
 * __useGetRefreshTokenQuery__
 *
 * To run a query within a React component, call `useGetRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefreshTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRefreshTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetRefreshTokenQuery, GetRefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRefreshTokenQuery, GetRefreshTokenQueryVariables>(GetRefreshTokenDocument, options);
      }
export function useGetRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRefreshTokenQuery, GetRefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRefreshTokenQuery, GetRefreshTokenQueryVariables>(GetRefreshTokenDocument, options);
        }
export type GetRefreshTokenQueryHookResult = ReturnType<typeof useGetRefreshTokenQuery>;
export type GetRefreshTokenLazyQueryHookResult = ReturnType<typeof useGetRefreshTokenLazyQuery>;
export type GetRefreshTokenQueryResult = Apollo.QueryResult<GetRefreshTokenQuery, GetRefreshTokenQueryVariables>;
export function refetchGetRefreshTokenQuery(variables?: GetRefreshTokenQueryVariables) {
      return { query: GetRefreshTokenDocument, variables: variables }
    }
export const UpdateEventDefinitionDocument = gql`
    mutation UpdateEventDefinition($eventDefinitionId: ID!, $groupSize: Int!, $template: String) {
  updateEventDefinition(
    eventDefinitionId: $eventDefinitionId
    data: {groupSize: $groupSize, template: $template}
  ) {
    id
  }
}
    `;
export type UpdateEventDefinitionMutationFn = Apollo.MutationFunction<UpdateEventDefinitionMutation, UpdateEventDefinitionMutationVariables>;

/**
 * __useUpdateEventDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateEventDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDefinitionMutation, { data, loading, error }] = useUpdateEventDefinitionMutation({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      groupSize: // value for 'groupSize'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useUpdateEventDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDefinitionMutation, UpdateEventDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDefinitionMutation, UpdateEventDefinitionMutationVariables>(UpdateEventDefinitionDocument, options);
      }
export type UpdateEventDefinitionMutationHookResult = ReturnType<typeof useUpdateEventDefinitionMutation>;
export type UpdateEventDefinitionMutationResult = Apollo.MutationResult<UpdateEventDefinitionMutation>;
export type UpdateEventDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateEventDefinitionMutation, UpdateEventDefinitionMutationVariables>;
export const UpdateEventDefinitionScheduleDocument = gql`
    mutation UpdateEventDefinitionSchedule($eventDefinitionId: ID!, $frequency: Frequency!, $hourOfDay: Int!, $minuteOfHour: Int!, $timezone: String!, $scheduledTime: DateTime!) {
  updateEventDefinitionSchedule(
    eventDefinitionId: $eventDefinitionId
    data: {frequency: $frequency, hourOfDay: $hourOfDay, minuteOfHour: $minuteOfHour, timezone: $timezone, scheduledTime: $scheduledTime}
  ) {
    id
  }
}
    `;
export type UpdateEventDefinitionScheduleMutationFn = Apollo.MutationFunction<UpdateEventDefinitionScheduleMutation, UpdateEventDefinitionScheduleMutationVariables>;

/**
 * __useUpdateEventDefinitionScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateEventDefinitionScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDefinitionScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDefinitionScheduleMutation, { data, loading, error }] = useUpdateEventDefinitionScheduleMutation({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      frequency: // value for 'frequency'
 *      hourOfDay: // value for 'hourOfDay'
 *      minuteOfHour: // value for 'minuteOfHour'
 *      timezone: // value for 'timezone'
 *      scheduledTime: // value for 'scheduledTime'
 *   },
 * });
 */
export function useUpdateEventDefinitionScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDefinitionScheduleMutation, UpdateEventDefinitionScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDefinitionScheduleMutation, UpdateEventDefinitionScheduleMutationVariables>(UpdateEventDefinitionScheduleDocument, options);
      }
export type UpdateEventDefinitionScheduleMutationHookResult = ReturnType<typeof useUpdateEventDefinitionScheduleMutation>;
export type UpdateEventDefinitionScheduleMutationResult = Apollo.MutationResult<UpdateEventDefinitionScheduleMutation>;
export type UpdateEventDefinitionScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateEventDefinitionScheduleMutation, UpdateEventDefinitionScheduleMutationVariables>;
export const UpdateEventDefinitionStateDocument = gql`
    mutation UpdateEventDefinitionState($eventDefinitionId: ID!, $state: EventDefinitionState!) {
  updateEventDefinitionState(
    eventDefinitionId: $eventDefinitionId
    data: {state: $state}
  ) {
    id
  }
}
    `;
export type UpdateEventDefinitionStateMutationFn = Apollo.MutationFunction<UpdateEventDefinitionStateMutation, UpdateEventDefinitionStateMutationVariables>;

/**
 * __useUpdateEventDefinitionStateMutation__
 *
 * To run a mutation, you first call `useUpdateEventDefinitionStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDefinitionStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDefinitionStateMutation, { data, loading, error }] = useUpdateEventDefinitionStateMutation({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateEventDefinitionStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDefinitionStateMutation, UpdateEventDefinitionStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDefinitionStateMutation, UpdateEventDefinitionStateMutationVariables>(UpdateEventDefinitionStateDocument, options);
      }
export type UpdateEventDefinitionStateMutationHookResult = ReturnType<typeof useUpdateEventDefinitionStateMutation>;
export type UpdateEventDefinitionStateMutationResult = Apollo.MutationResult<UpdateEventDefinitionStateMutation>;
export type UpdateEventDefinitionStateMutationOptions = Apollo.BaseMutationOptions<UpdateEventDefinitionStateMutation, UpdateEventDefinitionStateMutationVariables>;
export const UpdateEventDefinitionStrategyDocument = gql`
    mutation UpdateEventDefinitionStrategy($eventDefinitionId: ID!, $shufflStrategy: ShufflStrategy!) {
  updateEventDefinitionStrategy(
    eventDefinitionId: $eventDefinitionId
    data: {shufflStrategy: $shufflStrategy}
  ) {
    id
  }
}
    `;
export type UpdateEventDefinitionStrategyMutationFn = Apollo.MutationFunction<UpdateEventDefinitionStrategyMutation, UpdateEventDefinitionStrategyMutationVariables>;

/**
 * __useUpdateEventDefinitionStrategyMutation__
 *
 * To run a mutation, you first call `useUpdateEventDefinitionStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDefinitionStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDefinitionStrategyMutation, { data, loading, error }] = useUpdateEventDefinitionStrategyMutation({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      shufflStrategy: // value for 'shufflStrategy'
 *   },
 * });
 */
export function useUpdateEventDefinitionStrategyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDefinitionStrategyMutation, UpdateEventDefinitionStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDefinitionStrategyMutation, UpdateEventDefinitionStrategyMutationVariables>(UpdateEventDefinitionStrategyDocument, options);
      }
export type UpdateEventDefinitionStrategyMutationHookResult = ReturnType<typeof useUpdateEventDefinitionStrategyMutation>;
export type UpdateEventDefinitionStrategyMutationResult = Apollo.MutationResult<UpdateEventDefinitionStrategyMutation>;
export type UpdateEventDefinitionStrategyMutationOptions = Apollo.BaseMutationOptions<UpdateEventDefinitionStrategyMutation, UpdateEventDefinitionStrategyMutationVariables>;
export const UpdateEventDefinitionAddonsDocument = gql`
    mutation UpdateEventDefinitionAddons($eventDefinitionId: ID!, $sendBiosToGroups: Boolean!) {
  updateEventDefinitionAddons(
    eventDefinitionId: $eventDefinitionId
    data: {sendBiosToGroups: $sendBiosToGroups}
  ) {
    id
  }
}
    `;
export type UpdateEventDefinitionAddonsMutationFn = Apollo.MutationFunction<UpdateEventDefinitionAddonsMutation, UpdateEventDefinitionAddonsMutationVariables>;

/**
 * __useUpdateEventDefinitionAddonsMutation__
 *
 * To run a mutation, you first call `useUpdateEventDefinitionAddonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDefinitionAddonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDefinitionAddonsMutation, { data, loading, error }] = useUpdateEventDefinitionAddonsMutation({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      sendBiosToGroups: // value for 'sendBiosToGroups'
 *   },
 * });
 */
export function useUpdateEventDefinitionAddonsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDefinitionAddonsMutation, UpdateEventDefinitionAddonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDefinitionAddonsMutation, UpdateEventDefinitionAddonsMutationVariables>(UpdateEventDefinitionAddonsDocument, options);
      }
export type UpdateEventDefinitionAddonsMutationHookResult = ReturnType<typeof useUpdateEventDefinitionAddonsMutation>;
export type UpdateEventDefinitionAddonsMutationResult = Apollo.MutationResult<UpdateEventDefinitionAddonsMutation>;
export type UpdateEventDefinitionAddonsMutationOptions = Apollo.BaseMutationOptions<UpdateEventDefinitionAddonsMutation, UpdateEventDefinitionAddonsMutationVariables>;
export const RescheduleEventDefinitionsDocument = gql`
    mutation RescheduleEventDefinitions {
  rescheduleEventDefinitions
}
    `;
export type RescheduleEventDefinitionsMutationFn = Apollo.MutationFunction<RescheduleEventDefinitionsMutation, RescheduleEventDefinitionsMutationVariables>;

/**
 * __useRescheduleEventDefinitionsMutation__
 *
 * To run a mutation, you first call `useRescheduleEventDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleEventDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleEventDefinitionsMutation, { data, loading, error }] = useRescheduleEventDefinitionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRescheduleEventDefinitionsMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleEventDefinitionsMutation, RescheduleEventDefinitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleEventDefinitionsMutation, RescheduleEventDefinitionsMutationVariables>(RescheduleEventDefinitionsDocument, options);
      }
export type RescheduleEventDefinitionsMutationHookResult = ReturnType<typeof useRescheduleEventDefinitionsMutation>;
export type RescheduleEventDefinitionsMutationResult = Apollo.MutationResult<RescheduleEventDefinitionsMutation>;
export type RescheduleEventDefinitionsMutationOptions = Apollo.BaseMutationOptions<RescheduleEventDefinitionsMutation, RescheduleEventDefinitionsMutationVariables>;
export const CreateDefaultEventDefinitionDocument = gql`
    mutation CreateDefaultEventDefinition {
  createDefaultEventDefinition
}
    `;
export type CreateDefaultEventDefinitionMutationFn = Apollo.MutationFunction<CreateDefaultEventDefinitionMutation, CreateDefaultEventDefinitionMutationVariables>;

/**
 * __useCreateDefaultEventDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateDefaultEventDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultEventDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultEventDefinitionMutation, { data, loading, error }] = useCreateDefaultEventDefinitionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDefaultEventDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDefaultEventDefinitionMutation, CreateDefaultEventDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDefaultEventDefinitionMutation, CreateDefaultEventDefinitionMutationVariables>(CreateDefaultEventDefinitionDocument, options);
      }
export type CreateDefaultEventDefinitionMutationHookResult = ReturnType<typeof useCreateDefaultEventDefinitionMutation>;
export type CreateDefaultEventDefinitionMutationResult = Apollo.MutationResult<CreateDefaultEventDefinitionMutation>;
export type CreateDefaultEventDefinitionMutationOptions = Apollo.BaseMutationOptions<CreateDefaultEventDefinitionMutation, CreateDefaultEventDefinitionMutationVariables>;
export const GetNextRunEventDefinitionDocument = gql`
    query GetNextRunEventDefinition {
  nextRunEventDefinition {
    id
    shufflStrategy
    groupSize
    nextRunDateToString
    nextShufflEvent {
      id
      scheduledTime
    }
    schedule {
      scheduleToString
    }
    slackMetadata {
      id
      channel {
        name
        id
      }
    }
    state
    nextRunDate
  }
}
    `;

/**
 * __useGetNextRunEventDefinitionQuery__
 *
 * To run a query within a React component, call `useGetNextRunEventDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextRunEventDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextRunEventDefinitionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNextRunEventDefinitionQuery(baseOptions?: Apollo.QueryHookOptions<GetNextRunEventDefinitionQuery, GetNextRunEventDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextRunEventDefinitionQuery, GetNextRunEventDefinitionQueryVariables>(GetNextRunEventDefinitionDocument, options);
      }
export function useGetNextRunEventDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextRunEventDefinitionQuery, GetNextRunEventDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextRunEventDefinitionQuery, GetNextRunEventDefinitionQueryVariables>(GetNextRunEventDefinitionDocument, options);
        }
export type GetNextRunEventDefinitionQueryHookResult = ReturnType<typeof useGetNextRunEventDefinitionQuery>;
export type GetNextRunEventDefinitionLazyQueryHookResult = ReturnType<typeof useGetNextRunEventDefinitionLazyQuery>;
export type GetNextRunEventDefinitionQueryResult = Apollo.QueryResult<GetNextRunEventDefinitionQuery, GetNextRunEventDefinitionQueryVariables>;
export function refetchGetNextRunEventDefinitionQuery(variables?: GetNextRunEventDefinitionQueryVariables) {
      return { query: GetNextRunEventDefinitionDocument, variables: variables }
    }
export const GetDefaultEventDefinitionDocument = gql`
    query GetDefaultEventDefinition {
  defaultEventDefinition {
    id
    shufflStrategy
    groupSize
    slackMetadata {
      id
      channel {
        name
        id
      }
    }
    schedule {
      scheduleToString
    }
    nextRunDate
  }
}
    `;

/**
 * __useGetDefaultEventDefinitionQuery__
 *
 * To run a query within a React component, call `useGetDefaultEventDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultEventDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultEventDefinitionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultEventDefinitionQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultEventDefinitionQuery, GetDefaultEventDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultEventDefinitionQuery, GetDefaultEventDefinitionQueryVariables>(GetDefaultEventDefinitionDocument, options);
      }
export function useGetDefaultEventDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultEventDefinitionQuery, GetDefaultEventDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultEventDefinitionQuery, GetDefaultEventDefinitionQueryVariables>(GetDefaultEventDefinitionDocument, options);
        }
export type GetDefaultEventDefinitionQueryHookResult = ReturnType<typeof useGetDefaultEventDefinitionQuery>;
export type GetDefaultEventDefinitionLazyQueryHookResult = ReturnType<typeof useGetDefaultEventDefinitionLazyQuery>;
export type GetDefaultEventDefinitionQueryResult = Apollo.QueryResult<GetDefaultEventDefinitionQuery, GetDefaultEventDefinitionQueryVariables>;
export function refetchGetDefaultEventDefinitionQuery(variables?: GetDefaultEventDefinitionQueryVariables) {
      return { query: GetDefaultEventDefinitionDocument, variables: variables }
    }
export const GetAllEventDefinitionsDocument = gql`
    query GetAllEventDefinitions($query: String, $after: String, $first: Int, $state: EventDefinitionState, $createdAtOrder: OrderBy) {
  eventDefinitionConnection(
    after: $after
    first: $first
    where: {query: $query, state: $state}
    order: {createdAt: $createdAtOrder}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        nextRunDate
        isDefaultEventDefinition
        latestSnapshot {
          users {
            optedIn {
              total
            }
          }
        }
        id
        state
        groupSize
        shufflStrategyToString
        shufflEvents(take: 1) {
          id
          scheduledTime
          timezone
        }
        nextShufflEvent {
          id
          scheduledTime
        }
        createdFor
        createdBy {
          id
          name
        }
        schedule {
          timezone
          scheduleToString
          frequency
          dayOfWeek
        }
        createdAt
        slackMetadata {
          id
          channel {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllEventDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetAllEventDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventDefinitionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      state: // value for 'state'
 *      createdAtOrder: // value for 'createdAtOrder'
 *   },
 * });
 */
export function useGetAllEventDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEventDefinitionsQuery, GetAllEventDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventDefinitionsQuery, GetAllEventDefinitionsQueryVariables>(GetAllEventDefinitionsDocument, options);
      }
export function useGetAllEventDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventDefinitionsQuery, GetAllEventDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventDefinitionsQuery, GetAllEventDefinitionsQueryVariables>(GetAllEventDefinitionsDocument, options);
        }
export type GetAllEventDefinitionsQueryHookResult = ReturnType<typeof useGetAllEventDefinitionsQuery>;
export type GetAllEventDefinitionsLazyQueryHookResult = ReturnType<typeof useGetAllEventDefinitionsLazyQuery>;
export type GetAllEventDefinitionsQueryResult = Apollo.QueryResult<GetAllEventDefinitionsQuery, GetAllEventDefinitionsQueryVariables>;
export function refetchGetAllEventDefinitionsQuery(variables?: GetAllEventDefinitionsQueryVariables) {
      return { query: GetAllEventDefinitionsDocument, variables: variables }
    }
export const GetEventDefinitionDocument = gql`
    query GetEventDefinition($eventDefinitionId: ID!) {
  eventDefinition(eventDefinitionId: $eventDefinitionId) {
    latestSnapshot {
      users {
        shuffld {
          total
        }
        optedIn {
          total
        }
        optedOut {
          total
        }
        userAttributeBios {
          total
        }
      }
    }
    shufflStrategy
    groupsCreatedTotal
    nextRunDate
    isDefaultEventDefinition
    slackMetadata {
      id
      channel {
        id
        name
      }
    }
    id
    tenantTemplate {
      id
      template
    }
    defaultTemplate {
      id
      template
    }
    state
    groupSize
    createdFor
    shufflStrategyToString
    createdBy {
      id
      name
    }
    schedule {
      timezone
      frequency
      dayOfWeek
      hourOfDay
      minuteOfHour
    }
    createdAt
    eventLastRunAt
    nextShufflEvent {
      id
      scheduledTime
    }
    notifications {
      sendBiosToGroups
      sendMessageToChannel
      sendMessageToGroups
    }
  }
}
    `;

/**
 * __useGetEventDefinitionQuery__
 *
 * To run a query within a React component, call `useGetEventDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDefinitionQuery({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *   },
 * });
 */
export function useGetEventDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetEventDefinitionQuery, GetEventDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventDefinitionQuery, GetEventDefinitionQueryVariables>(GetEventDefinitionDocument, options);
      }
export function useGetEventDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventDefinitionQuery, GetEventDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventDefinitionQuery, GetEventDefinitionQueryVariables>(GetEventDefinitionDocument, options);
        }
export type GetEventDefinitionQueryHookResult = ReturnType<typeof useGetEventDefinitionQuery>;
export type GetEventDefinitionLazyQueryHookResult = ReturnType<typeof useGetEventDefinitionLazyQuery>;
export type GetEventDefinitionQueryResult = Apollo.QueryResult<GetEventDefinitionQuery, GetEventDefinitionQueryVariables>;
export function refetchGetEventDefinitionQuery(variables?: GetEventDefinitionQueryVariables) {
      return { query: GetEventDefinitionDocument, variables: variables }
    }
export const GetNextScheduledTimeFromScheduleDocument = gql`
    query GetNextScheduledTimeFromSchedule($eventDefinitionId: ID!, $schedule: NextScheduledTimeFromScheduleScheduleInput!) {
  getNextScheduledTimeFromSchedule(
    eventDefinitionId: $eventDefinitionId
    schedule: $schedule
  )
}
    `;

/**
 * __useGetNextScheduledTimeFromScheduleQuery__
 *
 * To run a query within a React component, call `useGetNextScheduledTimeFromScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextScheduledTimeFromScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextScheduledTimeFromScheduleQuery({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useGetNextScheduledTimeFromScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetNextScheduledTimeFromScheduleQuery, GetNextScheduledTimeFromScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextScheduledTimeFromScheduleQuery, GetNextScheduledTimeFromScheduleQueryVariables>(GetNextScheduledTimeFromScheduleDocument, options);
      }
export function useGetNextScheduledTimeFromScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextScheduledTimeFromScheduleQuery, GetNextScheduledTimeFromScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextScheduledTimeFromScheduleQuery, GetNextScheduledTimeFromScheduleQueryVariables>(GetNextScheduledTimeFromScheduleDocument, options);
        }
export type GetNextScheduledTimeFromScheduleQueryHookResult = ReturnType<typeof useGetNextScheduledTimeFromScheduleQuery>;
export type GetNextScheduledTimeFromScheduleLazyQueryHookResult = ReturnType<typeof useGetNextScheduledTimeFromScheduleLazyQuery>;
export type GetNextScheduledTimeFromScheduleQueryResult = Apollo.QueryResult<GetNextScheduledTimeFromScheduleQuery, GetNextScheduledTimeFromScheduleQueryVariables>;
export function refetchGetNextScheduledTimeFromScheduleQuery(variables?: GetNextScheduledTimeFromScheduleQueryVariables) {
      return { query: GetNextScheduledTimeFromScheduleDocument, variables: variables }
    }
export const GetEventSnapshotsDocument = gql`
    query GetEventSnapshots($count: Int, $eventDefinitionId: ID) {
  eventSnapshots(count: $count, eventDefinitionId: $eventDefinitionId) {
    id
    createdAt
    groupSize
    users {
      shuffled {
        total
        totalDelta
      }
      inEventDefinition {
        total
      }
      delta
      inChannel
    }
    groups {
      created {
        total
        totalDelta
      }
      total
      delta
    }
  }
}
    `;

/**
 * __useGetEventSnapshotsQuery__
 *
 * To run a query within a React component, call `useGetEventSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventSnapshotsQuery({
 *   variables: {
 *      count: // value for 'count'
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *   },
 * });
 */
export function useGetEventSnapshotsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventSnapshotsQuery, GetEventSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventSnapshotsQuery, GetEventSnapshotsQueryVariables>(GetEventSnapshotsDocument, options);
      }
export function useGetEventSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventSnapshotsQuery, GetEventSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventSnapshotsQuery, GetEventSnapshotsQueryVariables>(GetEventSnapshotsDocument, options);
        }
export type GetEventSnapshotsQueryHookResult = ReturnType<typeof useGetEventSnapshotsQuery>;
export type GetEventSnapshotsLazyQueryHookResult = ReturnType<typeof useGetEventSnapshotsLazyQuery>;
export type GetEventSnapshotsQueryResult = Apollo.QueryResult<GetEventSnapshotsQuery, GetEventSnapshotsQueryVariables>;
export function refetchGetEventSnapshotsQuery(variables?: GetEventSnapshotsQueryVariables) {
      return { query: GetEventSnapshotsDocument, variables: variables }
    }
export const GetFeatureFlagDocument = gql`
    query GetFeatureFlag($key: FeatureFlagKey!, $eventDefinitionId: ID) {
  featureFlag(key: $key, eventDefinitionId: $eventDefinitionId) {
    key
    isEnabled
    variables {
      defaultValue
      key
    }
    matchingAudience {
      id
      name
      description
    }
    matchingRules {
      id
      variables {
        key
        defaultValue
      }
    }
  }
}
    `;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      key: // value for 'key'
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
      }
export function useGetFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
        }
export type GetFeatureFlagQueryHookResult = ReturnType<typeof useGetFeatureFlagQuery>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagLazyQuery>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>;
export function refetchGetFeatureFlagQuery(variables?: GetFeatureFlagQueryVariables) {
      return { query: GetFeatureFlagDocument, variables: variables }
    }
export const GetGroupsForShufflEventDocument = gql`
    query GetGroupsForShufflEvent($after: String, $first: Int, $shufflEventId: String, $createdAtOrder: OrderBy) {
  groupConnection(
    after: $after
    first: $first
    where: {shufflEventId: $shufflEventId}
    order: {createdAt: $createdAtOrder}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        connectionsTotal
        newConnections
        users {
          id
          isBioSet
          matchedTags
          user {
            id
            name
            email
            slackMetadata {
              image
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGroupsForShufflEventQuery__
 *
 * To run a query within a React component, call `useGetGroupsForShufflEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForShufflEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForShufflEventQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      shufflEventId: // value for 'shufflEventId'
 *      createdAtOrder: // value for 'createdAtOrder'
 *   },
 * });
 */
export function useGetGroupsForShufflEventQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsForShufflEventQuery, GetGroupsForShufflEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsForShufflEventQuery, GetGroupsForShufflEventQueryVariables>(GetGroupsForShufflEventDocument, options);
      }
export function useGetGroupsForShufflEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsForShufflEventQuery, GetGroupsForShufflEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsForShufflEventQuery, GetGroupsForShufflEventQueryVariables>(GetGroupsForShufflEventDocument, options);
        }
export type GetGroupsForShufflEventQueryHookResult = ReturnType<typeof useGetGroupsForShufflEventQuery>;
export type GetGroupsForShufflEventLazyQueryHookResult = ReturnType<typeof useGetGroupsForShufflEventLazyQuery>;
export type GetGroupsForShufflEventQueryResult = Apollo.QueryResult<GetGroupsForShufflEventQuery, GetGroupsForShufflEventQueryVariables>;
export function refetchGetGroupsForShufflEventQuery(variables?: GetGroupsForShufflEventQueryVariables) {
      return { query: GetGroupsForShufflEventDocument, variables: variables }
    }
export const UpdateOnboardingInstanceDocument = gql`
    mutation UpdateOnboardingInstance($stepId: OnboardingDefinitionStepId!, $metadata: String, $isCompleted: Boolean!) {
  updateOnboardingInstance(
    stepId: $stepId
    data: {metadata: $metadata, isCompleted: $isCompleted}
  ) {
    id
  }
}
    `;
export type UpdateOnboardingInstanceMutationFn = Apollo.MutationFunction<UpdateOnboardingInstanceMutation, UpdateOnboardingInstanceMutationVariables>;

/**
 * __useUpdateOnboardingInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingInstanceMutation, { data, loading, error }] = useUpdateOnboardingInstanceMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *      metadata: // value for 'metadata'
 *      isCompleted: // value for 'isCompleted'
 *   },
 * });
 */
export function useUpdateOnboardingInstanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingInstanceMutation, UpdateOnboardingInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingInstanceMutation, UpdateOnboardingInstanceMutationVariables>(UpdateOnboardingInstanceDocument, options);
      }
export type UpdateOnboardingInstanceMutationHookResult = ReturnType<typeof useUpdateOnboardingInstanceMutation>;
export type UpdateOnboardingInstanceMutationResult = Apollo.MutationResult<UpdateOnboardingInstanceMutation>;
export type UpdateOnboardingInstanceMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingInstanceMutation, UpdateOnboardingInstanceMutationVariables>;
export const GetIsOnboardingInstanceCompletedDocument = gql`
    query GetIsOnboardingInstanceCompleted {
  isOnboardingInstanceCompleted
}
    `;

/**
 * __useGetIsOnboardingInstanceCompletedQuery__
 *
 * To run a query within a React component, call `useGetIsOnboardingInstanceCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsOnboardingInstanceCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsOnboardingInstanceCompletedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsOnboardingInstanceCompletedQuery(baseOptions?: Apollo.QueryHookOptions<GetIsOnboardingInstanceCompletedQuery, GetIsOnboardingInstanceCompletedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsOnboardingInstanceCompletedQuery, GetIsOnboardingInstanceCompletedQueryVariables>(GetIsOnboardingInstanceCompletedDocument, options);
      }
export function useGetIsOnboardingInstanceCompletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsOnboardingInstanceCompletedQuery, GetIsOnboardingInstanceCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsOnboardingInstanceCompletedQuery, GetIsOnboardingInstanceCompletedQueryVariables>(GetIsOnboardingInstanceCompletedDocument, options);
        }
export type GetIsOnboardingInstanceCompletedQueryHookResult = ReturnType<typeof useGetIsOnboardingInstanceCompletedQuery>;
export type GetIsOnboardingInstanceCompletedLazyQueryHookResult = ReturnType<typeof useGetIsOnboardingInstanceCompletedLazyQuery>;
export type GetIsOnboardingInstanceCompletedQueryResult = Apollo.QueryResult<GetIsOnboardingInstanceCompletedQuery, GetIsOnboardingInstanceCompletedQueryVariables>;
export function refetchGetIsOnboardingInstanceCompletedQuery(variables?: GetIsOnboardingInstanceCompletedQueryVariables) {
      return { query: GetIsOnboardingInstanceCompletedDocument, variables: variables }
    }
export const GetIsOnboardingInstanceCompletedForPollingDocument = gql`
    query GetIsOnboardingInstanceCompletedForPolling {
  isOnboardingInstanceCompletedForPolling
}
    `;

/**
 * __useGetIsOnboardingInstanceCompletedForPollingQuery__
 *
 * To run a query within a React component, call `useGetIsOnboardingInstanceCompletedForPollingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsOnboardingInstanceCompletedForPollingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsOnboardingInstanceCompletedForPollingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsOnboardingInstanceCompletedForPollingQuery(baseOptions?: Apollo.QueryHookOptions<GetIsOnboardingInstanceCompletedForPollingQuery, GetIsOnboardingInstanceCompletedForPollingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsOnboardingInstanceCompletedForPollingQuery, GetIsOnboardingInstanceCompletedForPollingQueryVariables>(GetIsOnboardingInstanceCompletedForPollingDocument, options);
      }
export function useGetIsOnboardingInstanceCompletedForPollingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsOnboardingInstanceCompletedForPollingQuery, GetIsOnboardingInstanceCompletedForPollingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsOnboardingInstanceCompletedForPollingQuery, GetIsOnboardingInstanceCompletedForPollingQueryVariables>(GetIsOnboardingInstanceCompletedForPollingDocument, options);
        }
export type GetIsOnboardingInstanceCompletedForPollingQueryHookResult = ReturnType<typeof useGetIsOnboardingInstanceCompletedForPollingQuery>;
export type GetIsOnboardingInstanceCompletedForPollingLazyQueryHookResult = ReturnType<typeof useGetIsOnboardingInstanceCompletedForPollingLazyQuery>;
export type GetIsOnboardingInstanceCompletedForPollingQueryResult = Apollo.QueryResult<GetIsOnboardingInstanceCompletedForPollingQuery, GetIsOnboardingInstanceCompletedForPollingQueryVariables>;
export function refetchGetIsOnboardingInstanceCompletedForPollingQuery(variables?: GetIsOnboardingInstanceCompletedForPollingQueryVariables) {
      return { query: GetIsOnboardingInstanceCompletedForPollingDocument, variables: variables }
    }
export const GetOnboardingInstanceDocument = gql`
    query GetOnboardingInstance {
  onboardingInstance {
    lastCompletedStep {
      index
      id
    }
    stepsProgress {
      completedAt
      id
      index
      isCompleted
      metadata
    }
    onboardingDefinition {
      steps {
        id
        index
        isRequiredForCompletion
        description
        instruction
        displayName
      }
    }
  }
}
    `;

/**
 * __useGetOnboardingInstanceQuery__
 *
 * To run a query within a React component, call `useGetOnboardingInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingInstanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingInstanceQuery(baseOptions?: Apollo.QueryHookOptions<GetOnboardingInstanceQuery, GetOnboardingInstanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingInstanceQuery, GetOnboardingInstanceQueryVariables>(GetOnboardingInstanceDocument, options);
      }
export function useGetOnboardingInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingInstanceQuery, GetOnboardingInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingInstanceQuery, GetOnboardingInstanceQueryVariables>(GetOnboardingInstanceDocument, options);
        }
export type GetOnboardingInstanceQueryHookResult = ReturnType<typeof useGetOnboardingInstanceQuery>;
export type GetOnboardingInstanceLazyQueryHookResult = ReturnType<typeof useGetOnboardingInstanceLazyQuery>;
export type GetOnboardingInstanceQueryResult = Apollo.QueryResult<GetOnboardingInstanceQuery, GetOnboardingInstanceQueryVariables>;
export function refetchGetOnboardingInstanceQuery(variables?: GetOnboardingInstanceQueryVariables) {
      return { query: GetOnboardingInstanceDocument, variables: variables }
    }
export const GetPairingAuditsDocument = gql`
    query GetPairingAudits($eventDefinitionId: ID!, $count: Int) {
  pairingAudits(eventDefinitionId: $eventDefinitionId, count: $count) {
    id
    pairing {
      id
    }
    eventDefinition {
      id
    }
    shufflEvent {
      id
    }
  }
}
    `;

/**
 * __useGetPairingAuditsQuery__
 *
 * To run a query within a React component, call `useGetPairingAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPairingAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPairingAuditsQuery({
 *   variables: {
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetPairingAuditsQuery(baseOptions: Apollo.QueryHookOptions<GetPairingAuditsQuery, GetPairingAuditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPairingAuditsQuery, GetPairingAuditsQueryVariables>(GetPairingAuditsDocument, options);
      }
export function useGetPairingAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPairingAuditsQuery, GetPairingAuditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPairingAuditsQuery, GetPairingAuditsQueryVariables>(GetPairingAuditsDocument, options);
        }
export type GetPairingAuditsQueryHookResult = ReturnType<typeof useGetPairingAuditsQuery>;
export type GetPairingAuditsLazyQueryHookResult = ReturnType<typeof useGetPairingAuditsLazyQuery>;
export type GetPairingAuditsQueryResult = Apollo.QueryResult<GetPairingAuditsQuery, GetPairingAuditsQueryVariables>;
export function refetchGetPairingAuditsQuery(variables?: GetPairingAuditsQueryVariables) {
      return { query: GetPairingAuditsDocument, variables: variables }
    }
export const UpdateShufflEventGroupReportDocument = gql`
    mutation UpdateShufflEventGroupReport($shufflEventId: ID!) {
  groupReport(shufflEventId: $shufflEventId)
}
    `;
export type UpdateShufflEventGroupReportMutationFn = Apollo.MutationFunction<UpdateShufflEventGroupReportMutation, UpdateShufflEventGroupReportMutationVariables>;

/**
 * __useUpdateShufflEventGroupReportMutation__
 *
 * To run a mutation, you first call `useUpdateShufflEventGroupReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShufflEventGroupReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShufflEventGroupReportMutation, { data, loading, error }] = useUpdateShufflEventGroupReportMutation({
 *   variables: {
 *      shufflEventId: // value for 'shufflEventId'
 *   },
 * });
 */
export function useUpdateShufflEventGroupReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShufflEventGroupReportMutation, UpdateShufflEventGroupReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShufflEventGroupReportMutation, UpdateShufflEventGroupReportMutationVariables>(UpdateShufflEventGroupReportDocument, options);
      }
export type UpdateShufflEventGroupReportMutationHookResult = ReturnType<typeof useUpdateShufflEventGroupReportMutation>;
export type UpdateShufflEventGroupReportMutationResult = Apollo.MutationResult<UpdateShufflEventGroupReportMutation>;
export type UpdateShufflEventGroupReportMutationOptions = Apollo.BaseMutationOptions<UpdateShufflEventGroupReportMutation, UpdateShufflEventGroupReportMutationVariables>;
export const GetShufflEventsDocument = gql`
    query GetShufflEvents($after: String, $first: Int, $eventDefinitionId: String, $state: ShufflEventState, $createdAtOrder: OrderBy) {
  shufflEventConnection(
    after: $after
    first: $first
    where: {eventDefinitionId: $eventDefinitionId, state: $state}
    order: {createdAt: $createdAtOrder}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        createdAt
        state
        scheduledTime
        timezone
        eventSnapshot {
          id
          shufflStrategyToString
          connections {
            new {
              total
            }
            eventDefinition {
              total
            }
            event {
              total
            }
          }
          groups {
            created {
              total
            }
          }
          users {
            optedOut {
              total
            }
            bios {
              total
            }
            inEventDefinition {
              total
            }
            shuffled {
              total
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetShufflEventsQuery__
 *
 * To run a query within a React component, call `useGetShufflEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShufflEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShufflEventsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      state: // value for 'state'
 *      createdAtOrder: // value for 'createdAtOrder'
 *   },
 * });
 */
export function useGetShufflEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetShufflEventsQuery, GetShufflEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShufflEventsQuery, GetShufflEventsQueryVariables>(GetShufflEventsDocument, options);
      }
export function useGetShufflEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShufflEventsQuery, GetShufflEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShufflEventsQuery, GetShufflEventsQueryVariables>(GetShufflEventsDocument, options);
        }
export type GetShufflEventsQueryHookResult = ReturnType<typeof useGetShufflEventsQuery>;
export type GetShufflEventsLazyQueryHookResult = ReturnType<typeof useGetShufflEventsLazyQuery>;
export type GetShufflEventsQueryResult = Apollo.QueryResult<GetShufflEventsQuery, GetShufflEventsQueryVariables>;
export function refetchGetShufflEventsQuery(variables?: GetShufflEventsQueryVariables) {
      return { query: GetShufflEventsDocument, variables: variables }
    }
export const GetShufflEventDocument = gql`
    query GetShufflEvent($shufflEventId: ID!) {
  shufflEvent(shufflEventId: $shufflEventId) {
    id
    scheduledTime
    state
    stateReason
    timezone
    notifications {
      sendBiosToGroups
    }
    eventSnapshot {
      id
      tags
      connections {
        event {
          total
        }
        new {
          total
        }
      }
      shufflStrategyToString
      users {
        bios {
          total
        }
        inEventDefinition {
          total
        }
        shuffled {
          total
        }
        optedIn {
          total
        }
        optedOut {
          total
        }
      }
      groupSize
      groups {
        created {
          total
        }
        newConnections {
          mean
          max
          min
        }
      }
    }
  }
}
    `;

/**
 * __useGetShufflEventQuery__
 *
 * To run a query within a React component, call `useGetShufflEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShufflEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShufflEventQuery({
 *   variables: {
 *      shufflEventId: // value for 'shufflEventId'
 *   },
 * });
 */
export function useGetShufflEventQuery(baseOptions: Apollo.QueryHookOptions<GetShufflEventQuery, GetShufflEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShufflEventQuery, GetShufflEventQueryVariables>(GetShufflEventDocument, options);
      }
export function useGetShufflEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShufflEventQuery, GetShufflEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShufflEventQuery, GetShufflEventQueryVariables>(GetShufflEventDocument, options);
        }
export type GetShufflEventQueryHookResult = ReturnType<typeof useGetShufflEventQuery>;
export type GetShufflEventLazyQueryHookResult = ReturnType<typeof useGetShufflEventLazyQuery>;
export type GetShufflEventQueryResult = Apollo.QueryResult<GetShufflEventQuery, GetShufflEventQueryVariables>;
export function refetchGetShufflEventQuery(variables?: GetShufflEventQueryVariables) {
      return { query: GetShufflEventDocument, variables: variables }
    }
export const GetSlackConversationsDocument = gql`
    query GetSlackConversations($after: String, $first: Int, $name: String) {
  slackConversations(after: $after, first: $first, where: {name: $name}) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        name
        numberOfMembers
        topic
        purpose
      }
    }
  }
}
    `;

/**
 * __useGetSlackConversationsQuery__
 *
 * To run a query within a React component, call `useGetSlackConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlackConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlackConversationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetSlackConversationsQuery(baseOptions?: Apollo.QueryHookOptions<GetSlackConversationsQuery, GetSlackConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlackConversationsQuery, GetSlackConversationsQueryVariables>(GetSlackConversationsDocument, options);
      }
export function useGetSlackConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlackConversationsQuery, GetSlackConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlackConversationsQuery, GetSlackConversationsQueryVariables>(GetSlackConversationsDocument, options);
        }
export type GetSlackConversationsQueryHookResult = ReturnType<typeof useGetSlackConversationsQuery>;
export type GetSlackConversationsLazyQueryHookResult = ReturnType<typeof useGetSlackConversationsLazyQuery>;
export type GetSlackConversationsQueryResult = Apollo.QueryResult<GetSlackConversationsQuery, GetSlackConversationsQueryVariables>;
export function refetchGetSlackConversationsQuery(variables?: GetSlackConversationsQueryVariables) {
      return { query: GetSlackConversationsDocument, variables: variables }
    }
export const GetSlackConversationUsersDocument = gql`
    query GetSlackConversationUsers($after: String, $first: Int, $conversationId: String) {
  slackConversationUsers(
    after: $after
    first: $first
    where: {conversationId: $conversationId}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        email
        firstName
        lastName
        name
        role
        slackMetadata {
          id
          slackUserId
          displayName
          realName
          image
        }
      }
    }
  }
}
    `;

/**
 * __useGetSlackConversationUsersQuery__
 *
 * To run a query within a React component, call `useGetSlackConversationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlackConversationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlackConversationUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetSlackConversationUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetSlackConversationUsersQuery, GetSlackConversationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlackConversationUsersQuery, GetSlackConversationUsersQueryVariables>(GetSlackConversationUsersDocument, options);
      }
export function useGetSlackConversationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlackConversationUsersQuery, GetSlackConversationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlackConversationUsersQuery, GetSlackConversationUsersQueryVariables>(GetSlackConversationUsersDocument, options);
        }
export type GetSlackConversationUsersQueryHookResult = ReturnType<typeof useGetSlackConversationUsersQuery>;
export type GetSlackConversationUsersLazyQueryHookResult = ReturnType<typeof useGetSlackConversationUsersLazyQuery>;
export type GetSlackConversationUsersQueryResult = Apollo.QueryResult<GetSlackConversationUsersQuery, GetSlackConversationUsersQueryVariables>;
export function refetchGetSlackConversationUsersQuery(variables?: GetSlackConversationUsersQueryVariables) {
      return { query: GetSlackConversationUsersDocument, variables: variables }
    }
export const GetStripeCouponByCouponIdDocument = gql`
    query GetStripeCouponByCouponId($couponId: String!) {
  stripeCoupon(couponId: $couponId) {
    percentOff
    valid
  }
}
    `;

/**
 * __useGetStripeCouponByCouponIdQuery__
 *
 * To run a query within a React component, call `useGetStripeCouponByCouponIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCouponByCouponIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCouponByCouponIdQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useGetStripeCouponByCouponIdQuery(baseOptions: Apollo.QueryHookOptions<GetStripeCouponByCouponIdQuery, GetStripeCouponByCouponIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeCouponByCouponIdQuery, GetStripeCouponByCouponIdQueryVariables>(GetStripeCouponByCouponIdDocument, options);
      }
export function useGetStripeCouponByCouponIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeCouponByCouponIdQuery, GetStripeCouponByCouponIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeCouponByCouponIdQuery, GetStripeCouponByCouponIdQueryVariables>(GetStripeCouponByCouponIdDocument, options);
        }
export type GetStripeCouponByCouponIdQueryHookResult = ReturnType<typeof useGetStripeCouponByCouponIdQuery>;
export type GetStripeCouponByCouponIdLazyQueryHookResult = ReturnType<typeof useGetStripeCouponByCouponIdLazyQuery>;
export type GetStripeCouponByCouponIdQueryResult = Apollo.QueryResult<GetStripeCouponByCouponIdQuery, GetStripeCouponByCouponIdQueryVariables>;
export function refetchGetStripeCouponByCouponIdQuery(variables?: GetStripeCouponByCouponIdQueryVariables) {
      return { query: GetStripeCouponByCouponIdDocument, variables: variables }
    }
export const GetStripeCouponByPriceLookupKeyDocument = gql`
    query GetStripeCouponByPriceLookupKey($priceLookupKey: StripePriceLookupKey) {
  stripeCoupon(priceLookupKey: $priceLookupKey) {
    percentOff
    valid
  }
}
    `;

/**
 * __useGetStripeCouponByPriceLookupKeyQuery__
 *
 * To run a query within a React component, call `useGetStripeCouponByPriceLookupKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCouponByPriceLookupKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCouponByPriceLookupKeyQuery({
 *   variables: {
 *      priceLookupKey: // value for 'priceLookupKey'
 *   },
 * });
 */
export function useGetStripeCouponByPriceLookupKeyQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeCouponByPriceLookupKeyQuery, GetStripeCouponByPriceLookupKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeCouponByPriceLookupKeyQuery, GetStripeCouponByPriceLookupKeyQueryVariables>(GetStripeCouponByPriceLookupKeyDocument, options);
      }
export function useGetStripeCouponByPriceLookupKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeCouponByPriceLookupKeyQuery, GetStripeCouponByPriceLookupKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeCouponByPriceLookupKeyQuery, GetStripeCouponByPriceLookupKeyQueryVariables>(GetStripeCouponByPriceLookupKeyDocument, options);
        }
export type GetStripeCouponByPriceLookupKeyQueryHookResult = ReturnType<typeof useGetStripeCouponByPriceLookupKeyQuery>;
export type GetStripeCouponByPriceLookupKeyLazyQueryHookResult = ReturnType<typeof useGetStripeCouponByPriceLookupKeyLazyQuery>;
export type GetStripeCouponByPriceLookupKeyQueryResult = Apollo.QueryResult<GetStripeCouponByPriceLookupKeyQuery, GetStripeCouponByPriceLookupKeyQueryVariables>;
export function refetchGetStripeCouponByPriceLookupKeyQuery(variables?: GetStripeCouponByPriceLookupKeyQueryVariables) {
      return { query: GetStripeCouponByPriceLookupKeyDocument, variables: variables }
    }
export const UpdateTenantStripeCompanyNameDocument = gql`
    mutation UpdateTenantStripeCompanyName($companyName: String!) {
  updateTenantStripeCompanyName(data: {companyName: $companyName}) {
    id
  }
}
    `;
export type UpdateTenantStripeCompanyNameMutationFn = Apollo.MutationFunction<UpdateTenantStripeCompanyNameMutation, UpdateTenantStripeCompanyNameMutationVariables>;

/**
 * __useUpdateTenantStripeCompanyNameMutation__
 *
 * To run a mutation, you first call `useUpdateTenantStripeCompanyNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantStripeCompanyNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantStripeCompanyNameMutation, { data, loading, error }] = useUpdateTenantStripeCompanyNameMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useUpdateTenantStripeCompanyNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantStripeCompanyNameMutation, UpdateTenantStripeCompanyNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantStripeCompanyNameMutation, UpdateTenantStripeCompanyNameMutationVariables>(UpdateTenantStripeCompanyNameDocument, options);
      }
export type UpdateTenantStripeCompanyNameMutationHookResult = ReturnType<typeof useUpdateTenantStripeCompanyNameMutation>;
export type UpdateTenantStripeCompanyNameMutationResult = Apollo.MutationResult<UpdateTenantStripeCompanyNameMutation>;
export type UpdateTenantStripeCompanyNameMutationOptions = Apollo.BaseMutationOptions<UpdateTenantStripeCompanyNameMutation, UpdateTenantStripeCompanyNameMutationVariables>;
export const GetStripeInvoicesDocument = gql`
    query GetStripeInvoices {
  stripeInvoices {
    id
    amountDue
    amountRemaining
    amountPaid
    currency
    paid
    created
    periodStart
    brand
    last4
    usageAmount
    periodEnd
    status
    dueDate
    invoicePdf
    usageRecord {
      id
      memberQuantity
      prePaidMemberQuantity
      excessMemberQuantity
      numberOfEventDefinitions
      numberOfShufflEvents
      priceExcessTierMax
      priceExcessTierMin
      priceTierMax
      priceTierMin
      cost
      excessCost
      reportUrl
    }
  }
}
    `;

/**
 * __useGetStripeInvoicesQuery__
 *
 * To run a query within a React component, call `useGetStripeInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeInvoicesQuery, GetStripeInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeInvoicesQuery, GetStripeInvoicesQueryVariables>(GetStripeInvoicesDocument, options);
      }
export function useGetStripeInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeInvoicesQuery, GetStripeInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeInvoicesQuery, GetStripeInvoicesQueryVariables>(GetStripeInvoicesDocument, options);
        }
export type GetStripeInvoicesQueryHookResult = ReturnType<typeof useGetStripeInvoicesQuery>;
export type GetStripeInvoicesLazyQueryHookResult = ReturnType<typeof useGetStripeInvoicesLazyQuery>;
export type GetStripeInvoicesQueryResult = Apollo.QueryResult<GetStripeInvoicesQuery, GetStripeInvoicesQueryVariables>;
export function refetchGetStripeInvoicesQuery(variables?: GetStripeInvoicesQueryVariables) {
      return { query: GetStripeInvoicesDocument, variables: variables }
    }
export const GetStripeUpcomingInvoiceDocument = gql`
    query GetStripeUpcomingInvoice {
  stripeUpcomingInvoice {
    id
    amountDue
    amountRemaining
    amountPaid
    currency
    paid
    periodStart
    usageAmount
    periodEnd
    status
    dueDate
    hostedInvoiceUrl
  }
}
    `;

/**
 * __useGetStripeUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `useGetStripeUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeUpcomingInvoiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeUpcomingInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeUpcomingInvoiceQuery, GetStripeUpcomingInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeUpcomingInvoiceQuery, GetStripeUpcomingInvoiceQueryVariables>(GetStripeUpcomingInvoiceDocument, options);
      }
export function useGetStripeUpcomingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeUpcomingInvoiceQuery, GetStripeUpcomingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeUpcomingInvoiceQuery, GetStripeUpcomingInvoiceQueryVariables>(GetStripeUpcomingInvoiceDocument, options);
        }
export type GetStripeUpcomingInvoiceQueryHookResult = ReturnType<typeof useGetStripeUpcomingInvoiceQuery>;
export type GetStripeUpcomingInvoiceLazyQueryHookResult = ReturnType<typeof useGetStripeUpcomingInvoiceLazyQuery>;
export type GetStripeUpcomingInvoiceQueryResult = Apollo.QueryResult<GetStripeUpcomingInvoiceQuery, GetStripeUpcomingInvoiceQueryVariables>;
export function refetchGetStripeUpcomingInvoiceQuery(variables?: GetStripeUpcomingInvoiceQueryVariables) {
      return { query: GetStripeUpcomingInvoiceDocument, variables: variables }
    }
export const GetStripePaymentMethodsDocument = gql`
    query GetStripePaymentMethods {
  stripePaymentMethods {
    id
    last4
    expYear
    expMonth
    brand
    billingName
    billingAddressCity
    billingAddressCountry
    billingAddressLine1
    billingAddressLine2
    billingAddressPostalCode
    billingAddressState
    billingPhone
    billingEmail
  }
}
    `;

/**
 * __useGetStripePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetStripePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetStripePaymentMethodsQuery, GetStripePaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripePaymentMethodsQuery, GetStripePaymentMethodsQueryVariables>(GetStripePaymentMethodsDocument, options);
      }
export function useGetStripePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripePaymentMethodsQuery, GetStripePaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripePaymentMethodsQuery, GetStripePaymentMethodsQueryVariables>(GetStripePaymentMethodsDocument, options);
        }
export type GetStripePaymentMethodsQueryHookResult = ReturnType<typeof useGetStripePaymentMethodsQuery>;
export type GetStripePaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetStripePaymentMethodsLazyQuery>;
export type GetStripePaymentMethodsQueryResult = Apollo.QueryResult<GetStripePaymentMethodsQuery, GetStripePaymentMethodsQueryVariables>;
export function refetchGetStripePaymentMethodsQuery(variables?: GetStripePaymentMethodsQueryVariables) {
      return { query: GetStripePaymentMethodsDocument, variables: variables }
    }
export const UpdateTenantStripePaymentMethodDocument = gql`
    mutation UpdateTenantStripePaymentMethod($paymentMethodId: String!) {
  updateTenantStripePaymentMethod(data: {paymentMethodId: $paymentMethodId}) {
    id
  }
}
    `;
export type UpdateTenantStripePaymentMethodMutationFn = Apollo.MutationFunction<UpdateTenantStripePaymentMethodMutation, UpdateTenantStripePaymentMethodMutationVariables>;

/**
 * __useUpdateTenantStripePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateTenantStripePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantStripePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantStripePaymentMethodMutation, { data, loading, error }] = useUpdateTenantStripePaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateTenantStripePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantStripePaymentMethodMutation, UpdateTenantStripePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantStripePaymentMethodMutation, UpdateTenantStripePaymentMethodMutationVariables>(UpdateTenantStripePaymentMethodDocument, options);
      }
export type UpdateTenantStripePaymentMethodMutationHookResult = ReturnType<typeof useUpdateTenantStripePaymentMethodMutation>;
export type UpdateTenantStripePaymentMethodMutationResult = Apollo.MutationResult<UpdateTenantStripePaymentMethodMutation>;
export type UpdateTenantStripePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateTenantStripePaymentMethodMutation, UpdateTenantStripePaymentMethodMutationVariables>;
export const GetStripePriceDocument = gql`
    query GetStripePrice($priceLookupKey: StripePriceLookupKey!) {
  stripePrice(priceLookupKey: $priceLookupKey) {
    id
    lookupKey
    amount
    interval
    tiers {
      upTo
      flatAmount
    }
    metadata {
      description
      name
      tier
      minUserLimit
      maxUserLimit
    }
  }
}
    `;

/**
 * __useGetStripePriceQuery__
 *
 * To run a query within a React component, call `useGetStripePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePriceQuery({
 *   variables: {
 *      priceLookupKey: // value for 'priceLookupKey'
 *   },
 * });
 */
export function useGetStripePriceQuery(baseOptions: Apollo.QueryHookOptions<GetStripePriceQuery, GetStripePriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripePriceQuery, GetStripePriceQueryVariables>(GetStripePriceDocument, options);
      }
export function useGetStripePriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripePriceQuery, GetStripePriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripePriceQuery, GetStripePriceQueryVariables>(GetStripePriceDocument, options);
        }
export type GetStripePriceQueryHookResult = ReturnType<typeof useGetStripePriceQuery>;
export type GetStripePriceLazyQueryHookResult = ReturnType<typeof useGetStripePriceLazyQuery>;
export type GetStripePriceQueryResult = Apollo.QueryResult<GetStripePriceQuery, GetStripePriceQueryVariables>;
export function refetchGetStripePriceQuery(variables?: GetStripePriceQueryVariables) {
      return { query: GetStripePriceDocument, variables: variables }
    }
export const GetStripeSubscriptionDocument = gql`
    query GetStripeSubscription {
  stripeSubscription {
    id
    currentPeriodEnd
    cost
    prePaidCost
    prePaidMemberQuantity
    priceTier {
      min
      max
    }
    items {
      data {
        quantity
      }
    }
    price {
      lookupKey
      amount
      tiers {
        upTo
        flatAmount
      }
      metadata {
        name
        tier
        maxUserLimit
      }
    }
  }
}
    `;

/**
 * __useGetStripeSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetStripeSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeSubscriptionQuery, GetStripeSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeSubscriptionQuery, GetStripeSubscriptionQueryVariables>(GetStripeSubscriptionDocument, options);
      }
export function useGetStripeSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeSubscriptionQuery, GetStripeSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeSubscriptionQuery, GetStripeSubscriptionQueryVariables>(GetStripeSubscriptionDocument, options);
        }
export type GetStripeSubscriptionQueryHookResult = ReturnType<typeof useGetStripeSubscriptionQuery>;
export type GetStripeSubscriptionLazyQueryHookResult = ReturnType<typeof useGetStripeSubscriptionLazyQuery>;
export type GetStripeSubscriptionQueryResult = Apollo.QueryResult<GetStripeSubscriptionQuery, GetStripeSubscriptionQueryVariables>;
export function refetchGetStripeSubscriptionQuery(variables?: GetStripeSubscriptionQueryVariables) {
      return { query: GetStripeSubscriptionDocument, variables: variables }
    }
export const UpdateTenantStripeSubscriptionDocument = gql`
    mutation UpdateTenantStripeSubscription($priceLookupKey: StripePriceLookupKey!) {
  updateTenantStripeSubscription(data: {priceLookupKey: $priceLookupKey}) {
    id
  }
}
    `;
export type UpdateTenantStripeSubscriptionMutationFn = Apollo.MutationFunction<UpdateTenantStripeSubscriptionMutation, UpdateTenantStripeSubscriptionMutationVariables>;

/**
 * __useUpdateTenantStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTenantStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantStripeSubscriptionMutation, { data, loading, error }] = useUpdateTenantStripeSubscriptionMutation({
 *   variables: {
 *      priceLookupKey: // value for 'priceLookupKey'
 *   },
 * });
 */
export function useUpdateTenantStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantStripeSubscriptionMutation, UpdateTenantStripeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantStripeSubscriptionMutation, UpdateTenantStripeSubscriptionMutationVariables>(UpdateTenantStripeSubscriptionDocument, options);
      }
export type UpdateTenantStripeSubscriptionMutationHookResult = ReturnType<typeof useUpdateTenantStripeSubscriptionMutation>;
export type UpdateTenantStripeSubscriptionMutationResult = Apollo.MutationResult<UpdateTenantStripeSubscriptionMutation>;
export type UpdateTenantStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateTenantStripeSubscriptionMutation, UpdateTenantStripeSubscriptionMutationVariables>;
export const GetTenantDocument = gql`
    query GetTenant {
  tenant {
    id
    createdAt
    isEligibleForTrial
    isPayingOrTrialing
    isPaying
    isTrialing
    timezone
    trialEndAt
    trialStartAt
    price
    payingStartAt
    currentBillingPeriod {
      periodEnd
      periodStart
    }
    slackMetadata {
      id
      appId
      permissions {
        createConversation
      }
      team {
        icon
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
      }
export function useGetTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
        }
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export function refetchGetTenantQuery(variables?: GetTenantQueryVariables) {
      return { query: GetTenantDocument, variables: variables }
    }
export const GetTenantWithCreatedByDocument = gql`
    query GetTenantWithCreatedBy {
  tenant {
    id
    createdAt
    timezone
    isEligibleForTrial
    isPayingOrTrialing
    isPaying
    isTrialing
    trialEndAt
    trialStartAt
    price
    payingStartAt
    currentBillingPeriod {
      periodEnd
      periodStart
    }
    createdBy {
      id
      name
      email
    }
    slackMetadata {
      id
      appId
      permissions {
        createConversation
      }
      team {
        icon
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetTenantWithCreatedByQuery__
 *
 * To run a query within a React component, call `useGetTenantWithCreatedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantWithCreatedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantWithCreatedByQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantWithCreatedByQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantWithCreatedByQuery, GetTenantWithCreatedByQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantWithCreatedByQuery, GetTenantWithCreatedByQueryVariables>(GetTenantWithCreatedByDocument, options);
      }
export function useGetTenantWithCreatedByLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantWithCreatedByQuery, GetTenantWithCreatedByQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantWithCreatedByQuery, GetTenantWithCreatedByQueryVariables>(GetTenantWithCreatedByDocument, options);
        }
export type GetTenantWithCreatedByQueryHookResult = ReturnType<typeof useGetTenantWithCreatedByQuery>;
export type GetTenantWithCreatedByLazyQueryHookResult = ReturnType<typeof useGetTenantWithCreatedByLazyQuery>;
export type GetTenantWithCreatedByQueryResult = Apollo.QueryResult<GetTenantWithCreatedByQuery, GetTenantWithCreatedByQueryVariables>;
export function refetchGetTenantWithCreatedByQuery(variables?: GetTenantWithCreatedByQueryVariables) {
      return { query: GetTenantWithCreatedByDocument, variables: variables }
    }
export const GetHighestUsageRecordForCurrentBillingPeriodDocument = gql`
    query GetHighestUsageRecordForCurrentBillingPeriod {
  highestUsageRecordForCurrentBillingPeriod {
    id
    memberQuantity
    numberOfEventDefinitions
    numberOfShufflEvents
    prePaidMemberQuantity
    prePaidCost
    billingPeriodEnd
    billingPeriodStart
    excessMemberQuantity
    priceExcessTierMax
    priceExcessTierMin
    priceTierMax
    priceTierMin
    reportUrl
    price
    cost
    excessCost
  }
}
    `;

/**
 * __useGetHighestUsageRecordForCurrentBillingPeriodQuery__
 *
 * To run a query within a React component, call `useGetHighestUsageRecordForCurrentBillingPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighestUsageRecordForCurrentBillingPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighestUsageRecordForCurrentBillingPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHighestUsageRecordForCurrentBillingPeriodQuery(baseOptions?: Apollo.QueryHookOptions<GetHighestUsageRecordForCurrentBillingPeriodQuery, GetHighestUsageRecordForCurrentBillingPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHighestUsageRecordForCurrentBillingPeriodQuery, GetHighestUsageRecordForCurrentBillingPeriodQueryVariables>(GetHighestUsageRecordForCurrentBillingPeriodDocument, options);
      }
export function useGetHighestUsageRecordForCurrentBillingPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHighestUsageRecordForCurrentBillingPeriodQuery, GetHighestUsageRecordForCurrentBillingPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHighestUsageRecordForCurrentBillingPeriodQuery, GetHighestUsageRecordForCurrentBillingPeriodQueryVariables>(GetHighestUsageRecordForCurrentBillingPeriodDocument, options);
        }
export type GetHighestUsageRecordForCurrentBillingPeriodQueryHookResult = ReturnType<typeof useGetHighestUsageRecordForCurrentBillingPeriodQuery>;
export type GetHighestUsageRecordForCurrentBillingPeriodLazyQueryHookResult = ReturnType<typeof useGetHighestUsageRecordForCurrentBillingPeriodLazyQuery>;
export type GetHighestUsageRecordForCurrentBillingPeriodQueryResult = Apollo.QueryResult<GetHighestUsageRecordForCurrentBillingPeriodQuery, GetHighestUsageRecordForCurrentBillingPeriodQueryVariables>;
export function refetchGetHighestUsageRecordForCurrentBillingPeriodQuery(variables?: GetHighestUsageRecordForCurrentBillingPeriodQueryVariables) {
      return { query: GetHighestUsageRecordForCurrentBillingPeriodDocument, variables: variables }
    }
export const SetOptStateDocument = gql`
    mutation SetOptState($userEventDefinitionId: ID!, $optState: UserEventDefinitionOptState!) {
  setOptState(
    userEventDefinitionId: $userEventDefinitionId
    data: {optState: $optState}
  ) {
    id
  }
}
    `;
export type SetOptStateMutationFn = Apollo.MutationFunction<SetOptStateMutation, SetOptStateMutationVariables>;

/**
 * __useSetOptStateMutation__
 *
 * To run a mutation, you first call `useSetOptStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOptStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOptStateMutation, { data, loading, error }] = useSetOptStateMutation({
 *   variables: {
 *      userEventDefinitionId: // value for 'userEventDefinitionId'
 *      optState: // value for 'optState'
 *   },
 * });
 */
export function useSetOptStateMutation(baseOptions?: Apollo.MutationHookOptions<SetOptStateMutation, SetOptStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOptStateMutation, SetOptStateMutationVariables>(SetOptStateDocument, options);
      }
export type SetOptStateMutationHookResult = ReturnType<typeof useSetOptStateMutation>;
export type SetOptStateMutationResult = Apollo.MutationResult<SetOptStateMutation>;
export type SetOptStateMutationOptions = Apollo.BaseMutationOptions<SetOptStateMutation, SetOptStateMutationVariables>;
export const GetUserEventDefinitionsDocument = gql`
    query GetUserEventDefinitions($after: String, $first: Int, $eventDefinitionId: String, $createdAtOrder: OrderBy) {
  userEventDefinitionConnection(
    after: $after
    first: $first
    where: {eventDefinitionId: $eventDefinitionId}
    order: {createdAt: $createdAtOrder}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        totalShuffls
        createdAt
        user {
          email
          id
          name
          isBioSet
          tags
          slackMetadata {
            id
            image
          }
        }
        lastShufflEvent {
          createdAt
          id
        }
        state
        optState
      }
    }
  }
}
    `;

/**
 * __useGetUserEventDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetUserEventDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEventDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEventDefinitionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      eventDefinitionId: // value for 'eventDefinitionId'
 *      createdAtOrder: // value for 'createdAtOrder'
 *   },
 * });
 */
export function useGetUserEventDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEventDefinitionsQuery, GetUserEventDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEventDefinitionsQuery, GetUserEventDefinitionsQueryVariables>(GetUserEventDefinitionsDocument, options);
      }
export function useGetUserEventDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEventDefinitionsQuery, GetUserEventDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEventDefinitionsQuery, GetUserEventDefinitionsQueryVariables>(GetUserEventDefinitionsDocument, options);
        }
export type GetUserEventDefinitionsQueryHookResult = ReturnType<typeof useGetUserEventDefinitionsQuery>;
export type GetUserEventDefinitionsLazyQueryHookResult = ReturnType<typeof useGetUserEventDefinitionsLazyQuery>;
export type GetUserEventDefinitionsQueryResult = Apollo.QueryResult<GetUserEventDefinitionsQuery, GetUserEventDefinitionsQueryVariables>;
export function refetchGetUserEventDefinitionsQuery(variables?: GetUserEventDefinitionsQueryVariables) {
      return { query: GetUserEventDefinitionsDocument, variables: variables }
    }
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: ID!, $role: UserRole!) {
  updateUser(userId: $userId, data: {role: $role}) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpsertUserDocument = gql`
    mutation UpsertUser($email: String!, $role: UserRole!) {
  upsertUser(data: {email: $email, role: $role}) {
    id
  }
}
    `;
export type UpsertUserMutationFn = Apollo.MutationFunction<UpsertUserMutation, UpsertUserMutationVariables>;

/**
 * __useUpsertUserMutation__
 *
 * To run a mutation, you first call `useUpsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserMutation, { data, loading, error }] = useUpsertUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpsertUserMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserMutation, UpsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(UpsertUserDocument, options);
      }
export type UpsertUserMutationHookResult = ReturnType<typeof useUpsertUserMutation>;
export type UpsertUserMutationResult = Apollo.MutationResult<UpsertUserMutation>;
export type UpsertUserMutationOptions = Apollo.BaseMutationOptions<UpsertUserMutation, UpsertUserMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetProfileDocument = gql`
    query GetProfile {
  profile {
    id
    intercomHashId
    createdAt
    updatedAt
    email
    firstName
    lastName
    role
    name
    phoneNumber
    slackMetadata {
      id
      slackUserId
      displayName
      realName
      image
    }
    impersonatedBy {
      id
      email
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export function refetchGetProfileQuery(variables?: GetProfileQueryVariables) {
      return { query: GetProfileDocument, variables: variables }
    }
export const GetAllUsersDocument = gql`
    query GetAllUsers($after: String, $first: Int, $name: String, $role: UserRole, $ids: [String!], $createdAtOrder: OrderBy) {
  userConnection(
    after: $after
    first: $first
    where: {name: $name, role: $role, ids: $ids}
    order: {createdAt: $createdAtOrder}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        email
        firstName
        lastName
        name
        role
        slackMetadata {
          id
          slackUserId
          displayName
          realName
          image
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      ids: // value for 'ids'
 *      createdAtOrder: // value for 'createdAtOrder'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export function refetchGetAllUsersQuery(variables?: GetAllUsersQueryVariables) {
      return { query: GetAllUsersDocument, variables: variables }
    }
export const GetAllAdminsDocument = gql`
    query GetAllAdmins($after: String, $first: Int, $name: String, $createdAtOrder: OrderBy) {
  adminConnection(
    after: $after
    first: $first
    where: {name: $name}
    order: {createdAt: $createdAtOrder}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        email
        firstName
        lastName
        name
        role
        slackMetadata {
          id
          slackUserId
          displayName
          realName
          image
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllAdminsQuery__
 *
 * To run a query within a React component, call `useGetAllAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAdminsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      createdAtOrder: // value for 'createdAtOrder'
 *   },
 * });
 */
export function useGetAllAdminsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAdminsQuery, GetAllAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAdminsQuery, GetAllAdminsQueryVariables>(GetAllAdminsDocument, options);
      }
export function useGetAllAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAdminsQuery, GetAllAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAdminsQuery, GetAllAdminsQueryVariables>(GetAllAdminsDocument, options);
        }
export type GetAllAdminsQueryHookResult = ReturnType<typeof useGetAllAdminsQuery>;
export type GetAllAdminsLazyQueryHookResult = ReturnType<typeof useGetAllAdminsLazyQuery>;
export type GetAllAdminsQueryResult = Apollo.QueryResult<GetAllAdminsQuery, GetAllAdminsQueryVariables>;
export function refetchGetAllAdminsQuery(variables?: GetAllAdminsQueryVariables) {
      return { query: GetAllAdminsDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query GetUser($userId: ID!) {
  user(userId: $userId) {
    id
    email
    firstName
    lastName
    name
    createdAt
    role
    slackMetadata {
      id
      slackUserId
      image
    }
    eventDefinitionsCreated {
      id
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const FindUserByEmailDocument = gql`
    query FindUserByEmail($email: String!) {
  userByEmail(email: $email) {
    id
  }
}
    `;

/**
 * __useFindUserByEmailQuery__
 *
 * To run a query within a React component, call `useFindUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserByEmailQuery, FindUserByEmailQueryVariables>(FindUserByEmailDocument, options);
      }
export function useFindUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserByEmailQuery, FindUserByEmailQueryVariables>(FindUserByEmailDocument, options);
        }
export type FindUserByEmailQueryHookResult = ReturnType<typeof useFindUserByEmailQuery>;
export type FindUserByEmailLazyQueryHookResult = ReturnType<typeof useFindUserByEmailLazyQuery>;
export type FindUserByEmailQueryResult = Apollo.QueryResult<FindUserByEmailQuery, FindUserByEmailQueryVariables>;
export function refetchFindUserByEmailQuery(variables?: FindUserByEmailQueryVariables) {
      return { query: FindUserByEmailDocument, variables: variables }
    }