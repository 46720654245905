import React from 'react';
import './index.css';

const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="container mx-auto p-6 text-xs flex flex-col md:flex-row items-center font-semibold">
        <span className="mb-4 md:mb-0 md:mr-auto">&copy; {new Date().getFullYear()} Shuffl. All Rights Reserved</span>
        <div
          className="cursor-pointer md:mr-4 hover:text-orange-500 hover:underline"
          onClick={() => {
            const { Intercom } = window;

            Intercom('showNewMessage');
          }}
        >
          Contact
        </div>
        <a href="https://shuffl.ai/privacy" className="md:mr-4 hover:text-orange-500 hover:underline">
          Privacy Policy
        </a>
        <a href="https://shuffl.ai/terms" className="hover:text-orange-500 hover:underline">
          Terms of Service
        </a>
      </div>
    </footer>
  );
};

export default Footer;
