import React from 'react';
import { Page } from '../../components/Page';
import { useUser } from '../../context/user-context';

export const Unauthorized = () => {
  const { userQuery } = useUser();

  return (
    <Page header="Unauthorized" hideNavLinks={true}>
      <div className="flex flex-1 justify-center items-center relative h-full">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Hi there {userQuery.data?.profile.firstName}!
            </h3>
            <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
              <p className="mb-2">
                Currently, Shuffl Portal access is available only for your team&apos;s Shuffl Admin.
              </p>
              <p className="mb-4">
                If you believe you have reached this page in error, please contact{' '}
                <a
                  href="mailto:support@shuffl.ai?subject=Unauthorized"
                  className="text-orange-500 hover:underline cursor-pointer"
                >
                  support@shuffl.ai
                </a>
              </p>
              <p>Best, Shuffl</p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
