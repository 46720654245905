import React from 'react';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useUser } from '../../context/user-context';
import { useGetAllEventDefinitionsQuery } from '../../generated/graphql';
import { Channels } from './Channels';
import { EventDefinitionsProvider } from './context/event-definitions-context';

export const ChannelsContainer = () => {
  const eventDefinitionsQuery = useGetAllEventDefinitionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 25,
      // query: searchParams.get('query') ?? undefined,
      // state: EventDefinitionState.Enabled,
    },
  });
  const { isPayingOrTrialing, tenantQuery } = useUser();

  return (
    <EventDefinitionsProvider>
      <IsLoadingProvider isLoading={eventDefinitionsQuery.loading}>
        <Channels
          tenantQuery={tenantQuery}
          eventDefinitionsQuery={eventDefinitionsQuery}
          isPayingOrTrialing={isPayingOrTrialing}
        />
      </IsLoadingProvider>
    </EventDefinitionsProvider>
  );
};
