import React, { ReactElement } from 'react';
import { useField } from 'formik';
import {
  SelectMenu as TailwindSelectMenu,
  SelectMenuProps as TailwindSelectMenuProps,
  SelectMenuItem,
  SelectMenuItemProps,
} from '@shuffl/tailwind-ui-react';

export interface SelectMenuProps extends TailwindSelectMenuProps {
  label: string;
  name: string;
  children: ReactElement<SelectMenuItemProps>[];
}

export const SelectMenu = ({ name, children, ...rest }: SelectMenuProps) => {
  const [field] = useField({ name });

  return (
    <TailwindSelectMenu {...field} {...rest}>
      {children}
    </TailwindSelectMenu>
  );
};

SelectMenu.Item = SelectMenuItem;
