import { object, InferType, string } from 'yup';

export const PaymentSchema = object()
  .shape({
    companyName: string().required('Company Name is required'),
    nameOnCC: string().required('Card Holder Name is required'),
  })
  .defined();

export type FormData = InferType<typeof PaymentSchema>;
