import React, { ReactElement, ReactNode } from 'react';
import { FormikHandlers, useField } from 'formik';
import { TextArea as TailwindTextArea } from '@shuffl/tailwind-ui-react';

export interface TextAreaProps {
  name: string;
  label: ReactNode;
  hideLabel?: boolean;
  id?: string;
  className?: string;
  rows?: number;
  type?: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  showSpinButtons?: boolean;
  value?: string;
  error?: string;
  autoComplete?: string;
  required?: boolean;
  icon?: ReactElement;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onChange?: FormikHandlers['handleChange'];
  onBlur?: FormikHandlers['handleBlur'];
}

export const TextArea = ({ name, error, ...rest }: TextAreaProps) => {
  const [field, meta] = useField({ name });
  let errorMessage = meta.touched && !!meta.error ? meta.error : undefined;
  errorMessage = errorMessage ?? error;

  return <TailwindTextArea error={errorMessage} {...field} {...rest} />;
};
