import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Button } from '@shuffl/tailwind-ui-react';
import DefaultStep from '../DefaultStep';

import { useUser } from '../../../../context/user-context';
import { useGetIsOnboardingInstanceCompletedQuery } from '../../../../generated/graphql';

export interface FinishedOnboardingProps {
  scheduleToString: string;
}

export const FinishedOnboarding = (props: FinishedOnboardingProps) => {
  const step = { isCompleted: true };
  const navigate = useNavigate();
  const { updateTenant } = useUser();
  const stepDefinition = { displayName: 'Congratulations! You have completed onboarding!' };
  const onboardingQuery = useGetIsOnboardingInstanceCompletedQuery();
  return (
    <DefaultStep
      isCurrentStep={true}
      step={step}
      stepDefinition={stepDefinition}
      content={
        <div>
          <div>
            Your first shuffl will be on {props.scheduleToString}{' '}
            <span role="img" aria-label="emoji tada">
              🎉
            </span>
          </div>
        </div>
      }
      accessory={
        <Button
          buttonStyle="primary"
          onClick={async () => {
            await updateTenant();
            await onboardingQuery.refetch();
            navigate('/channels');
          }}
        >
          Go to Shuffl Portal
        </Button>
      }
      {...props}
    />
  );
};
