import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { PaymentMethodCreateParams, StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { Form, Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { startCase } from 'lodash';
import { ExternalLink, InputGroup } from '@shuffl/tailwind-ui-react';
import {
  StripePriceLookupKey,
  useUpdateTenantStripePaymentMethodMutation,
  useUpdateTenantStripeCompanyNameMutation,
} from '../../../../generated/graphql';
import { SubmitButton } from '../../../../components/Form';
import { useUser } from '../../../../context/user-context';
import { ReactComponent as StripeLogo } from '../../../../images/stripe/outline-dark.svg';
import { FormData, PaymentSchema } from './form-schema';

export interface StripePaymentFormProps {
  price: StripePriceLookupKey;
  onSubmit?: () => Promise<void>;
}

export const StripePaymentForm = ({ price, onSubmit }: StripePaymentFormProps) => {
  const { addToast } = useToasts();
  const stripe = useStripe();
  const { userQuery } = useUser();
  const elements = useElements();

  const [updatePaymentMethod] = useUpdateTenantStripePaymentMethodMutation({
    onError: (error) => {
      addToast('Could not update payment method', { appearance: 'error' });
      console.error('An error occurred trying to update tenant stripe payment method', error);
    },
  });

  const [updateCompanyName] = useUpdateTenantStripeCompanyNameMutation({
    onError: (error) => {
      addToast('Could not update company name', { appearance: 'error' });
      console.error('An error occurred trying to update tenant stripe company name', error);
    },
  });

  const formattedPriceName = startCase(price.toLowerCase());
  const user = userQuery.data?.profile;

  const elementOptions = {
    classes: {
      base: 'rounded-md sm:text-sm w-full block shadow-sm border border-gray-300 py-2 px-3 text-gray-900 mt-1',
      focus: 'ring-purple-500 border-purple-500 border-2',
      invalid: 'text-red-900 placeholder-red-300 outline-none ring-red-500 border-red-500',
    },
    iconStyle: 'solid',
    showIcon: true,
    style: {
      base: {
        color: '#111827',
        fontFamily: 'Inter Var, sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        fontWeight: '400',
        lineHeight: '20px',
      },
      invalid: {
        color: '#7f1d1d',
        iconColor: '#ef4444',
      },
    },
  } as StripeCardNumberElementOptions;

  return (
    <div className="mt-6">
      <Formik
        validationSchema={PaymentSchema}
        initialValues={{}}
        onSubmit={async (submitData: FormData) => {
          const cardElement = elements?.getElement(CardElement);
          if (!stripe || !elements || !cardElement) {
            // show error;
            throw new Error('Stripe and elements must be defined');
          }

          let billingDetails = {
            name: submitData.nameOnCC ?? user?.name,
          } as PaymentMethodCreateParams.BillingDetails;

          if (user?.email) {
            billingDetails = {
              ...billingDetails,
              email: user.email,
            };
          }
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            billing_details: billingDetails,
            card: cardElement,
            type: 'card',
          });

          if (error || !paymentMethod) {
            addToast('Could create a new payment method', { appearance: 'error' });
            console.error('An error occurred trying to create payment method in stripe', error);
            return;
          }

          await updatePaymentMethod({ variables: { paymentMethodId: paymentMethod.id } });
          await updateCompanyName({ variables: { companyName: submitData.companyName } });

          if (onSubmit) {
            await onSubmit();
          }
        }}
      >
        <Form>
          <div className="mb-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <InputGroup label="Card Holder Name" name="nameOnCC" autoComplete="given-name" required />
            </div>
            <div className="sm:col-span-3">
              <InputGroup label="Company Name" name="companyName" required />
            </div>
            <div className="sm:col-span-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
              <div className="sm:col-span-12">
                <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
                  Credit Card Number
                </label>
                <CardElement id="cardNumber" options={elementOptions} />
              </div>
              <div className="sm:col-span-4 flex items-end">
                <StripeLogo />
              </div>
            </div>
          </div>
          <div className="flex mb-8 text-sm text-gray-500 space-x-1">
            <span>By submitting a payment, you acknowledge you have read, and agree to our </span>
            <ExternalLink href="https://shuffl.ai/terms-of-service/" size="sm">
              terms of service
            </ExternalLink>
          </div>
          <SubmitButton disabled={!stripe}>Upgrade to {formattedPriceName}</SubmitButton>
        </Form>
      </Formik>
    </div>
  );
};
