import React from 'react';
import moment from 'moment-timezone';
import { Table, Panel, Header, Link, Badge, LearningHubLink, MediaObject, Button } from '@shuffl/tailwind-ui-react';

import { Page } from '../../components/Page';
import {
  EventDefinition,
  EventDefinitionState,
  GetAllEventDefinitionsQueryHookResult,
  GetTenantQueryHookResult,
  StripePriceLookupKey,
} from '../../generated/graphql';
import { ReactComponent as UndrawSelectingTeamImage } from '../../images/undraw/selecting-team.svg';

export interface ChannelsProps {
  eventDefinitionsQuery: GetAllEventDefinitionsQueryHookResult;
  tenantQuery: GetTenantQueryHookResult;
  isPayingOrTrialing: boolean;
}

export const Channels = ({ eventDefinitionsQuery, tenantQuery, isPayingOrTrialing }: ChannelsProps) => {
  const eventDefinitions = eventDefinitionsQuery.data?.eventDefinitionConnection.edges
    ?.map((edge) => edge?.node)
    .filter((eventDefinition) => !!eventDefinition) as EventDefinition[];
  const tenant = tenantQuery.data?.tenant;
  const slackTeamId = tenant?.slackMetadata?.team.id ?? '';
  const slackAppId = tenant?.slackMetadata?.appId ?? '';
  const useNativeClient = true;
  const prefixLink = useNativeClient ? 'slack://app' : 'https://slack.com/app_redirect?';

  return (
    <Page>
      <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6">
        <div className="lg:col-span-6">
          <Panel padding>
            <div className="flex">
              <div className="mr-auto">
                <div className="flex flex-col space-y-4">
                  <Header>Shuffl Channels</Header>
                  <LearningHubLink articleId="5125690-shuffl-channels" size="sm">
                    Learn more about Shuffl Channels in our Learning Hub
                  </LearningHubLink>
                </div>
              </div>
              {isPayingOrTrialing && (
                <div>
                  {/* <Button to={`add/channel`}>Add Channel</Button> */}
                  <Button url={`${prefixLink}?team=${slackTeamId}&id=${slackAppId}&tab=home`}>Add Channel</Button>
                </div>
              )}
            </div>
          </Panel>
        </div>

        <div className="lg:col-span-12">
          <Header type="h2" id="history" className="mb-2">
            Channels
          </Header>
          <Table
            isEmpty={(eventDefinitions?.length ?? 0) === 0}
            emptyText="No Channels"
            stickyHeaders
            height="fixed"
            onAtBottom={() => {
              if (
                eventDefinitionsQuery.data?.eventDefinitionConnection.pageInfo.hasNextPage &&
                !eventDefinitionsQuery.loading
              ) {
                eventDefinitionsQuery.fetchMore({
                  variables: {
                    after: eventDefinitionsQuery.data?.eventDefinitionConnection.pageInfo.endCursor,
                  },
                });
              }
            }}
          >
            <Table.Header>
              <Table.Header.Cell>Name</Table.Header.Cell>
              <Table.Header.Cell>Schedule</Table.Header.Cell>
              <Table.Header.Cell>Members Opted In</Table.Header.Cell>
              <Table.Header.Cell>Smart Shuffl</Table.Header.Cell>
              <Table.Header.Cell>Next Shuffl</Table.Header.Cell>
              <Table.Header.Cell>Last Shuffl</Table.Header.Cell>
            </Table.Header>
            <Table.Body>
              {eventDefinitions?.map((eventDefinition) => {
                const {
                  schedule: { scheduleToString },
                } = eventDefinition;
                // const nextRunDate = moment(eventDefinition.nextRunDate);
                const channelLinkTo = `${eventDefinition.id}/overview`;

                return (
                  <Table.Row key={eventDefinition.id}>
                    <Table.Cell>
                      <div className="flex flex-col">
                        <Link to={channelLinkTo}>#{eventDefinition.slackMetadata?.channel.name}</Link>
                        <div className="text-sm text-gray-500">
                          Created on {moment(eventDefinition.createdAt).format('LL')}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{scheduleToString}</Table.Cell>
                    <Table.Cell>{eventDefinition?.latestSnapshot?.users.optedIn.total ?? 0}</Table.Cell>
                    {/* <Table.Cell>{eventDefinition.usersOptedOut?.length}</Table.Cell> */}
                    <Table.Cell>{eventDefinition.shufflStrategyToString}</Table.Cell>
                    <Table.Cell>
                      {eventDefinition.state === EventDefinitionState.Enabled && (
                        <div>
                          {moment(eventDefinition.nextShufflEvent?.scheduledTime ?? eventDefinition.nextRunDate).format(
                            'MMM Do',
                          )}{' '}
                          (
                          {moment(
                            eventDefinition.nextShufflEvent?.scheduledTime ?? eventDefinition.nextRunDate,
                          ).fromNow()}
                          )
                        </div>
                      )}
                      {eventDefinition.state !== EventDefinitionState.Enabled && <Badge color="red">Paused</Badge>}
                    </Table.Cell>
                    <Table.Cell>
                      {eventDefinition.shufflEvents && eventDefinition.shufflEvents.length > 0 && (
                        <Link
                          to={`${eventDefinition.id}/history/${eventDefinition.shufflEvents[0].id}`}
                          disabled={!isPayingOrTrialing}
                        >
                          {moment(eventDefinition.shufflEvents[0].scheduledTime)
                            .tz(eventDefinition.shufflEvents[0].timezone)
                            .format('ll')}
                        </Link>
                      )}
                      {!eventDefinition.shufflEvents || (eventDefinition.shufflEvents.length === 0 && <div>Never</div>)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        {!isPayingOrTrialing && (
          <div className="lg:col-span-12">
            <Panel padding>
              <MediaObject header={'More Shuffl Channels'} media={<UndrawSelectingTeamImage />}>
                <div className="flex flex-col space-y-8">
                  <div>Available starting at $50/month.</div>
                  <div className="text-sm text-gray-500 space-y-4">
                    <p>
                      Add and create more shuffl channels with Shuffl Pro, a subscription that supports the whole team.
                    </p>
                    <p>
                      Start shufflin&apos; without limits in fun and smart ways to supercharge networking and cross team
                      collaboration.
                    </p>
                  </div>
                  <div className="space-y-4">
                    <LearningHubLink articleId="5125690-shuffl-channels" size="sm">
                      Learn more about Shuffl Channels in the docs in our Learning Hub
                    </LearningHubLink>
                    <div>
                      <Button to={`/account/billing/change/payment-details?price=${StripePriceLookupKey.ProMonthly}`}>
                        Upgrade team plan starting at $50/month
                      </Button>
                    </div>
                  </div>
                </div>
              </MediaObject>
            </Panel>
          </div>
        )}
      </div>
    </Page>
  );
};
