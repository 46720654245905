import React from 'react';
import { useUser } from '../../context/user-context';
import { AdminApp } from '../../apps/Admin';
import { UserApp } from '../../apps/User';
import { OnboardingApp as OnboardingV2App } from '../../apps/OnboardingV2';
import { OnboardingApp as OnboardingV3App } from '../../apps/OnboardingV3';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { FeatureFlagKey } from '../../generated/graphql';

export const Authenticated = () => {
  const { isOnboardingCompleted, isAdmin } = useUser();
  const onboardingV3FeatureFlag = useFeatureFlag({ key: FeatureFlagKey.OnboardingV3 });
  const isOnboardingV3Enabled = onboardingV3FeatureFlag.featureFlag?.isEnabled;

  return (
    <>
      {isOnboardingCompleted && isAdmin && <AdminApp />}
      {isOnboardingCompleted === false && isAdmin && isOnboardingV3Enabled === false && <OnboardingV2App />}
      {isOnboardingCompleted === false && isAdmin && isOnboardingV3Enabled === true && <OnboardingV3App />}
      {isAdmin === false && <UserApp />}
    </>
  );
};
