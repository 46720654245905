import React, { PropsWithChildren } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const apiKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(apiKey);

const StripeProviderWrapper = ({ children }: PropsWithChildren<any>) => (
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [{ cssSrc: 'https://rsms.me/inter/inter.css', family: 'Inter Var' }],
    }}
  >
    {children}
  </Elements>
);

export { StripeProviderWrapper as StripeProvider };
