import React, { PropsWithChildren, useEffect } from 'react';

import { Panel, Steps } from '@shuffl/tailwind-ui-react';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components/Page';
import { useUser } from '../../context/user-context';
import { OnboardingDefinitionStepId } from '../../generated/graphql';
import { useOnboarding } from './context';

export const Onboarding = ({ children }: PropsWithChildren<any>) => {
  const { onboardingInstanceQuery } = useOnboarding();
  const { userQuery } = useUser();
  const navigate = useNavigate();
  const user = userQuery.data?.profile;
  const stepsProgress = onboardingInstanceQuery.data?.onboardingInstance?.stepsProgress ?? [];
  const lastCompletedStep = onboardingInstanceQuery.data?.onboardingInstance?.lastCompletedStep;
  const definitionStepsProgress = onboardingInstanceQuery.data?.onboardingInstance?.onboardingDefinition.steps ?? [];

  const baseUrl = '/onboarding';
  const welcomeStepUrl = `${baseUrl}/welcome`;
  const channelStepUrl = `${baseUrl}/channel`;
  const membersStepUrl = `${baseUrl}/members`;
  const scheduleStepUrl = `${baseUrl}/schedule`;
  const confirmSettingsStepUrl = `${baseUrl}/confirm-settings`;
  // const matchWelcome = useMatch(welcomeStepUrl);
  // const matchChannel = useMatch(channelStepUrl);
  // const matchMembers = useMatch(membersStepUrl);
  // const matchSchedule = useMatch(scheduleStepUrl);
  // const matchFinish = useMatch(finishStepUrl);

  useEffect(() => {
    if (lastCompletedStep?.id === OnboardingDefinitionStepId.OnboardingV3AddToSlack) {
      navigate(welcomeStepUrl, { replace: true });
    } else if (lastCompletedStep?.id === OnboardingDefinitionStepId.OnboardingV3StartOnboarding) {
      navigate(channelStepUrl, { replace: true });
    } else if (lastCompletedStep?.id === OnboardingDefinitionStepId.OnboardingV3SelectChannel) {
      navigate(membersStepUrl, { replace: true });
    } else if (lastCompletedStep?.id === OnboardingDefinitionStepId.OnboardingV3SelectMembers) {
      navigate(scheduleStepUrl, { replace: true });
    } else if (lastCompletedStep?.id === OnboardingDefinitionStepId.OnboardingV3SelectSchedule) {
      navigate(confirmSettingsStepUrl, { replace: true });
    } else if (lastCompletedStep?.id === OnboardingDefinitionStepId.OnboardingV3ConfirmSettings) {
      navigate(confirmSettingsStepUrl, { replace: true });
    }
  }, [
    lastCompletedStep,
    navigate,
    welcomeStepUrl,
    channelStepUrl,
    membersStepUrl,
    scheduleStepUrl,
    confirmSettingsStepUrl,
  ]);

  const isStartOnboardingStepComplete = stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3StartOnboarding,
  )?.isCompleted;
  const isSelectChannelStepComplete = stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectChannel,
  )?.isCompleted;
  const isSelectMembersStepComplete = stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectMembers,
  )?.isCompleted;
  const isSelectScheduleStepComplete = stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3SelectSchedule,
  )?.isCompleted;
  const isConfirmedSettingsStepComplete = stepsProgress.find(
    (step) => step.id === OnboardingDefinitionStepId.OnboardingV3ConfirmSettings,
  )?.isCompleted;

  return (
    <Page hideNavLinks>
      <Panel padding={true}>
        <div className="flex flex-col align-center justify-center max-w-3xl mx-auto">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900 sm:truncate">
              Hi {user?.firstName}{' '}
              <span role="img" aria-label="emoji wave">
                👋
              </span>{' '}
              let&apos;s start shufflin&apos;!
            </h1>
            <div className="mt-2 max-w-4xl text-sm text-gray-500">Creating connection in our Future of Work</div>
          </div>
          <div className="mb-14">
            <Steps ariaLabel="Onboarding">
              <Steps.Step
                to={welcomeStepUrl}
                completed={isStartOnboardingStepComplete}
                disabled={isStartOnboardingStepComplete}
                title="Welcome"
              />
              <Steps.Step
                to={channelStepUrl}
                completed={isSelectChannelStepComplete}
                disabled={!isStartOnboardingStepComplete}
                title="Select Channel"
              />
              <Steps.Step
                to={membersStepUrl}
                completed={isSelectMembersStepComplete}
                disabled={!isStartOnboardingStepComplete || !isSelectChannelStepComplete}
                title="Select Members"
              />
              <Steps.Step
                to={scheduleStepUrl}
                completed={isSelectScheduleStepComplete}
                disabled={
                  !isStartOnboardingStepComplete || !isSelectChannelStepComplete || !isSelectMembersStepComplete
                }
                title="Select Schedule"
              />
              <Steps.Step
                to={confirmSettingsStepUrl}
                completed={isConfirmedSettingsStepComplete}
                disabled={
                  !isStartOnboardingStepComplete ||
                  !isSelectChannelStepComplete ||
                  !isSelectMembersStepComplete ||
                  !isSelectScheduleStepComplete
                }
                title="Confirm Settings"
              />
            </Steps>
          </div>
          <div>{children}</div>
        </div>
      </Panel>
    </Page>
  );
};
