import React from 'react';
import { UserGroupIcon, UsersIcon, MinusCircleIcon, DocumentReportIcon } from '@heroicons/react/outline';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { sortBy, startCase, orderBy } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import Avatar from 'react-avatar';
import {
  Header,
  Stats,
  Panel,
  Link,
  Badge,
  DescriptionList,
  Button,
  IsLoadingProvider,
} from '@shuffl/tailwind-ui-react';

import {
  GetShufflEventQueryHookResult,
  GetGroupsForShufflEventQueryHookResult,
  useUpdateShufflEventGroupReportMutation,
  Group,
} from '../../../../../generated/graphql';

export interface ShufflProps {
  shufflEventQuery: GetShufflEventQueryHookResult;
  groupsForShufflEventQuery: GetGroupsForShufflEventQueryHookResult;
}

export const Shuffl = ({ shufflEventQuery, groupsForShufflEventQuery }: ShufflProps) => {
  const shufflEvent = shufflEventQuery.data?.shufflEvent;
  const groups = groupsForShufflEventQuery.data?.groupConnection.edges
    ?.map((edge) => edge?.node)
    .filter((group) => !!group) as Group[];
  const [getGroupReport] = useUpdateShufflEventGroupReportMutation();
  const numberFormatter = new Intl.NumberFormat('en-US');
  const shufflEventScheduleTime = moment(shufflEvent?.scheduledTime).tz(shufflEvent?.timezone ?? 'UTC');

  return (
    <>
      <IsLoadingProvider isLoading={shufflEventQuery.loading}>
        <div className="lg:col-span-12 lg:row-start-1">
          <Panel padding>
            <div className="pb-5 border-b border-gray-200 flex items-center justify-between">
              {/* <Header type="h3">Groups shuffl&apos;d on {moment(shufflEvent?.scheduledTime).format('ll')}</Header> */}
              <Header type="h3">Shuffl Summary for {shufflEventScheduleTime.format('ll')}</Header>
              <Button
                onClick={async () => {
                  if (!shufflEvent) {
                    return;
                  }

                  const groupReport = await getGroupReport({
                    variables: {
                      shufflEventId: shufflEvent.id,
                    },
                  });

                  if (!groupReport.data) {
                    return;
                  }

                  const blob = new Blob([groupReport.data.groupReport], { type: 'text/plain;charset=utf-8' });

                  saveAs(
                    blob,
                    `${moment(shufflEvent?.scheduledTime)
                      .tz(shufflEvent?.timezone)
                      .format('yyyy-MM-DD')}-group-report.csv`,
                  );
                }}
              >
                <div className="flex items-center">
                  <span className="pr-2">Group Report</span>
                  <DocumentReportIcon className="w-5 h-5" />
                </div>
              </Button>
            </div>
            <div className="w-full smw-1/2">
              <DescriptionList>
                <DescriptionList.Item name="Shuffl Time">
                  <span>
                    {shufflEventScheduleTime.format('MMM Do')} at {shufflEventScheduleTime.format('h:mm A zz')}
                  </span>
                </DescriptionList.Item>
                <DescriptionList.Item name="Smart Shuffl">
                  {shufflEvent?.eventSnapshot?.shufflStrategyToString}
                </DescriptionList.Item>
                {(shufflEvent?.eventSnapshot?.tags?.length ?? 0) > 0 && (
                  <DescriptionList.Item name="Tags">
                    {shufflEvent?.eventSnapshot?.tags?.map((tag) => {
                      return (
                        <Badge key={tag} color="purple">
                          {startCase(tag)}
                        </Badge>
                      );
                    })}
                  </DescriptionList.Item>
                )}
              </DescriptionList>
            </div>
          </Panel>
        </div>
        <div className="lg:col-span-12 lg:row-start-2">
          <div className="grid grid-col-1 lg:grid-cols-12 lg:grid-rows-1 gap-8 lg:gap-y-8 lg:gap-x-6 lg:col-span-9 lg:row-start-2">
            <div className="lg:col-span-4 lg:row-start-1">
              <Stats
                icon={<UsersIcon className="h-5 w-5" />}
                stat={numberFormatter.format(shufflEvent?.eventSnapshot?.users.shuffled.total ?? 0)}
                title="Members Shuffl'd"
              />
            </div>
            <div className="lg:col-span-4 lg:row-start-1">
              <Stats
                icon={<MinusCircleIcon className="h-5 w-5" />}
                stat={numberFormatter.format(shufflEvent?.eventSnapshot?.users.optedOut.total ?? 0)}
                title="Members Opted Out"
              />
            </div>
            <div className="lg:col-span-4 lg:row-start-1">
              <Stats
                icon={<UserGroupIcon className="h-5 w-5" />}
                stat={numberFormatter.format(shufflEvent?.eventSnapshot?.groups.created.total ?? 0)}
                title="Groups Created"
              />
            </div>
          </div>
        </div>
      </IsLoadingProvider>
      <div className="lg:col-span-12 lg:row-start-3">
        <Header id="groups" type="h2">
          Groups
        </Header>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {groupsForShufflEventQuery.loading && (
            <>
              <li className="col-span-1 divide-y divide-gray-200">
                <Panel padding>
                  <div className="flex flex-col space-y-2">
                    <Skeleton count={5} />
                  </div>
                </Panel>
              </li>
              <li className="col-span-1 divide-y divide-gray-200">
                <Panel padding>
                  <div className="flex flex-col space-y-2">
                    <Skeleton count={5} />
                  </div>
                </Panel>
              </li>
              <li className="col-span-1 divide-y divide-gray-200">
                <Panel padding>
                  <div className="flex flex-col space-y-2">
                    <Skeleton count={5} />
                  </div>
                </Panel>
              </li>
            </>
          )}
          {!groupsForShufflEventQuery.loading &&
            orderBy(groups ?? [], ['users.length'], ['desc']).map((group, index) => {
              return (
                <li className="col-span-1 divide-y divide-gray-200" key={group.id ?? index}>
                  <Panel padding>
                    <div className="flex flex-col space-y-2">
                      {sortBy(group.users ?? [], 'user.name').map((groupUser) => {
                        return (
                          <div className="flex justify-between items-center" key={groupUser.id}>
                            <div className="flex space-x-2 items-center">
                              <Link to={`/members/${groupUser.user.id}`}>
                                <Avatar
                                  email={groupUser?.user.email ?? ''}
                                  src={groupUser?.user.slackMetadata?.image ?? ''}
                                  alt={groupUser?.user.name}
                                  name={groupUser?.user.name}
                                  round={true}
                                  size={'25'}
                                  color="#7c3aed"
                                  className="mr-3"
                                />
                                <span>{groupUser.user.name}</span>
                              </Link>
                            </div>
                            {groupUser.matchedTags?.map((tag) => {
                              return (
                                <Badge key={tag} color="purple">
                                  {startCase(tag)}
                                </Badge>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};
