import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { usePrompt } from 'react-router-dom';
import { Header, Panel, Toggle } from '@shuffl/tailwind-ui-react';

import { SubmitButton } from '../../../../components/Form';
import {
  GetEventDefinitionQueryHookResult,
  GetTenantQueryHookResult,
  useUpdateEventDefinitionMutation,
} from '../../../../generated/graphql';
import { FormData, NotificationsSchema } from './form-schema';

export interface NotificationsProps {
  eventDefinitionQuery: GetEventDefinitionQueryHookResult;

  tenantQuery: GetTenantQueryHookResult;
}

export const Notifications = ({ eventDefinitionQuery, tenantQuery }: NotificationsProps) => {
  const eventDefinition = eventDefinitionQuery.data?.eventDefinition;
  const tenant = tenantQuery.data?.tenant;
  const [updateEventDefinition] = useUpdateEventDefinitionMutation();
  const [dirty, setDirty] = useState(false);
  usePrompt('You have unsaved changes!\nAre you sure you want to leave?', dirty);

  const groupSizes = [
    { value: 2 },
    { value: 3 },
    { disabled: !tenant?.isPayingOrTrialing, value: 4 },
    { disabled: !tenant?.isPayingOrTrialing, value: 5 },
    { disabled: !tenant?.isPayingOrTrialing, value: 6 },
    { disabled: !tenant?.isPayingOrTrialing, value: 7 },
    { disabled: !tenant?.isPayingOrTrialing, value: 8 },
  ];

  return (
    <>
      <div className="lg:col-span-12 lg:row-start-1">
        <Header type="h2">Channel Notifications</Header>
        <Panel padding>
          <Formik
            validationSchema={NotificationsSchema}
            initialValues={{
              groupSize: eventDefinition?.groupSize.toString(),
              template: eventDefinition?.tenantTemplate?.template,
            }}
            enableReinitialize
            onSubmit={async (submitData: FormData) => {
              if (!eventDefinition) {
                return;
              }

              await updateEventDefinition({
                variables: {
                  eventDefinitionId: eventDefinition.id,
                  groupSize: Number(submitData.groupSize),
                  template: submitData.template,
                },
              });

              await eventDefinitionQuery.refetch();
            }}
          >
            {(formik) => {
              setDirty(formik.dirty);

              return (
                <Form>
                  <div className="mb-4">
                    <p className="max-w-4xl text-sm text-gray-500">
                      Here you can update the settings for channel notifications
                    </p>
                  </div>
                  <div className="mb-8">
                    {/* <div className="sm:pb-6 pb-5"> */}
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <Toggle name="">Notify Channel on schedule change</Toggle>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <Toggle>Notify Channel after Shuffl</Toggle>
                      </div>
                    </div> */}
                  </div>

                  <SubmitButton>Update</SubmitButton>
                </Form>
              );
            }}
          </Formik>
        </Panel>
      </div>
    </>
  );
};
