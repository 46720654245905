import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GetProfileQueryHookResult, GetTenantQueryHookResult } from '../generated/graphql';
import { useUser } from './user-context';

const { Intercom } = window;

const IntercomContext = React.createContext<typeof Intercom>(Intercom);
const hasBeenCalled = false;

const enabled = Boolean(process.env.REACT_APP_INTERCOM_ENABLED ?? false) === true;
const token = process.env.REACT_APP_INTERCOM_APP_ID;

const IntercomProvider: React.FC = (props) => {
  const { userQuery, tenantQuery } = useUser();
  const location = useLocation();

  useEffect(() => intercomUpdate(tenantQuery, userQuery), [location.pathname, tenantQuery, userQuery]);

  if (enabled) {
    if (token) {
      Intercom('boot', {
        app_id: token,
      });
    } else {
      throw new Error(
        'process.env.REACT_APP_INTERCOM_APP_ID must be defined if process.env.REACT_APP_INTERCOM_ENABLED is true',
      );
    }
  }

  return <IntercomContext.Provider value={Intercom} {...props} />;
};

function intercomUpdate(tenantQuery: GetTenantQueryHookResult, userQuery: GetProfileQueryHookResult) {
  if (
    !userQuery.data?.profile.impersonatedBy &&
    tenantQuery.data?.tenant.slackMetadata &&
    !hasBeenCalled &&
    enabled &&
    token
  ) {
    Intercom('update', {
      company: {
        created_at: new Date(tenantQuery.data?.tenant.createdAt).getTime() / 1000,
        id: tenantQuery.data?.tenant.id,
        name: tenantQuery.data?.tenant.slackMetadata.team.name,
      },
      created_at: new Date(userQuery.data?.profile.createdAt ?? new Date()).getTime() / 1000,
      email: userQuery.data?.profile.email as string,
      name: userQuery.data?.profile.name,
      phone: userQuery.data?.profile.phoneNumber ?? '',
      user_hash: userQuery.data?.profile.intercomHashId ?? '',
      user_id: userQuery.data?.profile.id,
    });
  }
}

function useIntercom() {
  const context = React.useContext(IntercomContext);

  if (context === undefined) {
    throw new Error(`useIntercom must be used within a IntercomProvider`);
  }

  return context;
}

export { IntercomProvider, useIntercom };
