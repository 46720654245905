import React from 'react';
import { useUser } from '../../../../context/user-context';
import { useEventDefinition } from '../../context/event-definition-context';
import { GroupSettings } from './GroupSettings';

export const GroupSettingsContainer = () => {
  const { eventDefinitionQuery } = useEventDefinition();
  const { isPayingOrTrialing } = useUser();

  return <GroupSettings eventDefinitionQuery={eventDefinitionQuery} isPayingOrTrialing={isPayingOrTrialing} />;
};
