import React from 'react';
import { useParams } from 'react-router-dom';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useGetUserQuery } from '../../../generated/graphql';
import { Member } from './Member';

export const MemberContainer = () => {
  const { id } = useParams();
  const userQuery = useGetUserQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: id,
    },
  });

  return (
    <IsLoadingProvider isLoading={userQuery.loading}>
      <Member userQuery={userQuery} />
    </IsLoadingProvider>
  );
};
