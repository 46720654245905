/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useParams } from 'react-router-dom';
import { IsLoadingProvider } from '@shuffl/tailwind-ui-react';
import { useUser } from '../../../../context/user-context';
import { useGetShufflEventsQuery } from '../../../../generated/graphql';
import { ShufflHistory } from './History';

export const ShufflHistoryContainer = () => {
  const { id } = useParams();

  const { isPayingOrTrialing } = useUser();
  const shufflEventsQuery = useGetShufflEventsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: {
      eventDefinitionId: id,
      first: 25,
    },
  });

  return (
    <IsLoadingProvider isLoading={shufflEventsQuery.loading}>
      <ShufflHistory shufflEventsQuery={shufflEventsQuery} isPayingOrTrialing={isPayingOrTrialing} />
    </IsLoadingProvider>
  );
};
