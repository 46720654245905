import React, { PropsWithChildren } from 'react';
import { Panel, Steps } from '@shuffl/tailwind-ui-react';
import { useMatch } from 'react-router-dom';
import { Page } from '../../../components/Page';

export const AddChannel = ({ children }: PropsWithChildren<any>) => {
  const baseUrl = '/channels/add';
  const channelStepUrl = `${baseUrl}/channel`;
  const membersStepUrl = `${baseUrl}/members`;
  const scheduleStepUrl = `${baseUrl}/schedule`;
  const finishStepUrl = `${baseUrl}/finish`;
  const matchChannel = useMatch(channelStepUrl);
  const matchMembers = useMatch(membersStepUrl);
  const matchSchedule = useMatch(scheduleStepUrl);
  const matchFinish = useMatch(finishStepUrl);

  return (
    <Page>
      <Panel padding={true}>
        <div className="flex flex-col align-center justify-center max-w-3xl mx-auto">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900 sm:truncate">Add New Channel</h1>
            <div className="mt-2 max-w-4xl text-sm text-gray-500">
              Enable Shuffl roles and permissions for team members
            </div>
          </div>
          <div className="mb-14">
            <Steps ariaLabel="Add Member">
              <Steps.Step to={channelStepUrl} completed={false} title="Select Channel" />
              <Steps.Step to={membersStepUrl} completed={false} title="Select Members" />
              <Steps.Step to={scheduleStepUrl} completed={false} title="Select Schedule" />
              <Steps.Step to={finishStepUrl} completed={false} title="Finish" />
            </Steps>
          </div>
          <div>{children}</div>
        </div>
      </Panel>
    </Page>
  );
};
