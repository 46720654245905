import React, { PropsWithChildren, useEffect, useState } from 'react';
import { UserRole } from '../../../generated/graphql';

export interface AddMemberContextInterface {
  email?: string;
  role?: UserRole;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRole: React.Dispatch<React.SetStateAction<UserRole | undefined>>;
}

export interface AddMemberProviderProps {
  email?: string;
  role?: UserRole;
}

const AddMemberContext = React.createContext<AddMemberContextInterface>({} as any);

const AddMemberProvider = (props: PropsWithChildren<AddMemberProviderProps>) => {
  const [email, setEmail] = useState(props.email);
  const [role, setRole] = useState(props.role);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  useEffect(() => {
    setRole(props.role);
  }, [props.role]);

  return <AddMemberContext.Provider value={{ email, role, setEmail, setRole }} {...props} />;
};

function useAddMember() {
  const context = React.useContext(AddMemberContext);

  if (context === undefined) {
    throw new Error(`useAddMember must be used within a AddMemberContext`);
  }

  return context;
}

export { AddMemberProvider, useAddMember };
